import * as React from "react";
import { useEffect } from "react";
import { store } from "../../app/store";
import { userLoggedIn } from "../../features/google/googleSlice";
function resp(resp) {
    console.log("GoogleOneTouch() - response received");
    store.dispatch(userLoggedIn(resp));
}
const GoogleOneTouch = () => {
    useEffect(() => {
        console.log("GoogleOneTouch() - init");
        google.accounts.id.initialize({
            client_id: "910269005872-nlvvku8e7tvrevi235v9riif6r34ch0h.apps.googleusercontent.com",
            callback: resp,
        });
        google.accounts.id.prompt(); // also display the One Tap dialog
        console.log("GoogleOneTouch() - prompt");
    }, []);
    return (React.createElement("div", { id: "g_id_onload", "data-client_id": "910269005872-nlvvku8e7tvrevi235v9riif6r34ch0h.apps.googleusercontent.com", "data-auto_select": "true", "data-callback": "yolo" }));
};
export default GoogleOneTouch;
