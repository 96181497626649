import * as React from "react";
import { useSelector } from "react-redux";
export function GoogleUserProfile() {
    const loggedIn = useSelector((state) => state.google.loggedIn);
    const profile = useSelector((state) => state.google.profile);
    const jwt = useSelector((state) => state.google.jwt);
    return (React.createElement("div", null,
        "User logged in: ",
        loggedIn.toString(),
        " ",
        React.createElement("br", null),
        "sub: ",
        profile.sub,
        " ",
        React.createElement("br", null),
        "email: ",
        profile.email,
        " ",
        React.createElement("br", null),
        "name: ",
        profile.name,
        " ",
        React.createElement("br", null),
        "given_name: ",
        profile.given_name,
        " ",
        React.createElement("br", null),
        "family_name: ",
        profile.family_name,
        " ",
        React.createElement("br", null),
        "picture: ",
        profile.picture,
        " ",
        React.createElement("br", null),
        "jwt: ",
        jwt));
}
