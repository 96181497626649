import * as React from "react";
import { startTransition } from "react";
import { useState } from "react";
import AdminHeader from "./AdminHeader";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Admin from "./Admin";
//const Admin_Lazy:React.LazyExoticComponent<any> = React.lazy(() => test() );
const AdminAccountList_Lazy = React.lazy(() => import("./accounts/AdminAccountList"));
const AdminAccount_Lazy = React.lazy(() => import("./accounts/AdminAccount"));
const AdminUserList_Lazy = React.lazy(() => import("./AdminUserList"));
const AdminUser_Lazy = React.lazy(() => import("./AdminUser"));
const AdminUserAdd_Lazy = React.lazy(() => import("./AdminUserAdd"));
const AdminUserUpdate_Lazy = React.lazy(() => import("./AdminUserUpdate"));
const AdminLog_Lazy = React.lazy(() => import("./AdminLog"));
export function getAdminRoutes() {
    let adminChildRoutes = [
        {
            path: "home",
            element: (React.createElement(React.Suspense, { fallback: React.createElement(React.Fragment, null, "Loading..") },
                React.createElement(APW, { title: "Account List", element: React.createElement(AdminAccountList_Lazy, null) }))),
        },
        {
            path: "accounts",
            element: (React.createElement(React.Suspense, { fallback: React.createElement(React.Fragment, null, "Loading..") },
                React.createElement(APW, { title: "Account List", element: React.createElement(AdminAccountList_Lazy, null) }))),
        },
        {
            path: "account/:id",
            element: (React.createElement(React.Suspense, { fallback: React.createElement(React.Fragment, null, "Loading..") },
                React.createElement(APW, { title: "Account", element: React.createElement(AdminAccount_Lazy, null) }))),
        },
        {
            path: "users",
            element: (React.createElement(React.Suspense, { fallback: React.createElement(React.Fragment, null, "Loading..") },
                React.createElement(APW, { title: "Users", element: React.createElement(AdminUserList_Lazy, null) }))),
        },
        {
            path: "user/:userId",
            element: (React.createElement(React.Suspense, { fallback: React.createElement(React.Fragment, null, "Loading..") },
                React.createElement(APW, { title: "User", element: React.createElement(AdminUser_Lazy, null) }))),
        },
        {
            path: "user/add",
            element: (React.createElement(React.Suspense, { fallback: React.createElement(React.Fragment, null, "Loading..") },
                React.createElement(APW, { title: "User", element: React.createElement(AdminUserAdd_Lazy, null) }))),
        },
        {
            path: "user/edit/:userId",
            element: (React.createElement(React.Suspense, { fallback: React.createElement(React.Fragment, null, "Loading..") },
                React.createElement(APW, { title: "User", element: React.createElement(AdminUserUpdate_Lazy, null) }))),
        },
        {
            path: "log",
            element: (React.createElement(React.Suspense, { fallback: React.createElement(React.Fragment, null, "Loading..") },
                React.createElement(APW, { title: "Log Viewer", element: React.createElement(AdminLog_Lazy, null) }))),
        },
    ];
    let admin = [
        { path: "/admin", element: React.createElement(Admin, null), children: adminChildRoutes },
    ];
    return admin;
}
// render an admin page with a page wrapper
function APW(props) {
    let location = useLocation();
    useEffect(() => {
        //console.log("AdminPage - location changed");
        window.scrollTo(0, 0);
        //console.log(location);
    }, [location]);
    return (React.createElement("div", null,
        React.createElement(AdminHeader, { title: props.title }),
        React.createElement("div", { className: "adminbody main" }, props.element)));
}
// render an admin page with a page wrapper
function APWLazy(props) {
    let location = useLocation();
    let [element, setElement] = useState(null);
    function load() {
        startTransition(() => {
            let e = React.createElement(props.element);
            setElement(e);
        });
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        load();
    }, [location]);
    return (React.createElement(React.Suspense, { fallback: React.createElement(React.Fragment, null, "Loading..") },
        React.createElement("div", null,
            React.createElement(AdminHeader, { title: props.title }),
            React.createElement("div", { className: "body main" },
                React.createElement(React.Suspense, { fallback: React.createElement(React.Fragment, null, "Loading..") }, element)))));
}
