import { HceObject, } from "../../../lib/hce/v3/engine/BaseObjects";
export class Scroller extends HceObject {
    scrollSpeed;
    scrollX;
    text;
    timer;
    constructor(hce) {
        super(hce);
        this.scrollSpeed = 6;
        this.hce = undefined;
    }
    onSceneReady(screenContext) {
        this.scrollX = this.hce.canvas.width;
        this.text =
            "Welcome.......how are you doing?            I was a little bored one night and decided to create a demo inspired by the old Commodore 64 demo scene days.           What do you think?              Well, like in the old days....      I guess I can send out a few Greetings :-)        Here goes....       Hello to....     Phil, Drew, Shane, Chris and all of the ADP BMS UK crew.                    Hello to Mat and Lou Saunders.  Hope you guys are enjoying the pool by now.               Hello to my Dad, big sister, Chris & Connor.                       And of course..... big hugs go to Sam, Alex, Alfie and Jack x x x x                       Well, as you've read until the end....why don't you press keys 1-4 to play a different song?      Lightforce is the best IMHO.....but.....see what you think of the others!            BBBBYYYEEEE!!!!!    ";
        this.timer = 1;
    }
    tick(ctx) {
        this.scrollX -= this.scrollSpeed;
    }
    render(ctx) {
        var y = this.hce.canvas.height / 2;
        ctx.font = "80px Courier";
        const fontSize = ctx.measureText("Welcome...");
        const fontHeight = fontSize.fontBoundingBoxAscent + fontSize.fontBoundingBoxDescent;
        const actualHeight = fontSize.actualBoundingBoxAscent + fontSize.actualBoundingBoxDescent;
        let sinSpeed = 50;
        y =
            y +
                Math.sin(this.timer / sinSpeed) *
                    (this.hce.canvas.height / 2 - actualHeight) +
                actualHeight / 2; // place just above the bottom c64 graphic
        ctx.fillText(this.text, this.scrollX, y);
        this.timer = this.timer + 1;
    }
}
