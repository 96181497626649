import * as React from "react";
import { useToggle } from "./useToggle";
import { useCustomState } from "./useCustomState";
import { useLocalStorage } from "./useLocalStorage";
import produce from "immer";
import { useTrackKeyPressed } from "../../../lib/hooks/useTrackKeyPressed";
import { createLogger } from "../../../lib/hooks/createLogger";
// reference: https://fettblog.eu/typescript-react-typeing-custom-hooks/
export default function SimpleHookDemo() {
    const [value, toggleVisible] = useToggle(false);
    const [store, setMessage] = useCustomState();
    const [pageCount, setPageCount] = useLocalStorage("views", 0);
    function increasePageCount() {
        setPageCount(pageCount + 1);
    }
    function changeMessage() {
        setMessage("boo");
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(MyKeyPressDemo, null),
        React.createElement(MyImmerDemo, null),
        React.createElement("h1", null, "Simple Hook Demo"),
        "Value is ",
        value.toString(),
        React.createElement("button", { onClick: toggleVisible }, "Toggle"),
        React.createElement("h1", null, "Store"),
        "Hello message is: ",
        store.hello,
        React.createElement("button", { onClick: changeMessage }, "Change Message"),
        React.createElement("h1", null, "Local storage hook for page renders"),
        "Page renders: ",
        pageCount,
        React.createElement("button", { onClick: increasePageCount }, "increase")));
}
function todoTest(addMessage) {
    const todos = [
        {
            text: "todo 1",
            done: false,
        },
        {
            text: "todo 2",
            done: false,
        },
    ];
    const nextTodos = produce(todos, (draft) => {
        draft.push({ text: "learn immer", done: true });
        draft[1].done = true;
    });
    // old state is unmodified
    addMessage("todos length should be 2 - actual is " + todos.length); // 2
    addMessage("2nd should not not be completed=false - actual is " +
        todos[1].done.toString()); // false
    // new state reflects the draft
    addMessage(nextTodos.length); // 3
    addMessage(nextTodos[1].done.toString()); // true
    // structural sharing
    addMessage((todos === nextTodos).toString()); // false
    addMessage("should be true: " + (todos[0] === nextTodos[0]).toString()); // true
    addMessage("should be false:" + (todos[1] === nextTodos[1]).toString()); // false
}
const MyImmerDemo = () => {
    const [log, addMessage] = createLogger();
    React.useEffect(() => {
        addMessage("This is a little test for Immer");
        todoTest(addMessage);
    }, []);
    const logItems = log.map((m) => React.createElement("li", { key: m.id }, m.msg));
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, "Immer Demo"),
        React.createElement("h2", null, "Log Messages"),
        React.createElement("ul", null, logItems)));
};
function MyKeyPressDemo() {
    const happyPress = useTrackKeyPressed("h");
    const sadPress = useTrackKeyPressed("s");
    const robotPress = useTrackKeyPressed("r");
    const foxPress = useTrackKeyPressed("f");
    return (React.createElement("div", null,
        React.createElement("h1", null, "My Key Press Demo"),
        React.createElement("div", null, "h, s, r, f"),
        React.createElement("div", null,
            happyPress && "😊",
            sadPress && "😢",
            robotPress && "🤖",
            foxPress && "🦊")));
}
