import * as React from "react";
import { useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { getAdminRoutes } from "../admin/routes";
import C64 from "../components/C64";
import HomePage from "../components/HomePage";
import About from "../components/About";
import ShopLocal from "../components/ShopLocal";
import Resume from "../components/Resume";
import { getRoutes as experimentalRoutes } from "../experimental/routes";
import { useRoutes } from "react-router-dom";
import { getMyLinkRedirects } from "./mylinks/MyLinks";
import { RedirectToPage } from "./RedirectToPage";
import StdHeader from "../components/Header";
import StdFooter from "../components/Footer";
import YouTube from "../components/YouTube";
import { RgcHomepage } from "../experimental/rgc";
import HcmMiner from "../experimental/hce/hcmminer/HcmMiner";
// StdPage is only mounted once...not 100% why, so locat
function StdPage(props) {
    let location = useLocation();
    useEffect(() => {
        // console.log("StdPage - mounted");
    }, []);
    useEffect(() => {
        //console.log("StdPage - location changed");
        window.scrollTo(0, 0);
        //console.log(location);
    }, [location]);
    //console.log("StdPage - render");
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "fadein" },
            React.createElement(StdHeader, null),
            React.createElement("div", { className: "main body" }, props.element),
            React.createElement("div", { className: "mb-5" }, "\u00A0")),
        React.createElement(StdFooter, null)));
}
const Signup = React.lazy(() => import("../signup/signup"));
const MyRoutes = () => {
    let routes = useRoutes([
        { path: "/", element: React.createElement(StdPage, { element: React.createElement(HomePage, null) }) },
        { path: "/c64", element: React.createElement(StdPage, { element: React.createElement(C64, null) }) },
        { path: "/resume", element: React.createElement(StdPage, { element: React.createElement(Resume, null) }) },
        { path: "/youtube", element: React.createElement(StdPage, { element: React.createElement(YouTube, null) }) },
        { path: "/me", element: React.createElement(StdPage, { element: React.createElement(Resume, null) }) },
        { path: "/about", element: React.createElement(StdPage, { element: React.createElement(About, null) }) },
        { path: "/shoplocal", element: React.createElement(StdPage, { element: React.createElement(ShopLocal, null) }) },
        { path: "/rgc", element: React.createElement(RgcHomepage, null) },
        { path: "/hcmminer", element: React.createElement(HcmMiner, null) },
        {
            path: "/signup",
            element: (React.createElement(React.Suspense, { fallback: React.createElement(React.Fragment, null, "Loading..") },
                React.createElement(Signup, null))),
        },
        {
            path: "/about/tech",
            element: (React.createElement(RedirectToPage, { url: "https://roamresearch.com/#/app/richandersonio/page/_jszEEKpX" })),
        },
        ...getMyLinkRedirects(),
        ...getAdminRoutes(),
        ...experimentalRoutes(),
    ]);
    return routes;
};
const RioApp = () => {
    return (React.createElement(BrowserRouter, null,
        React.createElement(MyRoutes, null)));
};
export default RioApp;
