import * as React from "react";
import { useEffect, useRef } from "react";
import Hce3 from "../engine/hce";
import { useHceAssetManager } from "./useHceAssetManager";
const HceReact = (props) => {
    const hce = useRef(null);
    const canvasContainerRef = useRef(null);
    const assetsRef = useRef(null);
    const [assetMgr, hceAssetMgrUI] = useHceAssetManager();
    const api = useRef(null);
    async function initHce() {
        hce.current = new Hce3(canvasContainerRef.current);
        var hceInitOptions = {
            pageBackgroundColor: "black",
            canvas: canvasContainerRef.current,
        };
        hce.current
            .init(hceInitOptions)
            .then(async () => {
            let api = {
                hce: hce.current,
                assetMgr: assetMgr,
                version: "3.0 - alpha",
            };
            // parent img/audio elements created to our div
            assetMgr.parent = assetsRef.current;
            if (props.onApiReady)
                props.onApiReady(api);
        })
            .catch((e) => {
            alert("hce init failed: " + e);
        });
    }
    function termHce() { }
    useEffect(() => {
        initHce();
        return () => {
            termHce();
        };
    }, []);
    let classNames = "noselect ";
    if (props.className)
        classNames += props.className;
    return (React.createElement("div", { ref: assetsRef, className: "" },
        React.createElement("canvas", { ref: canvasContainerRef, className: classNames })));
};
export default HceReact;
