import * as React from "react";
import ContentBlock from "../../components/ContentBlock";
var hceGlobal = null;
var options = {
    pageBackgroundColor: "black",
};
const Demos = () => {
    return (React.createElement(ContentBlock, { title: "HCE Demos" },
        React.createElement("div", null,
            React.createElement("a", { href: "/demos/flyship/index.html" }, "Fly Ship"),
            React.createElement("br", null),
            React.createElement("a", { href: "/demos/hello3d/index.html" }, "Hello 3d"),
            React.createElement("br", null),
            React.createElement("a", { href: "/demos/helloworld/index.html" }, "Hello World"),
            React.createElement("br", null),
            React.createElement("a", { href: "/demos/shapes/index.html" }, "Shapes"),
            React.createElement("br", null),
            React.createElement("a", { href: "/demos/simplegamedemo/index.html" }, "Simple Game Demo"),
            React.createElement("br", null),
            React.createElement("a", { href: "/demos/showdotproduct/index.html" }, "Show dot product"),
            React.createElement("br", null))));
};
export default Demos;
