import * as React from "react";
import { useEffect } from "react";
import { usePortal } from "../../../lib/hooks/usePortal";
export const PortalDemo = () => {
    const [portal, isOpen, open, close] = usePortal(MyPortal, { message: "Yo panda!" }, React.createElement("p", null, "this is pretty interetsing!"));
    useEffect(() => {
        setTimeout(() => {
            open();
        }, 2000);
        setTimeout(() => {
            close();
        }, 5000);
    }, []);
    return React.createElement(React.Fragment, null, portal);
};
const FullScreenModal = (props) => {
    return (React.createElement("div", { className: "modal", style: { backgroundColor: "white", padding: "10px" } }, props.children));
};
const MyPortal = (props) => {
    if (!props.isOpen) {
        return React.createElement("button", { onClick: props.onOpen }, "Open Portal");
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(FullScreenModal, null,
            React.createElement("h2", null, "Welcome to Rich Anderson IO"),
            React.createElement("h3", null, props.message),
            React.createElement("button", { className: "close", onClick: props.onClose }, "Close"))));
};
