import { rioAwsS3GetPresignedUrlForPutObject } from "../lib/rio/aws";
// upload a file to an s3 bucket
// returns back the the url of the uploaded file
export async function s3UploadFile(bucketName, filename, filetype, filedata) {
    let presignedPostUrl = "";
    let s3Url = "";
    // go get a presigned url so we can post to s3
    await rioAwsS3GetPresignedUrlForPutObject(bucketName, filename)
        .then((response) => {
        presignedPostUrl = response.url;
    })
        .catch((error) => {
        throw error;
    });
    const response = await fetch(presignedPostUrl, {
        method: "PUT",
        headers: { "Content-Type": filetype },
        // @ts-ignore
        body: filedata,
    })
        .then((response) => {
        if (response.status !== 200) {
            throw new Error("unexpected response code " + response.status);
        }
        s3Url = "https://" + bucketName + ".s3.amazonaws.com/" + filename;
    })
        .catch((e) => {
        throw new Error("unexpected error putting file to s3: " + e);
    });
    return s3Url;
}
