import React, { useEffect, useState } from "react";
import ContentBlock from "./ContentBlock";

const ShopLocal = () => {
  const [users, setUsers] = useState([]);

  // https://www.freecodecamp.org/news/javascript-fetch-api-tutorial-with-js-fetch-post-and-header-examples/

  const fetchData = () => {
    fetch("https://j8xa3qbt21.execute-api.us-east-1.amazonaws.com/prod/")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setUsers(data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="fadein">
      <ContentBlock title="Shop local tips from @USChamber">
        <div>
          <p>
            Making a difference and supporting your local community has always
            been easy by dining and shopping at your local small businesses.
          </p>
          <p>
            Continue to support them by ordering food online and doing a pickup
            or delivery. If they have a website buy vouchers/gift cards that you
            can use in the future when life is back to normal.
          </p>
          <p>
            <a
              style={{ fontSize: "12px" }}
              href="https://twitter.com/USChamber/status/1240643395154055168"
            >
              source: twitter @USChamber
            </a>
          </p>
        </div>
      </ContentBlock>

      <ContentBlock title="Here are a few great places you can support:">
        {users.length > 0 && (
          <table style={{ width: "100%" }}>
            {users.map((shop) => (
              <tr key={shop.name}>
                <td>
                  {" "}
                  <img
                    src={shop.image}
                    style={{ width: "64px", height: "64px" }}
                  />{" "}
                </td>
                <td>{shop.name}</td>
                <td>
                  <a target="_blank" href={shop.website}>
                    {shop.website}
                  </a>
                </td>
              </tr>
            ))}
          </table>
        )}
      </ContentBlock>
    </div>
  );
};

export default ShopLocal;
