import * as React from "react";
import { useState } from "react";
import { classnames, cursor, fontFamily, fontSize, textColor, } from "tailwindcss-classnames";
var RgcPageId;
(function (RgcPageId) {
    RgcPageId["Home"] = "home";
    RgcPageId["Events"] = "events";
    RgcPageId["About"] = "about";
})(RgcPageId || (RgcPageId = {}));
function getMenuOptionClasses(onActivePage) {
    return classnames(textColor({ ["text-blue-900"]: onActivePage }, "hover:text-blue-700"), fontFamily("font-sans"), cursor("cursor-pointer"), fontSize({ ["text-base"]: !onActivePage }), fontSize({ ["text-lg"]: onActivePage }));
}
const Container = (props) => {
    let defaultClasses = "grid grid-cols-1 ml-0 mr-1 my-3 py-1 px-2 bg-white rounded-xl shadow-lg space-y-2";
    if (props.defaultClassName)
        defaultClasses = props.defaultClassName;
    if (props.maxWidth)
        defaultClasses = defaultClasses + " " + props.maxWidth;
    if (props.width)
        defaultClasses = defaultClasses + " " + props.width;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: defaultClasses },
            React.createElement("div", null,
                React.createElement("div", null, props.children)))));
};
const RgcHeader = (props) => {
    function pageSelected(page) {
        if (props.onPageSelected)
            props.onPageSelected(page);
    }
    function renderMenuOption(title, id) {
        return (React.createElement("span", { onClick: () => pageSelected(id), className: getMenuOptionClasses(props.activePage === id) },
            title,
            " |",
            " "));
    }
    return (React.createElement("div", { className: "w-full sticky top-0 grid grid-cols-1 place-items-center bg-white" },
        React.createElement("div", { className: "flex items-center mb-0" },
            React.createElement("img", { onClick: () => pageSelected(RgcPageId.Home), src: "/images/rgc/logo.png", className: "cursor-pointer rounded-full mr-4 mt-1 ml-1 mb-1 max-h-28 sm:max-h-36 " }),
            React.createElement("div", { className: "grid grid-cols-1 mt-3 justify-center0" },
                React.createElement("p", { className: "text-lg font-sans" },
                    "Fused Glass Art and Home Decor",
                    React.createElement("br", null),
                    "By Sam Anderson"),
                React.createElement("p", null,
                    renderMenuOption("Home", RgcPageId.Home),
                    renderMenuOption("Events", RgcPageId.Events),
                    renderMenuOption("About", RgcPageId.About),
                    React.createElement("span", { className: "space-x-3.5 text-sm ml-1.5 flex inline-flex items-center" },
                        React.createElement("a", { className: "text-lg fa fa-envelope  text-slate-700  hover:text-blue-700", href: "mailto:robinglasscraft@gmail.com" }, "  "),
                        React.createElement("a", { className: "fa fa-instagram text-xl  text-slate-700  hover:text-blue-700", href: "https://www.instagram.com/robinglasscraft", "aria-hidden": "true" }),
                        React.createElement("a", { href: "https://www.etsy.com/shop/RobinGlassCraft", className: "fa fa-etsy text-xl  text-slate-700  hover:text-blue-700" }),
                        React.createElement("a", { className: "fa fa-facebook text-xl  text-slate-700  hover:text-blue-700", href: "https://www.facebook.com/RobinGlassCraft" }, " ")))))));
};
const RgcPage = (props) => {
    return (React.createElement("div", { className: "grid grid-cols-1 justify-items-center mt-2 mb-2 ml-2 mr-2" }, props.children));
};
export const RgcEventsPage = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "fadein" },
            React.createElement(Container, { width: "w-full", maxWidth: "max-w-screen-md" },
                React.createElement("h1", null, "2022 Fall and Winter Shows Comings Soon"),
                React.createElement("p", null, "Check back in a few weeks to see my 2022 planned shows or email me.")),
            React.createElement(Container, { width: "w-full", maxWidth: "max-w-screen-md" },
                React.createElement("h1", null, "47th Annual Fall Chester Craft Show - Sept 11 & 12 2021"),
                React.createElement("img", { src: "/images/rgc/events/2021/sept_chester.jpeg", className: "object-fill w-full" }),
                React.createElement("p", null,
                    "See more information on the event website:",
                    " ",
                    React.createElement("a", { href: "https://fb.me/e/CjckUqrl" }, "https://fb.me/e/CjckUqrl"))),
            React.createElement(Container, { width: "w-full", maxWidth: "max-w-screen-md" },
                React.createElement("h1", null, "23rd Annual Spring Chester Craft Show - June 5th & 6th 2021"),
                React.createElement("img", { src: "/images/rgc/events/2021/june_chester.jpeg", className: "object-fill w-full" }),
                React.createElement("p", null,
                    "See more information on the event",
                    " ",
                    React.createElement("a", { href: "https://www.facebook.com/media/set/?vanity=ilovechestercrafts&set=a.10158347629426864" }, "website"))),
            React.createElement(Container, { width: "w-full", maxWidth: "max-w-screen-md" },
                React.createElement("h1", null, "Springfest Tranquility Farms - May 15th 2021"),
                React.createElement("img", { src: "/images/rgc/events/2021/may_tranquility.jpeg", className: "object-fill w-full" }),
                React.createElement("p", null,
                    "See more information on the",
                    " ",
                    React.createElement("a", { href: ": https://fb.me/e/CjckUqrl" }, "event website"),
                    ".")),
            React.createElement(Container, { width: "w-full", maxWidth: "max-w-screen-md" },
                React.createElement("h1", null, "Mountain Lakes Club Holiday Festival - 6th December 2020"),
                React.createElement("img", { src: "/images/rgc/events/2020/dec_ml_club.jpeg", className: "object-fill w-full" })))));
};
export const RgcHomePageContent = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "fadein" },
            React.createElement(Container, { width: "w-full", maxWidth: "max-w-screen-lg" },
                React.createElement("div", null,
                    React.createElement("h1", null, "Welcome"),
                    React.createElement("img", { src: "/images/rgc/homepage_hero.jpeg", className: "object-cover max-h-64 sm:max-h-64 w-full overflow-hidden" })),
                React.createElement("div", { className: "mt-2" },
                    React.createElement("p", null,
                        "I am a self-taught fused glass artist. As a stay-at-home mum in England, I went through a number of different art-based hobbies. For a few years I attended pottery classes and even had a wheel and kiln at home, but never managed to find enough time to turn it into more than a hobby. I then moved to America in 2012 where I first tried fusing glass at a local workshop. You were provided with tools and glass and picked a shape that your finished piece would be. From that first time I was hooked. When I would go to collect my work, I would make another, and over the next few years I went a few times annually. I always had to keep going outside to feed the meter with quarters, so my car didn\u2019t get towed because I spent much more time than it allowed. When we were locked down in the pandemic, I decided to create a home studio in my garage and start making and selling my items. I sell online and also in person at shows, which gives me the opportunity to connect with my customers. ",
                        React.createElement("a", { onClick: props.onNavAbout }, "More...")))),
            React.createElement("div", { className: "mt-3 mb-3 grid grid-cols-1 justify-items-center max-w-screen-lg w-full gap-3 md:grid-cols-2 " },
                React.createElement(StackedCardWithImage, { image: "/images/rgc/feed/wave_sun.jpeg", text: "Rainbow wave \u2600\uFE0F \uD83C\uDF08\uD83C\uDF0A", date: "9 July 2022", tags: ["fusedglassart", "rainbow"] }),
                React.createElement(StackedCardWithImage, { image: "/images/rgc/feed/fly.jpeg", text: "All together ready to fly", date: "10 June 2022", tags: ["sunflower"] }),
                React.createElement(StackedCardWithImage, { image: "/images/rgc/feed/seaside_1.jpeg", text: "Having more seaside inspired fun and trying out some new stands.", date: "9 June 2022", tags: ["beachdecor", "beachhouse", "seagull"] }),
                React.createElement(StackedCardWithImage, { image: "/images/rgc/feed/seaside4.jpeg", text: "Thinking of a summer down the shore.  10x10 beach scene and stand.", date: "9 April 2022", tags: ["seagulls", "beach", "jerseyshore"] }),
                React.createElement(StackedCardWithImage, { image: "/images/rgc/feed/orange.jpeg", text: "Feeling fruity with the latest creation a yellow citrus 5 inch round bowl.", date: "6 May 2021", tags: ["yellowcitrusbowl", "round5inch", "cutefruitkitchen"] }),
                React.createElement(StackedCardWithImage, { image: "/images/rgc/feed/flower_platter.jpeg", text: "Colorful handmade fused glass flower platter, fruit bowl, serving plate.", date: "21 April 2021", tags: ["fruitbowlplatter", "12infloralserver", "foreverflowers"] })),
            React.createElement(Container, { width: "w-full", maxWidth: "max-w-screen-lg" },
                React.createElement("h2", null, "Commission for Mat Saunders"),
                React.createElement("p", null, "\"The mercurial fused glass artist that is Sam Anderson of Robin Glass craft made and sent us these. Sam captured Roxy\u2019s square face and Maddie\u2019s beaky face perfectly. We love them! Pride of place on our wall.\" - Mat Saunders"),
                React.createElement("img", { src: "/images/rgc/homepage/matsaunderscommision.png", className: "object-fill w-full" })),
            React.createElement(Container, { width: "w-full", maxWidth: "max-w-screen-lg" },
                React.createElement("h2", { className: "mt-2" }, "A happy customer sent me a photo of their friends pup proudly sitting with their new portrait"),
                React.createElement("img", { src: "/images/rgc/homepage/april2022.jpeg", className: "object-fill w-full" })))));
};
export const RgcAboutPage = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "fadein" },
            React.createElement(Container, { width: "w-full", maxWidth: "max-w-screen-lg" },
                React.createElement("h1", null, "About"),
                React.createElement("div", null,
                    React.createElement("img", { src: "/images/rgc/homepage_hero.jpeg", className: "object-cover max-h-64 sm:max-h-64 w-full overflow-hidden" })),
                React.createElement("div", { className: "mt-2" },
                    React.createElement("p", null, "I am a self-taught fused glass artist. As a stay-at-home mum in England, I went through a number of different art-based hobbies. For a few years I attended pottery classes and even had a wheel and kiln at home, but never managed to find enough time to turn it into more than a hobby. I then moved to America in 2012 where I first tried fusing glass at a local workshop. You were provided with tools and glass and picked a shape that your finished piece would be. From that first time I was hooked. When I would go to collect my work, I would make another, and over the next few years I went a few times annually. I always had to keep going outside to feed the meter with quarters, so my car didn\u2019t get towed because I spent much more time than it allowed. When we were locked down in the pandemic, I decided to create a home studio in my garage and start making and selling my items. I sell online and also in person at shows, which gives me the opportunity to connect with my customers."),
                    React.createElement("p", null, "I only use Bullseye glass that is itself handmade and is beautiful in its own right. I love that you start with a sheet of glass and can turn it into so many different things: Textured picture art panels, soap dishes, spoon rests, bowls, platters, coasters. The possibilities are endless and there is always something new to learn."),
                    React.createElement("p", null, "The business name is a tribute to my dad who was a glazier (I think glass has always been a part of my life) and is called Robin."),
                    React.createElement("p", null,
                        "Email:",
                        " ",
                        React.createElement("a", { href: "mailto:robinglasscraft@gmail.com" }, "robinglasscraft@gmail.com")))),
            React.createElement(Container, { width: "w-full", maxWidth: "max-w-screen-lg" },
                React.createElement("div", { className: "grid grid-cols-2 items-center justify-evenly overflow-hidden space-x-2" },
                    React.createElement("img", { src: "/images/rgc/about/selfie.jpeg", className: "object-fill w-full " }),
                    React.createElement("img", { src: "/images/rgc/about/klin.jpeg", className: "object-fill w-full " }))),
            React.createElement(Container, { width: "w-full", maxWidth: "max-w-screen-lg" },
                React.createElement("div", { className: "grid grid-cols-1 items-center justify-evenly overflow-hidden space-x-2 sm:grid-cols-2" },
                    React.createElement("div", null,
                        React.createElement("p", null, "Dec 2021 - All set up at the German Christmas Market at the Sussex Fairgrounds in Augusta NJ."),
                        React.createElement("img", { src: "/images/rgc/about/germanmarketdec2021.jpeg", className: "object-fill w-full " })),
                    React.createElement("div", null,
                        React.createElement("p", null, "November 2021 - All set up for an afternoon at Jersey Girl Brewery. Got my craft beer in already. ()"),
                        React.createElement("img", { src: "/images/rgc/about/indielake.jpeg", className: "object-fill w-full " })))),
            React.createElement(Container, { width: "w-full", maxWidth: "max-w-screen-lg" },
                React.createElement("div", { className: "grid grid-cols-1 items-center justify-evenly overflow-hidden space-x-2 sm:grid-cols-2" },
                    React.createElement("div", null,
                        React.createElement("p", null, "Sept 2021 - Action shot from 47th Annual Fall Chester Craft Show"),
                        React.createElement("img", { src: "/images/rgc/about/chesteractionshot.jpeg", className: "object-fill w-full max-h-96" })),
                    React.createElement("div", null,
                        React.createElement("p", null, "June 2021 - All set up for a hot day in Chester NJ."),
                        React.createElement("img", { src: "/images/rgc/about/chesterjune2021.jpeg", className: "object-fill w-full" })))))));
};
const StackedCardWithImage = (props) => {
    return (React.createElement("div", { className: "rounded-xl overflow-hidden shadow-lg bg-white" },
        React.createElement("div", { className: "px-2 py-2" },
            props.title && (React.createElement("div", { className: "font-bold text-xl mb-2" }, props.title)),
            React.createElement("p", { className: "text-gray-700 text-lg" }, props.text),
            React.createElement("p", { className: "text-gray-400 text-xs -mt-1" }, props.date)),
        React.createElement("img", { className: "w-full px-2 mb-2", src: props.image, alt: "Sunset in the mountains" }),
        props.tags && (React.createElement("div", { className: "px-2.5" }, props.tags &&
            props.tags.map((t, index) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement("span", { key: index, className: "inline-block bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2" },
                        "#",
                        t)));
            })))));
};
export const RgcHomepage = () => {
    const [page, setPage] = useState(RgcPageId.Home);
    let pageToRender = null;
    if (page === RgcPageId.Home)
        pageToRender = (React.createElement(RgcHomePageContent, { onNavAbout: () => onPageSelected(RgcPageId.About) }));
    if (page === RgcPageId.Events)
        pageToRender = React.createElement(RgcEventsPage, null);
    if (page === RgcPageId.About)
        pageToRender = React.createElement(RgcAboutPage, null);
    function onPageSelected(page) {
        window.scrollTo(0, 0);
        setPage(page);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "grid grid-cols-1 justify-items-center" },
            React.createElement(RgcHeader, { activePage: page, onPageSelected: onPageSelected }),
            React.createElement(RgcPage, null, pageToRender))));
};
export default RgcHomepage;
