import { useState } from "react";
let myState = {
    hello: "world",
};
export const useCustomState = () => {
    const [value, setValue] = useState(myState);
    const setMessage = (newHello) => setValue({
        ...value,
        hello: newHello,
    });
    return [value, setMessage];
};
