import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { RedirectToPage } from "../RedirectToPage";
import { rioAddWebEvent } from "../../lib/rio/webevent";
import { Link } from "react-router-dom";
import MyLinksDemo from "./MyLinksDemo";
import { DraggableWindow } from "../../lib/comp/DraggableWindow";
// routes to provide friendly urls for social media sites and other links I typically share
export function getMyLinkRedirects() {
    const routes = [
        { path: "/links", element: React.createElement(MyLinks, null) },
        {
            path: "/links/linkedin",
            element: (React.createElement(RedirectToPage, { url: "https://www.linkedin.com/in/richardanderson5" })),
        },
        {
            path: "/links/github",
            element: React.createElement(RedirectToPage, { url: "https://github.com/richandersonio" }),
        },
        {
            path: "/links/youtube",
            element: (React.createElement(RedirectToPage, { url: "https://www.youtube.com/c/RichAnderson00" })),
        },
        {
            path: "/links/twitter",
            element: React.createElement(RedirectToPage, { url: "https://twitter.com/richanderson" }),
        },
        {
            path: "/links/roam",
            element: (React.createElement(RedirectToPage, { url: "https://roamresearch.com/#/app/richandersonio/page/lEvnnP1jm" })),
        },
        {
            path: "/links/robinglasscraft",
            element: (React.createElement(RedirectToPage, { url: "https://robinglasscraft.com?src=richanderson.io" })),
        },
    ];
    return routes;
}
export function MyLinks() {
    const myLinksDemoApi = useRef(null);
    let [extraClasses, setExtraClasses] = useState("");
    let [showDebug, setShowDebug] = useState(false);
    function onApiReady(api) {
        myLinksDemoApi.current = api;
    }
    async function go() {
        rioAddWebEvent("mylinks", "rio.web");
    }
    const toggleClasses = () => {
        if (extraClasses.length === 0) {
            setExtraClasses(" invisible");
        }
        else {
            setExtraClasses("");
        }
    };
    const onKeyDown = (e) => {
        if (e.key === "t")
            toggleClasses();
        if (e.key === "@")
            setShowDebug(!showDebug);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        go();
    }, []);
    return (React.createElement(React.Fragment, null,
        showDebug ? (React.createElement(DraggableWindow, { x: 200, y: 200 },
            React.createElement("h1", null, "Do some debug shit"))) : null,
        React.createElement("div", { tabIndex: 0, className: "w-screen h-screen overflow-scroll outline-0 ", onKeyDown: onKeyDown, onDoubleClick: toggleClasses },
            React.createElement("div", { className: " fadeinlinks3s opacity-85 mt-10 max-w-sm mx-auto bg-white grid items-center rounded-xl shadow-lg space-y-2" +
                    extraClasses },
                React.createElement("div", { className: "space-y-1" },
                    React.createElement("div", { className: "grid place-items-center" },
                        React.createElement("div", { className: "mt-2 grid place-items-center w-full max-w-sm mx-auto" },
                            React.createElement("a", { href: "/" },
                                React.createElement("img", { className: "block mx-auto h-20 rounded-full border-2 border-rioblue ", src: "images/me/rich_aug_2022.jpeg", alt: "Picture of Rich Anderson" })),
                            React.createElement("div", { className: "ml-4 mr-4 mt-2 text-center space-y-2 grid place-items-center" },
                                React.createElement("div", { className: "space-y-0.5" },
                                    React.createElement("p", { className: "text-lg mt--3 font-semibold text-rioblue" }, "Rich Anderson"),
                                    React.createElement("p", null, "Happy Hacker."))))))),
            React.createElement("div", { className: "fadeinlinks4s opacity-85 my-4 py-4 px-4  max-w-sm mx-auto bg-white rounded-xl shadow-lg space-y-2 sm:py-4 sm:flex sm:space-y-0 sm:space-x-6 " +
                    extraClasses },
                React.createElement("div", { className: "space-y-2 " + extraClasses },
                    React.createElement("div", null,
                        React.createElement("p", { className: "font-semibold" }, "My Links"),
                        React.createElement("ul", { className: "list-disc" },
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/links/linkedin" }, "LinkedIn")),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/links/twitter" }, "Twitter")),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/links/github" }, "GitHub")),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/links/youtube" }, "YouTube")),
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/" }, "My Website")))))),
            React.createElement("div", { className: "fadeinlinks5s opacity-85  my-4 py-4 px-4  max-w-sm mx-auto bg-white rounded-xl shadow-lg space-y-2 sm:py-4 sm:flex sm:space-y-0 sm:space-x-6 " +
                    extraClasses },
                React.createElement("div", { className: "space-y-2 " + extraClasses },
                    React.createElement("div", null,
                        React.createElement("p", { className: "font-semibold" }, "My Resources"),
                        React.createElement("ul", { className: "list-disc" },
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/links/roam" }, "Roam Graph")))))),
            React.createElement("div", { className: " fadeinlinks6s opacity-85 my-4 py-4 px-4  max-w-sm mx-auto bg-white rounded-xl shadow-lg space-y-2 sm:py-4 sm:flex sm:space-y-0 sm:space-x-6 " +
                    extraClasses },
                React.createElement("div", { className: "space-y-2 " },
                    React.createElement("div", null,
                        React.createElement("p", { className: "font-semibold" }, "Recommended Sites"),
                        React.createElement("ul", { className: "list-disc" },
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/links/robinglasscraft" }, "Fusion Glass Art by Robin Glass Craft")),
                            React.createElement("li", null,
                                React.createElement("a", { href: "https://www.adp.com" }, "HR & Payroll Resources")))))),
            React.createElement("div", { className: " fadeinlinks6s opacity-85  my-4 py-4 px-4  max-w-sm mx-auto bg-white rounded-xl shadow-lg space-y-2 sm:py-4 sm:flex sm:space-y-0 sm:space-x-6 " +
                    extraClasses },
                React.createElement("div", { className: "space-y-2 " },
                    React.createElement("div", null,
                        React.createElement("p", { className: "font-semibold" },
                            "Music By",
                            " ",
                            React.createElement("a", { className: "text-black", href: "https://remix64.com/act/dr-future/", target: "c64" }, "Dr. Future")),
                        React.createElement("ul", { className: "list-disc" },
                            React.createElement("li", null,
                                React.createElement("span", { className: "text-riolink cursor-pointer", onClick: () => myLinksDemoApi.current.playMusic("1") }, "Lightforce")),
                            React.createElement("li", null,
                                React.createElement("span", { className: "text-riolink cursor-pointer", onClick: () => myLinksDemoApi.current.playMusic("2") }, "Xenon Ready Player 1")),
                            React.createElement("li", null,
                                React.createElement("span", { className: "text-riolink cursor-pointer", onClick: () => myLinksDemoApi.current.playMusic("3") }, "Shades")),
                            React.createElement("li", null,
                                React.createElement("span", { className: "text-riolink cursor-pointer", onClick: () => myLinksDemoApi.current.playMusic("4") }, "Speedball")),
                            " ")))),
            React.createElement(MyLinksDemo, { onApiReady: onApiReady }))));
}
