import { useEffect, useState } from "react";
// ref: https://usehooks.com/useKeyPress/
// tracks if a key is pressed
export function useTrackKeyPressed(targetKey) {
    const [keyPressed, setKeyPressed] = useState(false);
    function downHandler(e) {
        if (e.key === targetKey) {
            setKeyPressed(true);
        }
    }
    const upHandler = (e) => {
        if (e.key === targetKey) {
            setKeyPressed(false);
        }
    };
    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    }, []);
    return keyPressed;
}
