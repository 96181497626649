import * as React from "react";
import ContentBlock from "./ContentBlock";
import { rioAddWebEvent } from "../lib/rio/webevent";
import { useEffect } from "react";
const C64Content = () => {
    useEffect(() => {
        rioAddWebEvent("c64", "rio.web");
    }, []);
    const createVideo = (id) => {
        // rel=0 - ensures recommendations come from the same channel as the site that is hosting the video
        return (React.createElement("iframe", { width: "100%", height: "315", src: "https://www.youtube.com/embed/" + id + "?rel=0", allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }));
    };
    return (React.createElement("div", { className: "fadein" },
        React.createElement(ContentBlock, { title: "C64 Music" },
            React.createElement("div", null,
                React.createElement("p", null, "Growing up I spent many an hour with friends playing games on the Commodore 64 enjoying the wonderful music composed by Rob Hubbard, Martin Galway and many other great composers."))),
        React.createElement(ContentBlock, { title: "Tunes by Rob Hubbard" },
            React.createElement("div", null,
                React.createElement("h3", null, "Commando"),
                React.createElement("p", null, createVideo("qrQuR1LHAVI")),
                React.createElement("h3", null, "Sanxion loader"),
                React.createElement("p", null,
                    React.createElement("p", null, createVideo("It7yJh-NwPY"))),
                React.createElement("h3", null, "International Karate"),
                React.createElement("p", null, createVideo("Js2mcVVJpnA")),
                React.createElement("h3", null, "Monty on the Run"),
                React.createElement("p", null, createVideo("4EcgruWlXnQ")),
                React.createElement("h3", null, "Nemesis the Warlock"),
                React.createElement("p", null,
                    React.createElement("iframe", { width: "100%", height: "315", src: "https://www.youtube.com/embed/kdzfOXkZrY0", frameBorder: "0", allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true })))),
        React.createElement(ContentBlock, { title: "Tunes by Martin Galway" },
            React.createElement("div", null,
                React.createElement("h3", null, "Rambo"),
                React.createElement("p", null,
                    React.createElement("iframe", { width: "100%", height: "315", src: "https://www.youtube.com/embed/WmC2nsPiMz4", frameBorder: "0", allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true })),
                React.createElement("h3", null, "Parallax Title Tune"),
                React.createElement("p", null,
                    React.createElement("iframe", { width: "100%", height: "315", src: "https://www.youtube.com/embed/igVxjCecmEg", frameBorder: "0", allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true })),
                React.createElement("h3", null, "Ocean loader 2 by Martin Galway and loader 3 in 1987 by Peter Clarke"),
                React.createElement("p", null,
                    React.createElement("iframe", { width: "100%", height: "315", src: "https://www.youtube.com/embed/-X2zNe3YFNM", frameBorder: "0", allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true })),
                React.createElement("h3", null, "Yie Ar Kung-Fu"),
                React.createElement("p", null,
                    React.createElement("iframe", { width: "100%", height: "315", src: "https://www.youtube.com/embed/oDoGYlAzT7w", frameBorder: "0", allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true })))),
        React.createElement(ContentBlock, { title: "Tunes by Ben Daglish" },
            React.createElement("div", null,
                React.createElement("h3", null, "Trap"),
                React.createElement("p", null,
                    React.createElement("iframe", { width: "100%", height: "315", src: "https://www.youtube.com/embed/RoKhsxmz5ps", frameBorder: "0", allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true })),
                React.createElement("h3", null, "Cobra"),
                React.createElement("p", null,
                    React.createElement("iframe", { width: "100%", height: "315", src: "https://www.youtube.com/embed/RQ9JOraXUTM", frameBorder: "0", allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true })),
                React.createElement("h3", null, "Bulldog"),
                React.createElement("p", null,
                    React.createElement("iframe", { width: "100%", height: "315", src: "https://www.youtube.com/embed/4j8Tj4Gfbew", frameBorder: "0", allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true })))),
        React.createElement(ContentBlock, { title: "DeepSID" },
            React.createElement("div", null,
                React.createElement("p", null,
                    "You can browse and listen to more tunes on",
                    " ",
                    React.createElement("a", { href: "http://deepsid.chordian.net/" }, "DeepSID"),
                    ". The site emulates the",
                    " ",
                    React.createElement("a", { href: "https://en.wikipedia.org/wiki/MOS_Technology_6581" }, "the 6581 SID chip"),
                    " ",
                    "and provides access to 1000s of SID tunes from just about every composer.",
                    " "))),
        React.createElement(ContentBlock, { title: "Programming the C64" },
            React.createElement("div", null,
                React.createElement("p", null,
                    "I learnt",
                    " ",
                    React.createElement("a", { href: "https://en.wikipedia.org/wiki/MOS_Technology_6502" }, "6502"),
                    " ",
                    "assembly programming (after BASIC of course!) and wrote quite a few demos and was a pretty active member of the",
                    " ",
                    React.createElement("a", { href: "https://en.wikipedia.org/wiki/Demoscene#Intros" }, "demoscene"),
                    "."),
                React.createElement("p", null,
                    "If you are looking for a trip down memory lane you can write C64 BASIC code and/or loading C64 apps check out the",
                    " ",
                    React.createElement("a", { href: "https://sourceforge.net/projects/vice-emu/" }, "VICE "),
                    " C64 emulator"),
                React.createElement("img", { src: "images/c64/c64_richanderson_io.png", style: {
                        display: "none",
                        maxWidth: "50%",
                        margin: "auto",
                        textAlign: "center",
                    } }),
                React.createElement("p", null,
                    React.createElement("video", { width: "400", controls: true, autoPlay: true },
                        React.createElement("source", { src: "images/c64/c64_typing.mp4", type: "video/mp4" }),
                        "Your browser does not support HTML5 video.")))),
        " ",
        React.createElement("div", null)));
};
export default C64Content;
