import { HceObject, } from "../../../lib/hce/v3/engine/BaseObjects";
export class Scroller extends HceObject {
    scrollSpeed;
    scrollX;
    text = "Default text...";
    timer;
    constructor(hce) {
        super(hce);
        this.scrollSpeed = 6;
        this.hce = undefined;
    }
    onSceneReady(screenContext) {
        this.scrollX = this.hce.canvas.width;
        this.timer = 1;
    }
    onTick(ctx) {
        this.scrollX -= this.scrollSpeed;
    }
    onRender(ctx) {
        var y = this.hce.canvas.height / 2;
        ctx.font = "80px Courier";
        const fontSize = ctx.measureText("Welcome...");
        const fontHeight = fontSize.fontBoundingBoxAscent + fontSize.fontBoundingBoxDescent;
        const actualHeight = fontSize.actualBoundingBoxAscent + fontSize.actualBoundingBoxDescent;
        let sinSpeed = 50;
        y =
            y +
                Math.sin(this.timer / sinSpeed) *
                    (this.hce.canvas.height / 2 - actualHeight) +
                actualHeight / 2; // place just above the bottom c64 graphic
        ctx.fillText(this.text, this.scrollX, y);
        this.timer = this.timer + 1;
    }
}
