import * as React from "react";
import { Outlet, Link } from "react-router-dom";
import ContentBlock from "../../components/ContentBlock";
import About from "./about";
import ExperimentalSimpleReactTable from "./simple/";
const ReactTable = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentBlock, { title: "React Table Examples" },
            React.createElement(React.Fragment, null,
                React.createElement(Link, { to: "about" }, "About"),
                " ",
                React.createElement("br", null),
                React.createElement(Link, { to: "simple" }, "Simple table"),
                " ",
                React.createElement("br", null))),
        React.createElement(Outlet, null)));
};
export function getRoutes() {
    let childRoutes = [
        { path: "about", element: React.createElement(About, null) },
        { path: "simple", element: React.createElement(ExperimentalSimpleReactTable, null) },
    ];
    let routes = [
        { path: "reacttable", element: React.createElement(ReactTable, null), children: childRoutes },
    ];
    return routes;
}
