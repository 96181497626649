import * as React from "react";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable, } from "@tanstack/react-table";
const defaultData = [
    {
        firstName: "tanner",
        lastName: "linsley",
        age: 24,
        visits: 100,
        status: "In Relationship",
        progress: 50,
    },
    {
        firstName: "tandy",
        lastName: "miller",
        age: 40,
        visits: 40,
        status: "Single",
        progress: 80,
    },
    {
        firstName: "joe",
        lastName: "dirte",
        age: 45,
        visits: 20,
        status: "Complicated",
        progress: 10,
    },
];
const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor("firstName", {
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.lastName, {
        id: "lastName",
        cell: (info) => React.createElement("i", null, info.getValue()),
        header: () => React.createElement("span", null),
    }),
    columnHelper.accessor("age", {
        header: () => "Age",
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
    }),
    columnHelper.accessor("visits", {
        header: () => React.createElement("span", null, "Visits"),
        footer: (info) => info.column.id,
    }),
    columnHelper.accessor("status", {
        header: "Status",
        footer: (info) => info.column.id,
    }),
    columnHelper.accessor("progress", {
        header: "Profile Progress",
        footer: (info) => info.column.id,
    }),
];
export default function ExperimentalSimpleReactTable() {
    const [data, setData] = React.useState(() => [...defaultData]);
    const rerender = React.useReducer(() => ({}), {})[1];
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });
    return (React.createElement("div", { className: "p-2" },
        React.createElement("table", null,
            React.createElement("thead", null, table.getHeaderGroups().map((headerGroup) => (React.createElement("tr", { key: headerGroup.id }, headerGroup.headers.map((header) => (React.createElement("th", { key: header.id }, header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.header, header.getContext())))))))),
            React.createElement("tbody", null, table.getRowModel().rows.map((row) => (React.createElement("tr", { key: row.id }, row.getVisibleCells().map((cell) => (React.createElement("td", { key: cell.id }, flexRender(cell.column.columnDef.cell, cell.getContext())))))))),
            React.createElement("tfoot", null, table.getFooterGroups().map((footerGroup) => (React.createElement("tr", { key: footerGroup.id }, footerGroup.headers.map((header) => (React.createElement("th", { key: header.id }, header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.footer, header.getContext()))))))))),
        React.createElement("div", { className: "h-4" }),
        React.createElement("button", { onClick: () => rerender(), className: "border p-2" }, "Rerender"),
        React.createElement("h3", null, "Images from S3"),
        React.createElement("img", { src: "https://rio-file-upload.s3.amazonaws.com/newsite/public/images/logo.jpg" })));
}
