import * as React from "react";
const YouTubeComponent = (props) => {
    const attrs = {};
    if (props.width)
        attrs.width = props.width;
    if (props.height)
        attrs.width = props.height;
    if (props.allowFullScreen)
        attrs.allowFullScreen = false;
    return (React.createElement("iframe", { ...attrs, src: "https://www.youtube.com/embed/" + props.videoId }));
};
const YouTube = () => {
    // rob hubbard
    const hubbardVideos = [
        "qrQuR1LHAVI",
        "It7yJh-NwPY",
        "Js2mcVVJpnA",
        "4EcgruWlXnQ",
        "kdzfOXkZrY0",
    ];
    let hubbardVideosElement = hubbardVideos.map((v) => {
        return React.createElement(YouTubeComponent, { key: v, videoId: v });
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, "Tunes by Rob Hubbard!"),
        hubbardVideosElement));
};
export default YouTube;
