import { Player } from "./Player";
import { Platform } from "./Platform";
import { KeyboardMap } from "../../../lib/hce/v3/engine/keyboardMap";
import HceScene from "../../../lib/hce/v3/engine/HceScene";
// state for our scene we will share with children
class WelcomeSceneContext {
    platforms = [];
}
export class WelcomeScene extends HceScene {
    sceneContext;
    keyboardMap;
    player;
    constructor(hce) {
        super(hce);
    }
    onStart() {
        this.keyboardMap = new KeyboardMap();
        this.keyboardMap.init();
        // create a simple scene context for the current level
        this.sceneContext = new WelcomeSceneContext();
        var player = new Player(this.hce);
        this.rootObject.addChild(player);
        this.player = player;
        this.player.sceneContext = this.sceneContext;
        player.position.y = 500;
        player.position.x = 500;
        var platform = new Platform(this.hce);
        platform.position.y = 400;
        platform.position.x = 100;
        this.rootObject.addChild(platform);
        this.sceneContext.platforms.push(platform);
        var platform = new Platform(this.hce);
        platform.position.y = 500;
        platform.position.x = 1000;
        this.rootObject.addChild(platform);
        this.sceneContext.platforms.push(platform);
        var platform = new Platform(this.hce);
        platform.position.y = 600;
        platform.position.x = 500;
        this.rootObject.addChild(platform);
        this.sceneContext.platforms.push(platform);
        var platform = new Platform(this.hce);
        platform.position.y = 800;
        platform.position.x = 100;
        this.rootObject.addChild(platform);
        this.sceneContext.platforms.push(platform);
        var platform = new Platform(this.hce);
        platform.position.y = 900;
        platform.position.x = 500;
        this.rootObject.addChild(platform);
        this.sceneContext.platforms.push(platform);
    }
    onStop() {
        this.keyboardMap.term();
        this.keyboardMap = null;
    }
    onRender(context) {
        var gradient = context.ctx2d.createLinearGradient(0, 0, context.hce.canvas.width, 0);
        gradient.addColorStop(0, "lightblue");
        gradient.addColorStop(0.5, "lightblue");
        gradient.addColorStop(1.0, "white");
        context.ctx2d.fillStyle = gradient;
        context.ctx2d.font = "32px Courier";
        context.ctx2d.fillText("HCM Miner v0.1", 20, 30);
        context.ctx2d.fillText("--------------", 20, 50);
        context.ctx2d.fillText("Gravity " + this.player.gravityY, 400, 30);
        var tickContext = {
            keyboardMap: this.keyboardMap
        };
        this.rootObject.tick(tickContext, 0);
        this.rootObject.render(context.ctx2d);
    }
}
