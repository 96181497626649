import { createSlice } from "@reduxjs/toolkit";
import { decodeJwt } from "jose";
const initialState = {
    loggedIn: false,
    jwt: "",
    profile: {
        sub: "",
        email: "",
        name: "",
        given_name: "",
        family_name: "",
        picture: "",
    },
};
export const googleSlice = createSlice({
    name: "google",
    initialState,
    reducers: {
        userLoggedIn: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            const responsePayload = decodeJwt(action.payload.credential);
            state.loggedIn = true;
            state.profile = responsePayload;
            state.jwt = action.payload.credential;
        },
        userLoggedOut: (state) => {
            state.loggedIn = false;
        },
    },
});
// Action creators are generated for each case reducer function
export const { userLoggedIn, userLoggedOut } = googleSlice.actions;
export default googleSlice.reducer;
