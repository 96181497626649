var apiServer = __API__;
import { stdJsonFetchHeaders } from "./utils";
export async function rioAwsS3GetPresignedUrlForPutObject(bucketName, key) {
    var response = await fetch(apiServer + "/api/v1/aws/s3/getsignedurl/putobject", {
        method: "POST",
        body: JSON.stringify({ bucketName, key }),
        headers: stdJsonFetchHeaders,
    });
    if (response.status === 200)
        return response.json();
    throw Error("rioAwsS3GetPresignedUrlForPutObject() - unexpected response code: " +
        response.status);
}
