import { HceObject, HceEdgeCollider, MyVector, MoveComponent } from "../../lib/hce/v3/engine/BaseObjects";
export class Alien extends HceObject {
    gravity;
    gravityEffect;
    gravityEffectMax;
    gravityEffectInc;
    gravityX;
    gravityY;
    player;
    object;
    imageId;
    points;
    constructor(hce) {
        super(hce);
        this.gravity = false;
        this.gravityEffect = 0;
        this.gravityEffectMax = 5;
        this.gravityEffectInc = 0.1;
        this.width = 25;
        this.height = 25;
        this.gravityX = 0;
        this.gravityY = 0;
        this.collider = new HceEdgeCollider();
        this.collider.points.push(new MyVector(0, 0));
        this.collider.points.push(new MyVector(this.width, this.height));
        this.collider.parent = this;
        this.player = null;
        this.object = null;
        this.imageId = "alienimg";
        this.createMyPoints();
        var mc = new MoveComponent(this.hce);
        this.components.push(mc);
    }
    // init a new designer object
    initObject(obj) {
        obj.rotation = this.rotation;
        obj.tag = this.tag;
        obj.gravity = this.gravity;
        obj.height = this.height;
        obj.width = this.width;
        this.object = obj;
        this.object.points = this.createMyPoints();
    }
    // Take on an existing designer/persisted object
    setObject(obj) {
        this.object = obj; // design time obj information
        this.position.x = parseInt(obj.points[0].x);
        this.position.y = parseInt(obj.points[0].y);
        this.rotation = obj.rotation;
        this.tag = this.object.tag;
        if (obj.gravity)
            this.gravity = obj.gravity;
        if (obj.height)
            this.height = obj.height;
        if (obj.width)
            this.width = obj.width;
        this.object.points = this.createMyPoints(); // create points for the image for collisions
    }
    // create points for the editor
    createMyPoints() {
        var points = [];
        points.push({ x: this.position.x, y: this.position.y });
        points.push({ x: this.position.x + this.width, y: this.position.y });
        points.push({
            x: this.position.x + this.width,
            y: this.position.y + this.height,
        });
        points.push({ x: this.position.x, y: this.position.y + this.height });
        points.push({ x: this.position.x, y: this.position.y });
        this.points = points;
        return points;
    }
    onTick(tickContext) {
        this.translate(new MyVector(this.gravityX, this.gravityY));
        if (this.collider) {
            this.collider.setOriginXY(this.position.x, this.position.y);
        }
    }
    // called when the scene is ready to be initialized.
    onSceneReady(sceneContext) {
        // find the real player so we can track them
        var player = this.sceneContext.rootObject.getObjectsByTag("player");
        // if we have dots, check for collision
        if (player && player[0] != this) {
            this.player = player[0];
        }
        else {
            this.player = null;
        }
    }
    // check if we hit anything of the specific edges
    hit(edgePoints) {
        if (this.collider) {
            var hit = this.collider.hit(edgePoints);
            return hit;
        }
        return null;
    }
    renderText(ctx2d, text, x, y) {
        ctx2d.font = "18px Courier";
        ctx2d.fillText(text, x, y);
    }
    onRender(c) {
        var x = Math.floor(this.position.x);
        var y = Math.floor(this.position.y);
        this.renderText(c, "o", x, y);
        //var img = document.getElementById(this.imageId);
        // if (!img) return;
        //  if( img.dataset.hceready !== "loaded") // hce not loaded it yet
        //      return;
        // c.drawImage(img, x, y, this.width, this.height);
    }
}
