import { useEffect, useState } from "react";
export function createLogger() {
    const [log, setLog] = useState([]);
    useEffect(() => {
        // console.log("logger created()")
        return () => {
            // console.log("logger destroyed()")
        };
    }, []);
    function addMessage(msg) {
        const newMessage = {
            id: log.length,
            msg,
        };
        let newLog = [...log];
        newLog.push(newMessage);
        setLog(newLog);
        // console.log("log msg: " +msg );
    }
    return [log, addMessage];
}
