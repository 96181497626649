export class TrackedAsset {
    id = null;
    src = null;
    type = "none";
    domElement = null;
    state = "idle";
    ready = false;
}
// manages loading audio files and images at assets loaded for hce and the related HTML element
export class HceAssetManager {
    assetsid = "hce_assets";
    assetsElement = null;
    trackedAssets = [];
    assetLoadedCallback;
    parent = null; // parent element where we will host our elements
    getAssetsElement() {
        if (this.assetsElement !== null)
            return this.assetsElement;
        if (this.parent === null) {
            this.parent = document.body;
        }
        this.assetsElement = document.createElement("div");
        this.assetsElement.id = this.assetsid;
        this.assetsElement = this.parent.appendChild(this.assetsElement);
        return this.assetsElement;
    }
    createImageElement(id) {
        var assets = this.getAssetsElement();
        var image;
        var image = document.createElement("img");
        image.id = id;
        image = assets.appendChild(image);
        return image;
    }
    createAudioElement(id) {
        var assets = this.getAssetsElement();
        var audio;
        var audio = document.createElement("audio");
        audio.id = id;
        audio = assets.appendChild(audio);
        return audio;
    }
    loadImage(id, src) {
        var p = new Promise((ok, err) => {
            var trackedAsset = new TrackedAsset();
            trackedAsset.id = id;
            trackedAsset.src = src;
            trackedAsset.type = "image";
            this.trackedAssets.push(trackedAsset);
            var e = this.createImageElement(id);
            trackedAsset.domElement = e;
            trackedAsset.state = "idle";
            e.addEventListener("load", () => {
                trackedAsset.state = "loaded";
                trackedAsset.ready = true;
                if (this.assetLoadedCallback)
                    this.assetLoadedCallback(trackedAsset);
                ok(trackedAsset);
            });
            e.addEventListener("error", () => {
                trackedAsset.state = "error";
                err(trackedAsset);
            });
            e.src = src;
        });
        return p;
    }
    loadSound(id, src, callbackLoaded) {
        var p = new Promise((ok, err) => {
            var trackedAsset = new TrackedAsset();
            trackedAsset.id = id;
            trackedAsset.src = src;
            trackedAsset.type = "sound";
            this.trackedAssets.push(trackedAsset);
            var e = this.createAudioElement(id);
            trackedAsset.domElement = e;
            e.autoplay = false;
            e.addEventListener("canplaythrough", () => {
                trackedAsset.state = "loaded";
                trackedAsset.ready = true;
                ok(trackedAsset);
                if (callbackLoaded) {
                    callbackLoaded(trackedAsset);
                }
                if (this.assetLoadedCallback)
                    this.assetLoadedCallback(trackedAsset);
            });
            e.dataset.hceready = "loading";
            e.src = src;
            e.load();
        });
        return p;
    }
    getById(id) {
        for (var i = 0; i < this.trackedAssets.length; i++) {
            if (this.trackedAssets[i].id === id) {
                return this.trackedAssets[i];
            }
        }
        return null;
    }
}
