import * as React from "react";
import ContentBlock from "./ContentBlock";
import { rioAddWebEvent } from "../lib/rio/webevent";
import { useEffect } from "react";
const Resume = () => {
    useEffect(() => {
        rioAddWebEvent("resume", "rio.web");
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "fadein" },
            React.createElement(ContentBlock, { title: "Notable achievements" },
                React.createElement("div", null,
                    React.createElement("ul", { className: "list-disc" },
                        React.createElement("li", null, "Led the team that designed and created the RUN HR/Payroll platform & product that serves over 650k small businesses, generates well over $1 billon in revenue."),
                        React.createElement("li", null, "Led the team that brought the ADP Next Gen HCM product to market, winning the 2019 Awesome New Product & Awesome New Technology awards at HR Tech."),
                        React.createElement("li", null,
                            "Created and led the team that designed and launched the",
                            " ",
                            React.createElement("a", { href: "https://www.cpapracticeadvisor.com/accounting-audit/article/12337264/2017-innovation-award-winners-announced-in-los-angeles" }, "award winning"),
                            " ",
                            "ADP Accountant Connect."),
                        React.createElement("li", null,
                            "A key member of the team that created and launched the",
                            " ",
                            React.createElement("a", { href: "https://www.forbes.com/sites/jonathansalembaskin/2015/10/28/adp-creates-first-ever-os-for-human-resources/#471f9612a6b5" }, "award winning"),
                            " ",
                            "first Human Capital Management (HCM) marketplace."),
                        React.createElement("li", null, "CTO/R&D leader for the the first and highest scale cloud product at ADP."),
                        React.createElement("li", null, "Co-authored and published several technical books.")))),
            React.createElement(ContentBlock, { title: "SVP Product Development, Global Sales & Marketing @ ADP - April 2020+" },
                React.createElement("div", null,
                    React.createElement("ul", { className: "list-disc" },
                        React.createElement("li", null, "Senior executive focused on building next generation marketing, sales & data products."),
                        React.createElement("li", null, "Lead of Product Innovation & Development (CTO/CIO) building products and integrating tools/technologies to support 2 billon in yearly global sales."),
                        React.createElement("li", null, "Responsible for Sales & Marketing technology vision/strategy, partnering with Head of Sales, CMO & SVP of Commercial Operations."),
                        React.createElement("li", null, "Responsible for AI/ML technology & data product development, delivering 50+ models to support account scoring, product-fit scoring, in-product marketing."),
                        React.createElement("li", null, "Responsible for Digital marketing product development & advanced data products."),
                        React.createElement("li", null, "Responsible for Sales innovation teams building next generation internal tools (cloud native, modern stack)."),
                        React.createElement("li", null, "Responsible for core sales platforms and product/automation teams supporting (CRM, PRM, CPQ etc)")))),
            React.createElement(ContentBlock, { title: "SVP, Product Development @ Lifion By ADP (New York Innovation Lab) - Jan 2019 - April 2020" },
                React.createElement("div", null,
                    React.createElement("ul", { className: "list-disc" },
                        React.createElement("li", null, "Led the program/global team that brought the Lifion to market, resulting in Lifion winning the 2019 Awesome New Product + Awesome new Technology awards at HR Technology."),
                        React.createElement("li", null, "Restructured the program/team and established new priorities to drive the successful deployment of Lifion to enterprise sized clients (accounts such as Gold's Gym)."),
                        React.createElement("li", null, "Hired new leaders for all key functional areas of the program (product management, design, reliability, strategy etc) to ensure program success")),
                    React.createElement("p", null, "See also:"),
                    React.createElement("p", { style: { marginLeft: "10px" } },
                        React.createElement("a", { href: "https://youtu.be/e2iMASyAF6k" }, "Next Gen HCM/Lifion HR Tech Awards")))),
            React.createElement(ContentBlock, { title: "SVP, Product Development @ ADP (Small Business Division) - 2015-2018" },
                React.createElement("div", null,
                    React.createElement("ul", { className: "list-disc" },
                        React.createElement("li", null, "Head of Product Innovation & Development (CTO/CIO) for the Small Business Division of ADP (Small Business Services, Insurance Services & Retirement Services)"),
                        React.createElement("li", null, "Scaled the core SBS product, platform & teams to support over 550k clients, achieving best in class NPS scores and significantly improving margins year over year with product/design improvements"),
                        React.createElement("li", null, "Led the product/design/technology vision for Accountant Connect. Launched in Feb 2017 with a new platform built ground up using open source technologies, featuring a modern mobile first design and modern CI/CD pipeline to support high frequency daily product releases."),
                        React.createElement("li", null, "Accountant Connect has won multiple product awards including the CPA Practice Advisor Tax and Accounting Technology Innovation Award (2017+2018)"),
                        React.createElement("li", null, "Created and executed a people strategy resulting in record engagement scores.")),
                    React.createElement("p", null, "See also:"),
                    React.createElement("p", { style: { marginLeft: "10px" } },
                        React.createElement("a", { href: "https://www.cpapracticeadvisor.com/accounting-audit/article/12337264/2017-innovation-award-winners-announced-in-los-angeles" }, "Accountant Connect CPA Practice Advisor Innovation Award Winner")))),
            React.createElement(ContentBlock, { title: "Some of my work @ ADP" },
                React.createElement("div", null,
                    React.createElement("p", null, "This YouTube playlist shows some of the products I've built with some of the amazing teams @ ADP:"),
                    React.createElement("p", { style: { marginLeft: "10px" } },
                        React.createElement("iframe", { width: "95%", height: "315", src: "https://www.youtube.com/embed/videoseries?list=PLpOv3mPx9GmtWxrEjmMuxVOqKpR_LerqM", frameBorder: "0", allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true })))),
            React.createElement(ContentBlock, { title: "ADP leadership conference - Using AI & ML to transform our UX" },
                React.createElement("img", { src: "images/me/adp_techi_talk.jpg", style: { width: "100%" } })),
            React.createElement(ContentBlock, { title: "VP Product Development @ ADP - Jan 2009 - May 2015" },
                React.createElement("div", null,
                    "Responsibility for all product architecture/engineering functions for the SBS division (US) and the ADP freedom products (UK). Responsible for the architecture, ux, development and engineering teams. Over 450, 000 companies are running on the platforms with revenues exceeding nearly 1 billion. All products were designed and created ground up with small components integrated via M&A (HR411).",
                    React.createElement("ul", { className: "list-disc", style: { marginLeft: "10px" } },
                        React.createElement("li", null, "2014 - Worked as part of the core team that built and launched the ADP marketplace. Executive accountable to the CVP/CIO and SVP of labs for the on-time delivery of the project and build/buy decisions. Launched on schedule at HR tech in October."),
                        React.createElement("li", null, "May 2013 - Lead a technology, tools and UI transformation program for the SBS flagship product RUN. Successfully migrated over 200,000 clients to a modern responsive design UI in 4 weeks."),
                        React.createElement("li", null, "Successfully delivered multiple product releases across three product lines to production and achieved 99.99% uptime across all products."),
                        React.createElement("li", null, "Direct budget responsibility of $25 million with an integrated team of 200+ associates across the UK/US/India."),
                        React.createElement("li", null, "Developed and launched a new HR offering for RUN in the US SBS market (Oct 2012). Implemented next generation tools/technologies to support the Migrated development team to next generation tools and technologies (TFS/JSON/MVC)."),
                        React.createElement("li", null, "Created a new product development unit for mobile development. Led the expansion of core platforms to support the launch of native iPhone/Android applications (RUN Mobile) for payroll entry + HR."),
                        React.createElement("li", null, "Matured our performance capacity and testing functions to performance test every release (includes small patches) to ensure production uptime (always above 99.99%) and to ensure we are always 12 months ahead of our production system."),
                        React.createElement("li", null, "Completed M&A for a HR product (HR411) driving the technical review and successful integration into our core platforms (LAMP stack into an MS stack).")))),
            React.createElement(ContentBlock, { title: "VP Product Development / Managing Director @ ADP (BMS UK)  Jan 2005 - Dec 2008" },
                React.createElement("div", null,
                    "Responsibility for all product architecture/engineering functions for the SBS division (US) and the ADP freedom products (UK). Responsible for the architecture, ux, development and engineering teams. Over 450, 000 companies are running on the platforms with revenues exceeding nearly 1 billion. All products were designed and created ground up with small components integrated via M&A (HR411).",
                    React.createElement("ul", { className: "list-disc", style: { marginLeft: "10px" } },
                        React.createElement("li", null, "Improved development and engineering controls to meet standards required for SAS70 without compromising agile development productivity."),
                        React.createElement("li", null, "Promoted in late 2007 and given full development responsibility of the RUN development organisation after completing the development and launch of the full tax service product for RUN."),
                        React.createElement("li", null, "Headed the technology strategy and development teams responsible for creating the JPM Chase branded HR/payroll product."),
                        React.createElement("li", null, "Led an initiative to create a next generation service platform (Road Runner) which resulted in a 75% productivity improvement for key business functions for the SBS service organization (1000+ reps). Reduced training times for traditional systems from six months to a few weeks."),
                        React.createElement("li", null, "Promoted to VP and appointed Manager Director of ADP BMS (UK) in 2005. Responsibility of all development functions (business analysis/product management, development, test, architecture) along with HR/Finance functions.")))),
            React.createElement(ContentBlock, { title: "Chief Architect/CTO @ ADP (BMS UK) - May 2000  - Dec 2004" },
                React.createElement("div", null,
                    "Led the engineering teams and had overall responsibilty for the technology strategy.",
                    React.createElement("ul", { className: "list-disc", style: { marginLeft: "10px" } },
                        React.createElement("li", null,
                            " ",
                            "Led the architecture and technology teams responsible for creating the Microsoft Office Accounting Payroll Product/Service. Worked closely with Microsoft to create deep integration using web services and advanced synchronization techniques"),
                        React.createElement("li", null, "Reduced hosting cost per client by 90% by migrating a VB/ASP product stack to C#/ASP.net."),
                        React.createElement("li", null, "Bridged the integration of ADP BMS (UK) with ADP Corporate (US) keeping the startup and innovation culture."),
                        React.createElement("li", null, "Matured and transformed a client server development team into a modern web development team. Created distinct engineering and development disciplines and functions to ensure repeatable builds and product quality. Hired, mentor and coached all function heads."),
                        React.createElement("li", null, "Led the design of the product/production architecture for the first native SaaS product in ADP (TotalChoice). Launched UK version of the product in 2004 (ADP freedom).")),
                    React.createElement("p", null, "Expertise Areas"),
                    React.createElement("ul", { style: { marginLeft: "10px" } },
                        React.createElement("li", null, "ASP/ASP.Net, SQL Server, Windows Server, SaaS architecture, CA SiteMinder")))),
            React.createElement(ContentBlock, { title: "Developer/Architect & Data Interchange - 1990 - 2000" },
                React.createElement("div", null,
                    "Lead architect/developer for the ODEX EPIC product. Researched and implemented key features of Windows NT to create a scalable variant of our UNIX product.",
                    React.createElement("ul", { style: { marginLeft: "10px" } },
                        React.createElement("li", null, "Implemented numerous communications sub-systems to support protocols like X.25 and ISDN across multiple product platforms (Windows, UNIX and VAX/VMS)."),
                        React.createElement("li", null, "Created the Windows version of ODEX. Led the development and converted/ported key parts of our UNIX product set."),
                        React.createElement("li", null, "Created a product (DISP) for converting EDI file formats into reports used by large SMMT companies."),
                        React.createElement("li", null, "Implemented numerous communications sub-systems to support protocols like X.25 and ISDN across multiple product platforms (Windows, UNIX and VAX/VMS).")),
                    React.createElement("p", null, "Expertise Areas"),
                    React.createElement("ul", { style: { marginLeft: "10px" } },
                        React.createElement("li", null, "Assembler (x86) , C/C++, UNIX, Windows, VMS, X.25, ISDN, EDI (EDIFACT X.12), OFTP, UML")))),
            React.createElement(ContentBlock, { title: "Author @ Wrox Press - 1999-2003" },
                React.createElement("div", null,
                    React.createElement("p", null, "Co-authored several technical books covering topics such as ASP.net, XML and components."),
                    React.createElement("p", null,
                        "Published the first book on ASP.net which was given out by Microsoft to all attendees of the Microsoft Profession Developers Conference. Worked with the legendary",
                        " ",
                        React.createElement("a", { href: "https://www.linkedin.com/in/guthriescott/" }, "Scott Guthrie"),
                        " ",
                        "on this book, along with Alex Homer,",
                        " ",
                        React.createElement("a", { href: "https://www.linkedin.com/in/davesussman/", target: "_blank" }, "Dave Sussman"),
                        " ",
                        "and Rob Howard."),
                    React.createElement("p", null,
                        React.createElement("img", { className: "inline", style: { height: "250px" }, src: "images/me/first book.jpg" }),
                        React.createElement("img", { className: "inline", style: { height: "250px", marginLeft: "15px" }, src: "images/me/book_proaspnet.jpg" })))),
            React.createElement(ContentBlock, { title: "Key skills" },
                React.createElement("ul", null,
                    React.createElement("li", null, "Global Product Development & Program/Product Management"),
                    React.createElement("li", null, "UX Leadership & Management"),
                    React.createElement("li", null, "Innovative Product Design"),
                    React.createElement("li", null, "Executive Leadership"),
                    React.createElement("li", null, "Cloud/SaaS/Software Architecture at scale"),
                    React.createElement("li", null, "Organization and Talent Development"),
                    React.createElement("li", null, "M&A / Change Management"),
                    React.createElement("li", null, "Strategic Planning"),
                    React.createElement("li", null, "P&L, Budget Management and Cost Control"))),
            React.createElement(ContentBlock, { title: "LinkedIn" },
                React.createElement("p", null,
                    "Additional information can be found on",
                    " ",
                    React.createElement("a", { href: "https://www.linkedin.com/in/richardanderson5/" }, "LinkedIn."))))));
};
export default Resume;
