import * as React from "react";
import ContentBlock from "../../../components/ContentBlock";
export default function About() {
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentBlock, { title: "About React Table" },
            React.createElement(React.Fragment, null,
                React.createElement("p", null,
                    "Component home page",
                    " ",
                    React.createElement("a", { href: "https://tanstack.com/table/v8", target: "new" }, "here")),
                React.createElement("p", null,
                    "Github repo",
                    " ",
                    React.createElement("a", { href: "https://github.com/tanstack/table", target: "new" }, "here"))))));
}
