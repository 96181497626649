import * as React from "react";
import { Outlet, useOutlet, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { flushSync } from "react-dom";
import AdminPanel from "../components/AdminPanel";
import AdminHeader from "./AdminHeader";
import GoogleOneTouch from "../components/google/GoogleOneTouch";
const Admin = (props) => {
    const outlet = useOutlet(); // null if no child declared in router
    const authenticated = useSelector((state) => state.user.authenticated);
    // render the google login button
    if (authenticated !== true) {
        setTimeout(() => {
            flushSync(() => {
                google.accounts.id.renderButton(document.getElementById("googleButtonDiv"), { theme: "outline", size: "large" } // customization attributes
                );
            });
        }, 500);
    }
    var context = {
        authenticated: authenticated,
        message: "yo!!!!",
        hello: () => {
            alert("world");
        },
    };
    return (React.createElement("div", null,
        !authenticated && React.createElement(GoogleOneTouch, null),
        authenticated && outlet && React.createElement(Outlet, { context: context }),
        authenticated && !outlet && (React.createElement("div", null,
            React.createElement(AdminHeader, { title: "Admin Home" }),
            React.createElement("div", { className: "adminbody main" },
                React.createElement(AdminPanel, null)))),
        !authenticated && (React.createElement("div", null,
            React.createElement("h1", null, "Please login..."),
            React.createElement("div", { id: "googleButtonDiv" })))));
};
export default Admin;
export function useAdminContext() {
    return useOutletContext();
}
