import * as React from "react";
import { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import ContentBlock from "../../components/ContentBlock";
import SimpleHookDemo from "./simple";
import { PortalDemo } from "./portal";
import { useSelector } from "react-redux";
import { s3UploadFile } from "../../utils/s3";
// References: https://bobbyhadz.com/blog/aws-s3-presigned-url-react
import { rich as richUserProfile } from "../../data/userProfiles";
const UploadUserProile = () => {
    const riouid = useSelector((state) => state.user.riouid);
    async function upload() {
        var profileMetadata = JSON.stringify(richUserProfile);
        const filetype = "json";
        let s3Filename = "newsite/public/profiles/" + riouid + ".json";
        richUserProfile.s3Filename = s3Filename;
        await s3UploadFile("rio-file-upload", s3Filename, filetype, profileMetadata)
            .then((s3Url) => {
            alert("uploaded meta data");
        })
            .catch((err) => {
            alert("s3 upload failed: " + err);
        });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, "User Profile Upload"),
        React.createElement("button", { onClick: upload }, "Upload")));
};
const HookDemos = () => {
    const [filename, setFilename] = useState("");
    const [filetype, setFiletype] = useState("");
    const [filesize, setFilesize] = useState(0);
    const [fileUploadedUrl, setFileUploadedUrl] = useState("");
    const handleFileChange = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        const [type] = fileObj.type.split("/");
        //    if (!type || type !== 'image') {
        //      alert('You can only upload image files.');
        //      return;
        //    }
        if (fileObj.size > 3000000) {
            alert("file is too big, limit is ~3mb");
            return;
        }
        setFilename(fileObj.name);
        setFiletype(fileObj.type);
        setFilesize(fileObj.size);
    };
    async function handleSubmit(e) {
        var presignedPostUrl;
        e.preventDefault();
        var input = document.querySelector('input[type="file"]');
        // @ts-ignore
        if (input.files.length === 0) {
            alert("pick a file");
            return;
        }
        let s3Filename = "newsite/public/" + filename;
        // @ts-ignore
        let filedata = input.files[0];
        await s3UploadFile("rio-file-upload", s3Filename, filetype, filedata)
            .then((s3Url) => {
            setFileUploadedUrl(s3Url);
        })
            .catch((err) => {
            alert("s3 upload failed: " + err);
        });
        return;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentBlock, { title: "React Hooks" },
            React.createElement(React.Fragment, null,
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement(Link, { to: "simple" }, "My first hook demo"),
                        " ",
                        React.createElement("br", null)),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "portal" }, "React Portal"),
                        " ",
                        React.createElement("br", null)),
                    React.createElement("li", null,
                        React.createElement("a", { href: "https://www.netlify.com/blog/2019/03/11/deep-dive-how-do-react-hooks-really-work" }, "how hooks really work")),
                    React.createElement("li", null,
                        React.createElement("a", { href: "https://github.com/rehooks/awesome-react-hooks" }, "awesome-react-hooks - large collection of hooks"))))),
        React.createElement(ContentBlock, { title: "Pick a file to upload to S3" },
            React.createElement(React.Fragment, null,
                React.createElement("form", { onSubmit: (e) => handleSubmit(e) },
                    React.createElement("p", null,
                        "pick a file...",
                        React.createElement("br", null),
                        React.createElement("input", { type: "file", accept: "*", onChange: handleFileChange })),
                    React.createElement("input", { type: "submit", value: "Upload" })),
                filename.length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("h3", null, "Selected file info"),
                    React.createElement("p", null,
                        "Name: ",
                        filename),
                    React.createElement("p", null,
                        "Type: ",
                        filetype),
                    React.createElement("p", null,
                        "Size: ",
                        filesize))),
                fileUploadedUrl.length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        "Uploaded file Url: ",
                        fileUploadedUrl),
                    React.createElement("img", { src: fileUploadedUrl }))),
                React.createElement("p", null,
                    "S3 upload bucket:",
                    " ",
                    React.createElement("a", { href: "https://s3.console.aws.amazon.com/s3/buckets/rio-file-upload?region=us-east-1&tab=objects" }, "s3 bucket")),
                React.createElement(UploadUserProile, null))),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement(Outlet, null)));
};
export function getRoutes() {
    let childRoutes = [
        { path: "simple", element: React.createElement(SimpleHookDemo, null) },
        { path: "portal", element: React.createElement(PortalDemo, null) },
    ];
    let routes = [
        { path: "reacthooks", element: React.createElement(HookDemos, null), children: childRoutes },
    ];
    return routes;
}
