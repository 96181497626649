//import React, { useEffect, useState } from "react";
import { useEffect, useRef } from "react";
import * as React from "react";
import ContentBlock from "./ContentBlock";
import { GoogleUserProfile } from "./google/GoogleUserProfile";
var options = {
    pageBackgroundColor: "black",
};
const About = () => {
    const canvasContainerRef = useRef(null);
    useEffect(() => { });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "bg-green-500 md:bg-red-500 lg:bg-yellow-500 xl:bg-purple-500 2xl:bg-pink-100" }, "Hi"),
        React.createElement("div", { className: "py-8 px-8 max-w-sm mx-auto bg-white rounded-xl shadow-lg space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6" },
            React.createElement("img", { className: "block mx-auto h-20 rounded-full sm:mx-0 sm:shrink-0", src: "images/me/rich.jpeg", alt: "Picture of Rich Anderson" }),
            React.createElement("div", { className: "text-center space-y-2 sm:text-left" },
                React.createElement("div", { className: "space-y-0.5" },
                    React.createElement("p", { className: "text-lg text-black font-semibold" }, "Rich Anderson"),
                    React.createElement("p", { className: "text-slate-500 font-medium" }, "Product Guy")),
                React.createElement("button", { className: "px-4 py-1 text-sm text-blue-600 font-semibold rounded-full border border-purple-200 hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2" }, "Message"))),
        React.createElement(ContentBlock, { title: "Google Profile Information" },
            React.createElement(GoogleUserProfile, null)),
        React.createElement(ContentBlock, { title: "HCE Demos" },
            React.createElement("div", null,
                React.createElement("a", { href: "/demos/flyship/index.html" }, "Fly Ship"),
                " ",
                React.createElement("br", null),
                React.createElement("a", { href: "/demos/hcmminer/index.html" }, "HCM Miner"),
                React.createElement("br", null),
                React.createElement("a", { href: "/demos/hello3d/index.html" }, "Hello 3d"),
                React.createElement("br", null),
                React.createElement("a", { href: "/demos/helloworld/index.html" }, "Hello World"),
                React.createElement("br", null),
                React.createElement("a", { href: "/demos/shapes/index.html" }, "Shapes"),
                React.createElement("br", null),
                React.createElement("a", { href: "/demos/simplegamedemo/index.html" }, "Simple Game Demo"),
                React.createElement("br", null),
                React.createElement("a", { href: "/demos/showdotproduct/index.html" }, "Show dot product"),
                React.createElement("br", null)))));
};
export default About;
