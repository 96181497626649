import { createListenerMiddleware } from "@reduxjs/toolkit";
import { userLoggedIn as googleUserLoggedIn } from "../features/google/googleSlice";
import { store } from "./store";
import { Config } from "../config/config";
import { userLoggedIn } from "../app/appUserSlice";
export const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
    actionCreator: googleUserLoggedIn,
    effect: async (action, listenerApi) => {
        let config = new Config();
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ Jwt: store.getState().google.jwt }),
        };
        //console.log("apiServer: " + config.data.apiServer + "/api/jwt");
        // authenticate to richanderson.io with google identity
        const rawResponse = await fetch(config.data.apiServer + "/api/v1/auth/google", requestOptions);
        var json = await rawResponse.json();
        store.dispatch(userLoggedIn(json.NewSessionJwt));
        //          console.log("response: " + json.StatusCode + ":" + json.ErrorMessage);
    },
});
