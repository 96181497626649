export const stdJsonFetchHeaders = {
    "Content-type": "application/json; charset=UTF-8",
};
export async function rioFetch(apiName, url, verb, jwt) {
    const headers = {
        "Content-type": "application/json; charset=UTF-8",
    };
    if (typeof jwt !== "undefined") {
        headers.Authorization = "Bearer " + jwt;
    }
    const response = await fetch(url, {
        method: verb,
        headers,
    });
    if (response.status !== 200) {
        throwIfNot200Response(response, apiName);
    }
    return response;
}
//*************************************************************************************
// internal helper functions
export function throwIfNot200Response(response, api) {
    if (response.status !== 200) {
        throw (api +
            " - status not 200: " +
            response.status +
            " - " +
            response.statusText);
    }
    return;
}
