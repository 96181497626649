import * as React from "react";
import { Outlet, Link } from "react-router-dom";
import ContentBlock from "../components/ContentBlock";
import { getRoutes as getReactTableRoutes } from "./react-table";
import { getRoutes as getHceRoutes } from "./hce";
import { getRoutes as getHooksRoutes } from "./hooks";
import { getRoutes as getDynanmicUIRoute } from "./dynamicui";
import { getRoutes as getTailwindcssRoutes } from "./tailwindcss";
const Experimental = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentBlock, { title: "Experimental Work" },
            React.createElement(React.Fragment, null,
                React.createElement(Link, { to: "/" }, "Site Homepage"),
                " ",
                React.createElement("br", null),
                React.createElement(Link, { to: "reacttable" }, "React Table"),
                React.createElement("br", null),
                React.createElement(Link, { to: "hce" }, "HCE"),
                " ",
                React.createElement("br", null),
                React.createElement(Link, { to: "reacthooks" }, "React hooks "),
                " ",
                React.createElement("br", null),
                React.createElement(Link, { to: "dynamicui" }, "Dynamic UI"),
                " ",
                React.createElement("br", null),
                React.createElement(Link, { to: "tailwindcss" }, "Tailwind Css"),
                " ",
                React.createElement("br", null))),
        React.createElement(Outlet, null)));
};
export function getRoutes() {
    let routes = [
        {
            path: "/exp",
            element: React.createElement(Experimental, null),
            children: [
                ...getReactTableRoutes(),
                ...getHceRoutes(),
                ...getHooksRoutes(),
                ...getDynanmicUIRoute(),
                ...getTailwindcssRoutes(),
            ],
        },
    ];
    return routes;
}
