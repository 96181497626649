var apiServer = __API__;
import { stdJsonFetchHeaders, throwIfNot200Response } from "./utils";
export async function rioGetUsers(jwt) {
    var url = apiServer + "/api/users";
    const response = await fetch(url, {
        method: "GET",
        headers: { ...stdJsonFetchHeaders, Authorization: "Bearer " + jwt },
    });
    return (await response.json()).Users;
}
// return the new user id added if successfult
export async function rioAddUser(jwt, user) {
    var url = apiServer + "/api/users";
    const response = await fetch(url, {
        method: "POST",
        headers: { ...stdJsonFetchHeaders, Authorization: "Bearer " + jwt },
        body: JSON.stringify(user),
    });
    throwIfNot200Response(response, "rioAddUser");
    if (response.status !== 200) {
        throw "roiAddUser failed status code " + response.status;
    }
    return await response.json();
}
export async function rioGetUser(jwt, key) {
    var url = apiServer + "/api/users/" + key;
    const response = await fetch(url, {
        method: "GET",
        headers: { ...stdJsonFetchHeaders, Authorization: "Bearer " + jwt },
    });
    if (response.status !== 200)
        throwIfNot200Response(response, "rioGetUser");
    return (await response.json()).User;
}
export async function rioUpdateUser(jwt, key, user) {
    var url = apiServer + "/api/users/" + key;
    const response = await fetch(url, {
        method: "PATCH",
        headers: { ...stdJsonFetchHeaders, Authorization: "Bearer " + jwt },
        body: JSON.stringify(user),
    });
    if (response.status !== 200)
        throwIfNot200Response(response, "rioUpdateUser");
    return;
}
export async function rioDeleteUser(jwt, key) {
    var url = apiServer + "/api/users/" + key;
    const response = await fetch(url, {
        method: "DELETE",
        headers: { ...stdJsonFetchHeaders, Authorization: "Bearer " + jwt },
    });
    if (response.status !== 200)
        throwIfNot200Response(response, "rioDeleteUser");
    return;
}
