import { configureStore } from "@reduxjs/toolkit";
import appUserReducer from "./appUserSlice";
import counterReducer from "../features/counter/counterSlice";
import googleReducer from "../features/google/googleSlice";
import { listenerMiddleware } from "./listener";
import { listenerMiddleware2 } from "./listener2";
function ok(getDefaultMiddleware) {
    var a = getDefaultMiddleware().prepend(listenerMiddleware.middleware);
    a = a.prepend(listenerMiddleware2.middleware);
    return a;
}
export const store = configureStore({
    reducer: {
        counter: counterReducer,
        google: googleReducer,
        user: appUserReducer,
    },
    middleware: (getDefaultMiddleware) => ok(getDefaultMiddleware),
});
