import HceScene from "../../../lib/hce/v3/engine/HceScene";
import { Scroller } from "./Scroller";
import { Starfield } from "./Starfield";
export class TitleScene extends HceScene {
    x = 30;
    y = 40;
    constructor(hce) {
        super(hce);
    }
    onStart() {
        this.rootObject.sceneContext = this;
        var scroller = new Scroller(this.hce);
        scroller.text = "Welcome to GPT Conect 2022.....the 3D wars have started!";
        this.rootObject.addChild(scroller);
        var starfield = new Starfield(this.hce);
        starfield.init();
        this.rootObject.addChild(starfield);
    }
    onRender(context) {
        //context.ctx.fillStyle = "white"
        var gradient = context.ctx.createLinearGradient(0, 0, context.hce.canvas.width, 0);
        gradient.addColorStop("0", "lightblue");
        gradient.addColorStop("0.5", "lightblue");
        gradient.addColorStop("1.0", "white");
        context.ctx.fillStyle = gradient;
        context.ctx.font = "42px Courier";
        context.ctx.fillText("3D Wars", this.x, this.y);
        context.ctx.fillText("-------", this.x, this.y + 20);
        this.rootObject.tick(null, 0);
        this.rootObject.render(context.ctx);
        this.x++;
    }
}
