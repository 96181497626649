import { MyVector, HceObject, } from "../../../lib/hce/v3/engine/BaseObjects";
export class ParticleGenerator extends HceObject {
    bodyColor;
    alpha;
    decay;
    sizeDecay;
    size;
    color;
    generating;
    msMaxDuration;
    maxParticles;
    particlesGenerated;
    msRemainingDuration;
    onNewParticle;
    points;
    object;
    lineColor;
    constructor(hce) {
        super(hce);
        this.bodyColor = "#aaaaaa";
        this.alpha = 255;
        this.decay = 5;
        this.sizeDecay = 0.01;
        this.size = 3;
        this.color = "orange";
        this.createMyPoints();
        this.tag = "particlegenerator";
        this.generating = true; // on by default
        this.msMaxDuration = 1000;
        this.maxParticles = -1;
        this.particlesGenerated = 0;
        this.msRemainingDuration = 0;
    }
    onSceneReady(sceneContext) {
        this.sceneContext = sceneContext;
        this.msRemainingDuration = this.msMaxDuration;
    }
    onTick(context) {
        if (this.msRemainingDuration > 0) {
            this.msRemainingDuration -= this.hce.hceCore.time.frameDelta;
            if (this.msRemainingDuration <= 0) {
                this.generating = false;
            }
        }
        if (this.generating === true) {
            for (var i = 0; i < 1; i++) {
                if (this.maxParticles === -1 ||
                    this.particlesGenerated < this.maxParticles) {
                    var p = this.createNewParticle();
                    if (this.onNewParticle) {
                        this.onNewParticle(p);
                    }
                    this.particlesGenerated++;
                    p.size = this.size;
                }
            }
        }
    }
    createNewParticle() {
        var p = new Particle(this.hce);
        p.position = this.position.clone();
        p.velocity.x = Math.random() * 2;
        p.velocity.y = Math.random() * 2;
        p.alpha = this.alpha;
        p.decay = this.decay;
        p.sizeDecay = this.sizeDecay;
        p.color = this.color;
        this.addChild(p);
        p.detachedFromParentBody = true;
        return p;
    }
    onRender(c) {
        var x = this.position.x;
        var y = this.position.y;
        c.beginPath();
        c.fillStyle = this.bodyColor;
        c.arc(x, y, 4, 0, 2 * Math.PI);
        c.stroke();
        c.fill();
    }
    createMyPoints() {
        var points = [];
        this.width = 64;
        this.height = 64;
        points.push({ x: this.position.x, y: this.position.y });
        points.push({ x: this.position.x + this.width, y: this.position.y });
        points.push({
            x: this.position.x + this.width,
            y: this.position.y - this.height,
        });
        points.push({ x: this.position.x, y: this.position.y - this.height });
        points.push({ x: this.position.x, y: this.position.y });
        this.points = points;
        return points;
    }
    initObject(obj) {
        obj.color = this.color;
        obj.tag = this.tag;
        obj.height = this.height;
        obj.width = this.width;
        obj.points = this.points;
        obj.alpha = this.alpha;
        obj.decay = this.decay;
        obj.sizeDecay = this.sizeDecay;
        obj.size = this.size;
        obj.msMaxDuration = this.msMaxDuration;
    }
    setObject(obj) {
        this.object = obj; // design time obj information
        if (obj.points && obj.points.length >= 1) {
            this.position.x = obj.points[0].x;
            this.position.y = obj.points[0].y;
        }
        else {
            obj.points = this.points;
        }
        this.color = obj.fillColor;
        this.lineColor = obj.lineColor;
        this.tag = this.object.tag;
        if (obj.height)
            this.height = obj.height;
        if (obj.width)
            this.width = obj.width;
        if (obj.color)
            this.color = obj.color;
        if (obj.alpha)
            this.alpha = obj.alpha;
        if (obj.decay)
            this.decay = obj.decay;
        if (obj.sizeDecay)
            this.sizeDecay = obj.sizeDecay;
        if (obj.size)
            this.size = obj.size;
        if (obj.msMaxDuration)
            this.msMaxDuration = obj.msMaxDuration;
        if (obj.components) {
            obj.components.forEach((component) => {
                var mc = new MoveComponent();
                mc.setObject(component);
                this.components.push(mc);
            });
        }
    }
}
export class Particle extends HceObject {
    size;
    velocity;
    alpha;
    decay;
    sizeDecay;
    color;
    maxSize;
    constructor(hce) {
        super(hce);
        this.velocity = new MyVector(1, 1);
        //this.position.x = Math.random() * 800;
        //this.position.y = Math.random() * 800;
        this.color = "orange";
        this.tag = "particle";
        this.alpha = 255;
        this.decay = 5;
        this.sizeDecay = 0.09;
        this.maxSize = 20;
        this.size = Math.random() * this.maxSize;
    }
    onTick(context) {
        this.tickcount = this.tickcount + 1 * this.hce.hceCore.time.scale;
        this.alpha -= this.decay * this.hce.hceCore.time.scale;
        if (this.alpha < 0)
            this.visible = false;
        this.size =
            this.size - this.size * this.sizeDecay * this.hce.hceCore.time.scale;
        this.position.x += this.velocity.x * this.hce.hceCore.time.scale;
        this.position.y += this.velocity.y * this.hce.hceCore.time.scale;
    }
    onRender(c) {
        if (!this.visible)
            return;
        if (this.tickcount > 255 / this.decay)
            return;
        c.globalAlpha = (1 / 255) * this.alpha;
        c.beginPath();
        c.fillStyle = this.color;
        c.arc(this.position.x, this.position.y, this.size, 0, 2 * Math.PI);
        c.fill();
        c.globalAlpha = 1;
    }
}
