import { HceObject, } from "../../lib/hce/v3/engine/BaseObjects";
export class Scroller extends HceObject {
    scrollSpeed;
    scrollX;
    text = "                                                                                                                     When a ship appears use the keys a, d, space and enter to control it.     Double click the background area to hide the text.       Press the - key to launch more ships.      Press keys 1-4 for music.     Press r to reset the scene.      Enjoy!!      ";
    timer;
    gradient = null;
    constructor(hce) {
        super(hce);
        this.scrollSpeed = 1.25;
    }
    onSceneReady(screenContext) {
        this.scrollX = this.hce.canvas.width;
        this.timer = 1;
    }
    onTick(ctx) {
        this.scrollX -= this.scrollSpeed;
    }
    onRender(ctx) {
        var y = this.hce.canvas.height / 8 + ((this.hce.canvas.height / 8) * 6);
        if (this.gradient === null) {
            this.gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
            this.gradient.addColorStop("0", "orange");
            this.gradient.addColorStop("0.5", "aqua");
            this.gradient.addColorStop("1.0", "yellow");
        }
        ctx.fillStyle = this.gradient;
        ctx.font = "20px Courier";
        const fontSize = ctx.measureText("Welcome...");
        const fontHeight = fontSize.fontBoundingBoxAscent + fontSize.fontBoundingBoxDescent;
        const actualHeight = fontSize.actualBoundingBoxAscent + fontSize.actualBoundingBoxDescent;
        let sinSpeed = 200;
        y =
            y +
                Math.sin(this.timer / sinSpeed) *
                    (this.hce.canvas.height / 8 - actualHeight) +
                actualHeight / 8; // place just above the bottom c64 graphic
        y = 26;
        ctx.fillText(this.text, this.scrollX, y);
        this.timer = this.timer + 1;
        this.gradient = null;
    }
}
