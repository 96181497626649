import * as React from "react";
import { useOnKey } from "../lib/hooks/useOnKey";
import { useNavigate } from "react-router-dom";
const adminCommands = [
    {
        cmd: "/home",
        handler: (options) => {
            options.navigate("/");
        },
    },
    {
        cmd: "/admin",
        handler: (options) => {
            options.navigate("/admin");
        },
    },
    {
        cmd: "/log",
        handler: (options) => {
            options.navigate("/admin/log");
        },
    },
    {
        cmd: "/exp",
        handler: (options) => {
            options.navigate("/exp");
        },
    },
    {
        cmd: "/links",
        handler: (options) => {
            options.navigate("/links");
        },
    },
    {
        cmd: "/resume",
        handler: (options) => {
            options.navigate("/resume");
        },
    },
    {
        cmd: "/c64",
        handler: (options) => {
            options.navigate("/c64");
        },
    },
    {
        cmd: "/about",
        handler: (options) => {
            options.navigate("/about");
        },
    },
];
export function AdminQuickKeys(props) {
    const [keysPressed, setKeysPressed] = useOnKey();
    let navigateHook = useNavigate();
    let navigateCalled = false;
    let options = {
        navigate: (url) => {
            navigateCalled = true;
            setTimeout(() => {
                // we use a timer to avoid the navigating why still rendering which causes an error
                navigateHook(url);
            }, 500);
        },
    };
    const matchedCommands = adminCommands.filter((c) => keysPressed.includes(c.cmd));
    if (matchedCommands.length > 0) {
        setKeysPressed("");
        if (props.addMessage)
            props.addMessage("AdminQuickKeys command matched: " + matchedCommands[0].cmd);
        if (matchedCommands[0].handler) {
            matchedCommands[0].handler(options);
            if (navigateCalled) {
                return null;
            }
        }
        setKeysPressed("");
    }
    return React.createElement(React.Fragment, null);
}
