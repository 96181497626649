import * as React from "react";
import { useRef } from "react";
export const HceFullScreenDiv = (props) => {
    const mainDivRef = useRef();
    let fullScreenStyle = {
        padding: "0px",
        position: "absolute",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
        zIndex: props.zIndex ? props.zIndex : -100,
        background: "black",
    };
    const observer = React.useRef(new ResizeObserver((entries) => {
        entries.forEach((entry) => {
            props.onDivResized(entry.contentRect.width, entry.contentRect.height);
        });
    }));
    // track the window size track and keep the canvas in sync
    React.useEffect(() => {
        if (mainDivRef.current) {
            observer.current.observe(mainDivRef.current);
        }
        return () => {
            observer.current.unobserve(mainDivRef.current);
        };
    }, [observer, mainDivRef]);
    return (React.createElement("div", { ref: mainDivRef, style: fullScreenStyle }, props.children));
};
export default HceFullScreenDiv;
