import * as React from "react";
import { useState, useEffect } from "react";
import ContentBlock from "../../components/ContentBlock";
import Button from "../../lib/comp/Button";
function test(delay) {
    return new Promise((res, rej) => {
        setTimeout(() => {
            let a = import("./SplitTest");
            res(a);
        }, delay);
    });
}
//import SplitTest from "./SplitTest"
const SplitTest = React.lazy(() => test(2000));
const EditableUIComponent = (props) => {
    const [mouseOver, setMouseOver] = useState(false);
    function onMouseEnter() {
        setMouseOver(true);
        if (props.onMouseEnter) {
            props.onMouseEnter(props.index);
        }
    }
    function onMouseLeave() {
        setMouseOver(false);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave }, props.children)));
};
const ComponentOne = (props) => {
    return React.createElement("h1", null, props.title);
};
const ComponentTwo = (props) => {
    return React.createElement("h2", null, props.title);
};
const ComponentText = (props) => {
    return React.createElement("p", null, props.text);
};
const ComponentImage = (props) => {
    return React.createElement("img", { src: props.url });
};
const DynamicUI = () => {
    const [myUi, setUi] = useState([]);
    const [textPropValue, setTextPropValue] = useState("Hello World!");
    const [urlPropValue, setUrlPropValue] = useState("https://www.richanderson.io/images/me/rich.jpeg");
    // build initial ui on load
    useEffect(() => {
        const myTempUi = [];
        setUi(myTempUi);
    }, []);
    function onMouseEnterUiElement(index) {
        console.log(index);
        console.log(myUi[index].props);
        if (myUi[index].props.title) {
            console.log("it has a title, wow!");
            setTextPropValue(myUi[index].props.title);
        }
    }
    // build the ui dynamically
    const elements = [];
    myUi.forEach((line) => {
        const element = React.createElement(line.element, line.props, null);
        // wrap the element so we can track if the user is trying to interact with it
        const runtime = React.createElement(EditableUIComponent, { index: elements.length, onMouseEnter: onMouseEnterUiElement }, [element]);
        elements.push(runtime);
    });
    function addH1Component() {
        const newUi = [...myUi];
        const newLine = {
            element: ComponentOne,
            props: {
                title: textPropValue,
            },
        };
        newUi.push(newLine);
        setUi(newUi);
    }
    function addH2Component() {
        const newUi = [...myUi];
        const newLine = {
            element: ComponentTwo,
            props: {
                title: textPropValue,
            },
        };
        newUi.push(newLine);
        setUi(newUi);
    }
    function addTextComponent() {
        const newUi = [...myUi];
        const newLine = {
            element: ComponentText,
            props: {
                text: textPropValue,
            },
        };
        newUi.push(newLine);
        setUi(newUi);
    }
    function addImageComponent() {
        const newUi = [...myUi];
        const newLine = {
            element: ComponentImage,
            props: {
                url: urlPropValue,
            },
        };
        newUi.push(newLine);
        setUi(newUi);
    }
    function handleTextPropValueChange(event) {
        setTextPropValue(event.target.value);
    }
    function handleUrlPropValueChange(event) {
        setUrlPropValue(event.target.value);
    }
    function onResetUI(event) {
        setUi([]);
    }
    function onSaveUI(event) {
        alert(JSON.stringify(myUi));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentBlock, { title: "UI Editor" },
            React.createElement(React.Fragment, null,
                React.createElement("p", null, "Use the buttons and props to create new UI elements."),
                React.createElement(Button, { onClick: addH1Component }, "Add Heading 1"),
                React.createElement(Button, { onClick: addH2Component }, "Add Heading 2"),
                React.createElement(Button, { onClick: addTextComponent }, "Add Paragraph"),
                React.createElement(Button, { onClick: addImageComponent }, "Add Image"),
                React.createElement(Button, { onClick: onResetUI }, "Clear UI"),
                React.createElement(Button, { onClick: onSaveUI }, "Save"),
                React.createElement("p", null,
                    "text:",
                    " ",
                    React.createElement("input", { className: "border-2", type: "text", size: 50, value: textPropValue, onChange: handleTextPropValueChange }),
                    " "),
                React.createElement("p", null,
                    "url:",
                    " ",
                    React.createElement("input", { className: "border-2", type: "url", size: 50, value: urlPropValue, onChange: handleUrlPropValueChange }),
                    " "))),
        React.createElement(ContentBlock, { title: "Dynamic UI" },
            React.createElement(React.Fragment, null,
                "You can move the mouse over the UI elements you add to see the text property.",
                elements)),
        React.createElement(React.Suspense, { fallback: React.createElement(React.Fragment, null, "Loading..") },
            React.createElement(React.Fragment, null,
                React.createElement(SplitTest, null),
                React.createElement("h1", null, "all good"),
                React.createElement("p", null, "Yep!")))));
};
export function getRoutes() {
    let routes = [{ path: "dynamicui", element: React.createElement(DynamicUI, null) }];
    return routes;
}
