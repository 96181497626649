import HceScene from "../../../lib/hce/v3/engine/HceScene";
import { ParticleGenerator } from "./ParticleGenerator";
export class LoadingScene extends HceScene {
    pleaseWaitInitText = "Please wait.";
    pleaseWaitText = this.pleaseWaitInitText;
    loadingTimerHandle = null;
    pg = [];
    am;
    constructor(hce) {
        super(hce);
    }
    onStart() {
        var i;
        for (i = 0; i < 30; i++) {
            var pg = new ParticleGenerator(this.hce);
            pg.sceneContext = this;
            pg.position.x = Math.random() * 1200;
            pg.position.y = Math.random() * 1200;
            this.pg.push(pg);
            this.rootObject.addChild(pg);
        }
        this.loadingTimerHandle = setInterval(() => {
            this.pleaseWaitText = this.pleaseWaitText + ".";
            if (this.pleaseWaitText.length > this.pleaseWaitInitText.length + 3) {
                this.pleaseWaitText = this.pleaseWaitInitText;
            }
        }, 1000);
    }
    onStop() {
        if (this.loadingTimerHandle !== null) {
            clearInterval(this.loadingTimerHandle);
        }
    }
    onRender(context) {
        var gradient = context.ctx.createLinearGradient(0, 0, context.hce.canvas.width, 0);
        gradient.addColorStop("0", "lightblue");
        gradient.addColorStop("0.5", "lightblue");
        gradient.addColorStop("1.0", "white");
        context.ctx.fillStyle = gradient;
        context.ctx.font = "32px Courier";
        context.ctx.fillText(this.pleaseWaitText, 20, 30);
        this.rootObject.tick(null, 0);
        this.rootObject.render(context.ctx);
        this.pg.map((p) => {
            p.position.x += 4 + Math.random() * 0.1;
            p.position.y += 2 + Math.random() * 0.1;
        });
    }
}
