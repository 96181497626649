//import React, { useEffect, useState } from "react";
import { useRef } from "react";
import * as React from "react";
import { useState } from "react";
import ContentBlock from "../../../components/ContentBlock";
import { TitleScene } from "./TitleScene";
import { LoadingScene } from "./LoadingScene";
import { useOnKey } from "../../../lib/hooks/useOnKey";
import HceReact from "../../../lib/hce/v3/react/Hce";
const SimpleGame = (props) => {
    const hceapi = useRef(null);
    const assets = useRef(null);
    const musicPlaying = useRef(null);
    const renderCount = useRef(0);
    const canvasContainerRef = useRef(null);
    const [currentScene, setCurrentScene] = useState(null);
    const [keysPressed, setKeysPressed] = useOnKey();
    const [fullScreen, setFullScreen] = useState(false);
    renderCount.current++;
    async function loadMusic(id, src) {
        if (musicPlaying.current !== null) {
            musicPlaying.current.domElement.pause();
        }
        let asset = hceapi.current.assetMgr.getById(id);
        if (asset) {
            asset.domElement.play();
            musicPlaying.current = asset;
            return;
        }
        await hceapi.current.assetMgr.loadSound(id, src).then((asset) => {
            asset.domElement.play();
            musicPlaying.current = asset;
        });
    }
    if (keysPressed.length > 0) {
        if (keysPressed === "Escape")
            setFullScreen(false);
        if (keysPressed === "s")
            stopCurrentScene();
        if (keysPressed === "p")
            startCurrentScene();
        if (keysPressed === "f") {
            setFullScreen(!fullScreen);
        }
        if (keysPressed === "l")
            loadLoadingScene();
        if (keysPressed === "t")
            loadTitleScene();
        if (keysPressed === "1")
            loadMusic("lightforce", "/exp/hce/simplegamedemo/assets/lightforce.mp3");
        if (keysPressed === "2")
            loadMusic("xenon", "/exp/hce/simplegamedemo/assets/xenon.mp3");
        if (keysPressed === "3")
            loadMusic("shades", "/exp/hce/simplegamedemo/assets/shades.mp3");
        if (keysPressed === "4")
            loadMusic("speedball", "/exp/hce/simplegamedemo/assets/speedball.mp3");
        setKeysPressed("");
    }
    async function loadTitleScene() {
        stopCurrentScene();
        var scene = new TitleScene(hceapi.current.hce);
        scene.init();
        scene.start();
        setCurrentScene(scene);
    }
    async function loadLoadingScene() {
        stopCurrentScene();
        var scene = new LoadingScene(hceapi.current.hce);
        scene.init();
        scene.start();
        setCurrentScene(scene);
    }
    async function startCurrentScene() {
        if (currentScene !== null)
            currentScene.start();
    }
    async function stopCurrentScene() {
        if (currentScene !== null)
            currentScene.stop();
    }
    function onHceInit(api) {
        hceapi.current = api;
        loadLoadingScene();
    }
    async function termScene() {
        alert("term");
    }
    if (props.noContentBlock) {
        return (React.createElement("div", { ref: canvasContainerRef, style: { height: "100%" } },
            React.createElement("canvas", { className: "noselect", id: "canvas" })));
    }
    let fullScreenStyle = {
        backgroundColor: "black",
        padding: "0px",
        position: "absolute",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
    };
    let regularScreenStyle = {};
    return (React.createElement("div", { style: fullScreen ? fullScreenStyle : regularScreenStyle },
        React.createElement("div", { ref: assets }),
        fullScreen === false && (React.createElement(ContentBlock, { title: "HCE" },
            React.createElement(React.Fragment, null,
                React.createElement("p", null, "press t to load the title screen"),
                React.createElement("p", null, "press l to load the loading screen"),
                React.createElement("p", null, "press f to toggle full screen"),
                React.createElement("p", null, "press m to play title music"),
                React.createElement("p", null,
                    "Render count ",
                    renderCount.current),
                React.createElement("button", { onClick: () => loadMusic("lightforce", "/exp/hce/simplegamedemo/assets/lightforce.mp3") }, "Lightforce"),
                React.createElement("button", { onClick: () => loadMusic("shades", "/exp/hce/simplegamedemo/assets/shades.mp3") }, "Shades"),
                React.createElement(HceReact, { onApiReady: onHceInit, className: "w-full h-full" })))),
        fullScreen === true && (React.createElement(HceReact, { onApiReady: onHceInit, className: "w-screen h-screen" }))));
};
export default SimpleGame;
