import { HceObject, HceEdgeCollider, MyVector } from "../../../lib/hce/v3/engine/BaseObjects";
import DrawUtil from "../../../lib/hce/v3/x/DrawUtil";
export class Platform extends HceObject {
    fillColor;
    object;
    gameState;
    keyboardMap;
    constructor(hce) {
        super(hce);
        this.width = 300;
        this.height = 15;
        this.fillColor = "aqua";
        this.tag = "platform";
        this.createCollider();
    }
    createCollider() {
        this.collider = new HceEdgeCollider();
        this.collider.points.push(new MyVector(0, 0));
        this.collider.points.push(new MyVector(this.width, 0));
        this.collider.points.push(new MyVector(this.width, this.height));
        this.collider.points.push(new MyVector(0, this.height));
        this.collider.parent = this;
    }
    renderCollider(c) {
        return;
    }
    init(gameState) {
        this.gameState = gameState;
        this.keyboardMap = null;
    }
    onSceneReady(sceneContext) {
        this.sceneContext = sceneContext;
    }
    render(ctx2d) {
        var du = new DrawUtil(ctx2d);
        du.drawFilledBox(this.position.x, this.position.y, this.width, this.height, this.fillColor);
        //    ctx2d.fillText("GravXXXity ", this.position.x,this.position.y);
        return;
    }
}
