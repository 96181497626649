import * as React from "react";
import { useEffect } from "react";
import { rioAddWebEvent } from "../lib/rio/webevent";
export function RedirectToPage(props) {
    async function go() {
        rioAddWebEvent("redirect", "rio.web");
        window.location = props.url;
    }
    useEffect(() => {
        go();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, "Redirecting..."),
        React.createElement("p", null,
            React.createElement("a", { href: props.url }, props.url))));
}
