import { Scroller } from "./Scroller";
0;
import HceScene from "../../../lib/hce/v3/engine/HceScene";
export class WelcomeScene extends HceScene {
    constructor(hce) {
        super(hce);
    }
    // init the scene and get all of the objects ready
    onStart() {
        var scroller = new Scroller(this.hce);
        this.rootObject.addChild(scroller);
    }
    onRender(context) {
        //context.ctx.fillStyle = "white"
        var gradient = context.ctx.createLinearGradient(0, 0, context.hce.canvas.width, 0);
        gradient.addColorStop("0", "lightblue");
        gradient.addColorStop("0.5", "lightblue");
        gradient.addColorStop("1.0", "white");
        context.ctx.fillStyle = gradient;
        context.ctx.font = "32px Courier";
        context.ctx.fillText("Hello World powered by HCE V3", 20, 30);
        context.ctx.fillText("-----------------------------", 20, 50);
        this.rootObject.tick(null, 0);
        this.rootObject.render(context.ctx);
    }
}
