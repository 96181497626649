import { getSingletonHceCore } from "./hce";
import { DesignTimePropType } from "./DesignTime";
var hceGlobal = getSingletonHceCore();
//export type HceCanvas = HTMLCanvasElement;
//export type HceCanvas2dContext = CanvasRenderingContext2D;
export class MyVector {
    x;
    y;
    r; // TODO: not used by the class, but used by some dependencies..need to fix this 
    constructor(x, y) {
        this.x = x || 0;
        this.y = y || 0;
    }
    // v1 = from point
    // v2 = to point
    createFromPoints(v1, v2) {
        this.x = v2.x - v1.x;
        this.y = v2.y - v1.y;
        return this;
    }
    dot(vector) {
        return this.x * vector.x + this.y * vector.y;
    }
    cross(vector) {
        return this.x * vector.y - this.y * vector.x;
    }
    projectOnto(vector) {
        var dpdm = (this.x * vector.x + this.y * vector.y) /
            (vector.x * vector.x + vector.y * vector.y);
        this.x = dpdm * vector.x;
        this.y = dpdm * vector.y;
        return this;
    }
    clone() {
        return new MyVector(this.x, this.y);
    }
    length() {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }
    divide(vector) {
        this.x /= vector.x;
        this.y /= vector.y;
        return this;
    }
    normalize() {
        var length = this.length();
        if (length === 0) {
            this.x = 1;
            this.y = 0;
        }
        else {
            this.divide(new MyVector(length, length));
        }
        return this;
    }
    horizontalAngle() {
        return Math.atan2(this.y, this.x);
    }
    horizontalAngleDeg() {
        return hceGlobal.radian2degrees(this.horizontalAngle());
    }
    rotate(angle) {
        // radians
        var xRot = Math.cos(angle) * this.x - Math.sin(angle) * this.y;
        var yRot = Math.sin(angle) * this.x + Math.cos(angle) * this.y;
        this.x = xRot;
        this.y = yRot;
        return this;
    }
    rotateDeg(angle) {
        return this.rotate(hceGlobal.degrees2radian(angle));
    }
    translateXY(x, y) {
        this.x += x;
        this.y += y;
        return this;
    }
    translate(vec) {
        return this.translateXY(vec.x, vec.y);
    }
}
let angle = MyVector.prototype.horizontalAngle;
export class HceObject {
    hce = null; // link to the runtime
    tag = null;
    gameScene = null;
    position = new MyVector(0, 0);
    visible = true;
    rotation = 0;
    width = 0;
    height = 0;
    parent = null;
    children = [];
    collider = null;
    layer = null;
    components = [];
    tickcount = 0;
    detachedFromParentBody = false;
    eventListeners = [];
    sceneContext = null;
    constructor(hce) {
        this.hce = hce;
    }
    designTimeCreateProps() {
        let attr = [];
        attr.push({ classPropName: "tag", type: DesignTimePropType.String });
        attr.push({ classPropName: "position", type: DesignTimePropType.Custom,
            serialize: (loading, attr, targetObject, state) => {
                if (loading)
                    targetObject.position = new MyVector(state[attr.classPropName].x, state[attr.classPropName].y);
                else
                    state[attr.classPropName] = { x: targetObject.x, y: targetObject };
            } });
        attr.push({ classPropName: "visible", type: DesignTimePropType.Boolean });
        return attr;
    }
    designTimeLoadState(state) {
        console.log("designTimeLoadState()...");
        let attr = this.designTimeCreateProps();
        var object = this;
        attr.map((a) => {
            let opd = Object.getOwnPropertyDescriptor(this, a.classPropName);
            if (!opd) {
                console.log("HCE: warning prop declared on class " + this.constructor.name + " that does not exist: " + a.classPropName);
                return;
            }
            if (state.hasOwnProperty(a.classPropName)) {
                //console.log("setting prop [" + a.classPropName + "] to value: " + state[a.classPropName])
                if (a.type === DesignTimePropType.Boolean ||
                    a.type === DesignTimePropType.String ||
                    a.type === DesignTimePropType.Number) {
                    object[a.classPropName] = state[a.classPropName];
                    return;
                }
                if (a.type === DesignTimePropType.Custom) {
                    a.serialize(true, a, this, state);
                }
            }
        });
    }
    designTimeSaveState() {
        let attr = this.designTimeCreateProps();
        var state = {};
        attr.map((a) => {
            const object = this;
            if (state.hasOwnProperty(a.classPropName)) {
                //console.log("setting prop [" + a.classPropName + "] to value: " + state[a.classPropName])
                if (a.type === DesignTimePropType.Boolean ||
                    a.type === DesignTimePropType.String ||
                    a.type === DesignTimePropType.Number) {
                    state[a.classPropName] = object[a.classPropName];
                    return;
                }
                if (a.type === DesignTimePropType.Custom) {
                    a.serialize(false, a, this, state); // call custom serialize method indicat
                }
            }
            state[a.classPropName] = object[a.classPropName];
        });
        return state;
    }
    getPosition() {
        return this.position;
    }
    setPosition(x, y) {
        this.position.x = x;
        this.position.y = y;
        if (this.collider) {
            this.collider.setOriginXY(this.position.x, this.position.y);
        }
    }
    // move the object by the vector amount
    translate(vector) {
        this.position.x += vector.x;
        this.position.y += vector.y;
        this.children.forEach((obj) => {
            if (!obj.detachedFromParentBody)
                obj.translate(vector);
        });
    }
    tick(tickContext, elapsed) {
        this.tickcount++;
        this.onTick(tickContext);
        var lastComponent = null;
        var lastComponentCompleted = false;
        for (var i = 0; i < this.components.length; i++) {
            if (lastComponent !== null) {
                if (this.components[i].startOnPreviousFinished === true &&
                    lastComponentCompleted) {
                    lastComponentCompleted = this.components[i].completed;
                    this.components[i].tick(tickContext, this);
                }
                else if (this.components[i].startOnPreviousFinished === false) {
                    // save before the tick to avoid a the next start on finish component from starting in the same frame
                    lastComponentCompleted = this.components[i].completed;
                    this.components[i].tick(tickContext, this);
                }
            }
            else {
                lastComponentCompleted = this.components[i].completed;
                this.components[i].tick(tickContext, this);
            }
            lastComponent = this.components[i];
        }
        this.children.forEach((obj) => {
            obj.tick(tickContext, elapsed);
        });
    }
    onTick(tickContext) { }
    render(c) {
        if (this.visible === false)
            return;
        this.children.forEach((child) => {
            child.render(c);
        });
        this.onRender(c);
    }
    onRender(c) { }
    addChild(child) {
        this.children.push(child);
        // inherit properties
        if (child.sceneContext === null) {
            //console.log("setting scene for new child")
            child.sceneContext = this.sceneContext;
            //console.log(child.sceneContext)
        }
        else {
            //console.log("child already has a scene")
        }
        child.parent = this;
    }
    removeChild(child) {
        var index = 0;
        this.children.forEach((obj) => {
            if (obj === child) {
                this.children.splice(index, 1);
                return;
            }
            index++;
        });
    }
    sceneReady(sceneContext) {
        if (sceneContext) // if overriding
            this.sceneContext = sceneContext;
        this.onSceneReady(this.sceneContext);
        this.children.forEach((obj) => {
            obj.sceneReady(this.sceneContext);
        });
    }
    onSceneReady(sceneContext) { }
    //
    // Some methods to enable event management
    //
    addEventListener(eventName, methodToCall) {
        this.eventListeners.push({ event: eventName, method: methodToCall });
    }
    removeEventListener(eventName, methodToCall) {
        var index = 0;
        this.eventListeners.forEach((obj) => {
            if (obj.method === methodToCall) {
                this.eventListeners.splice(index, 1);
                return;
            }
            index++;
        });
    }
    raiseEvent(event, data) {
        this.eventListeners.forEach((obj) => {
            obj.method(data);
        });
    }
    getObjectByTag(tag, results) {
        var objs = this.getObjectsByTag(tag, results);
        if (objs)
            return objs[0];
        return null;
    }
    getObjectsByTag(tag, results) {
        var objects = results || [];
        if (this.tag && this.tag === tag) {
            objects.push(this);
        }
        this.children.forEach((obj) => {
            obj.getObjectsByTag(tag, objects);
        });
        if (objects.length === 0)
            return null;
        return objects;
    }
}
export const MyArea = function (x, y, width, height) {
    HceObject.call(this);
    this.position.x = x;
    this.position.y = y;
    this.width = width;
    this.height = height;
    this.color = "yellow";
    this.visible = true;
};
MyArea.prototype = Object.create(HceObject.prototype);
MyArea.prototype.tick = function (ctx) {
    this.translate(new MyVector(0.5, 0));
};
MyArea.prototype.render = function (c) {
    c.fillStyle = this.color;
    c.fillRect(this.position.x, this.position.y, this.width, this.height);
    HceObject.prototype.render.call(this, c); // render any children
};
export const HceCollider = function () {
    this.enabled = true;
    this.parent = null;
    this.type = "base";
};
HceCollider.prototype = {
    setParent(parent) {
        this.parent = parent;
    },
};
export const HceCircleCollider = function () {
    HceCollider.call(this);
    this.radius = 0;
    this.type = "circle";
};
HceCircleCollider.prototype = Object.create(HceCollider.prototype);
HceCircleCollider.prototype.hit = function (x, y, radius) {
    return hceCircleCircleIntersect(x, y, radius, this.parent.position.x, this.parent.position.y, this.parent.radius);
};
export class HceEdgeCollider {
    points;
    type;
    rotation;
    origin;
    parent;
    constructor() {
        this.points = [];
        this.type = "edge";
        this.rotation = 0; // rotation in radians
        this.origin = new MyVector(0, 0);
    }
    setRotationDeg(rotation) {
        this.rotation = (rotation * Math.PI) / 180; // covert to radians
    }
    setOriginXY(x, y) {
        this.origin.x = x;
        this.origin.y = y;
    }
    // translate the collider points to global/origin points with rotation
    getOriginPoints() {
        var points = [];
        for (var i = 0; i < this.points.length; i++) {
            var vec = new MyVector(this.points[i].x, this.points[i].y);
            vec.rotate(this.rotation);
            vec.translateXY(this.origin.x, this.origin.y);
            points.push({ x: vec.x, y: vec.y });
        }
        return points;
    }
    // render to help debug
    debugRender(c) {
        var points = this.getOriginPoints();
        if (points.length < 2)
            return;
        c.strokeStyle = "orange";
        c.beginPath();
        c.moveTo(points[0].x, points[0].y);
        for (var i = 1; i < points.length; i++) {
            c.lineTo(points[i].x, points[i].y);
        }
        c.stroke();
    }
    getTranslatedPoints(origin, rotation) {
        var points = [];
        for (var i = 0; i < this.points.length; i++) {
            var vec = new MyVector(this.points[i].x, this.points[i].y);
            vec.rotate(rotation);
            vec.translateXY(origin.x, origin.y);
            points.push({ x: vec.x, y: vec.y });
        }
        return points;
    }
    // is the specific point within the collider area
    // TODO: Using a box for a polygon aint gonna work except for text objects  squares
    hitPoint(x, y) {
        var hitPoints = this.getOriginPoints();
        var box = RT_getBoundingBoxForPoints(hitPoints); // get the box for the points
        if (x < box.minX)
            return false;
        if (x > box.maxX)
            return false;
        if (y < box.minY)
            return false;
        if (y > box.maxY)
            return false;
        return true;
    }
    hceSegmentIntersect(p0, p1, p2, p3) {
        var A1 = p1.y - p0.y, B1 = p0.x - p1.x, C1 = A1 * p0.x + B1 * p0.y, A2 = p3.y - p2.y, B2 = p2.x - p3.x, C2 = A2 * p2.x + B2 * p2.y, denominator = A1 * B2 - A2 * B1;
        if (denominator == 0) {
            return null;
        }
        var intersectX = (B2 * C1 - B1 * C2) / denominator, intersectY = (A1 * C2 - A2 * C1) / denominator, rx0 = (intersectX - p0.x) / (p1.x - p0.x), ry0 = (intersectY - p0.y) / (p1.y - p0.y), rx1 = (intersectX - p2.x) / (p3.x - p2.x), ry1 = (intersectY - p2.y) / (p3.y - p2.y);
        if (((rx0 >= 0 && rx0 <= 1) || (ry0 >= 0 && ry0 <= 1)) &&
            ((rx1 >= 0 && rx1 <= 1) || (ry1 >= 0 && ry1 <= 1))) {
            return {
                x: intersectX,
                y: intersectY,
            };
        }
        else {
            return null;
        }
    }
    hit(points) {
        var hitPoints = this.getOriginPoints();
        for (var i = 0; i < hitPoints.length - 1; i++) {
            // covert points to an edge
            var edgeStart = { x: hitPoints[i].x, y: hitPoints[i].y };
            var edgeEnd = { x: hitPoints[i + 1].x, y: hitPoints[i + 1].y };
            // check again shape
            for (var p = 0; p < points.length - 1; p = p + 1) {
                var hit = this.hceSegmentIntersect(points[p], points[p + 1], edgeStart, edgeEnd);
                if (hit != null) {
                    return hit;
                }
            }
        }
        return null;
    }
    hitOld(points) {
        for (var i = 0; i < this.points.length - 1; i++) {
            var edgeStart = {
                x: this.parent.position.x + this.points[i].x,
                y: this.parent.position.y + this.points[i].x,
            };
            var edgeEnd = {
                x: this.parent.position.x + this.points[i + 1].x,
                y: this.parent.position.y + this.points[i + 1].y,
            };
            for (var p = 0; p < points.length - 1; p = p + 1) {
                var hit = this.hceSegmentIntersect(points[p], points[p + 1], edgeStart, edgeEnd);
                if (hit != null) {
                    return hit;
                }
            }
        }
        return null;
    }
    // return points for the collide relative to the world x,y
    getWorldPoints() {
        var points = [];
        for (var i = 0; i < this.points.length; i++) {
            var edge = {
                x: this.parent.position.x + this.points[i].x,
                y: this.parent.position.y + this.points[i].y,
            };
            points.push(edge);
        }
        return points;
    }
}
export const HcePhysicsEngine = function () { };
HcePhysicsEngine.prototype = {
    checkCollisions(rootObject) {
        var colliders = [];
        this.getColliders(rootObject, colliders);
    },
    getColliders(object, colliders) {
        if (object.collider !== null) {
            colliders.push(object.collider);
        }
        object.children.forEach((child) => {
            this.getColliders(child, colliders);
        });
    },
    pointCircleCollision(pointVector, circleVector, r) {
        if (r === 0)
            return false;
        var dx = circleVector.x - pointVector.x;
        var dy = circleVector.y - pointVector.y;
        return dx * dx + dy * dy <= r * r;
    },
    lineCircleCollide(a, b, circle, radius, nearest) {
        var tmp = [0, 0];
        //check to see if start or end points lie within circle
        if (this.pointCircleCollision(a, circle, radius)) {
            if (nearest) {
                nearest[0] = a[0];
                nearest[1] = a[1];
            }
            return true;
        }
        if (this.pointCircleCollision(b, circle, radius)) {
            if (nearest) {
                nearest[0] = b[0];
                nearest[1] = b[1];
            }
            return true;
        }
        var x1 = a.x, y1 = a.y, x2 = b.x, y2 = b.y, cx = circle.x, cy = circle.y;
        //vector d
        var dx = x2 - x1;
        var dy = y2 - y1;
        //vector lc
        var lcx = cx - x1;
        var lcy = cy - y1;
        //project lc onto d, resulting in vector p
        var dLen2 = dx * dx + dy * dy; //len2 of d
        var px = dx;
        var py = dy;
        if (dLen2 > 0) {
            var dp = (lcx * dx + lcy * dy) / dLen2;
            px *= dp;
            py *= dp;
        }
        if (!nearest)
            nearest = tmp;
        nearest[0] = x1 + px;
        nearest[1] = y1 + py;
        //len2 of p
        var pLen2 = px * px + py * py;
        //check collision
        return (this.pointCircleCollision(new MyVector(x1 + px, y1 + py), circle, radius) &&
            pLen2 <= dLen2 &&
            px * dx + py * dy >= 0);
    },
};
export const CustomComponent = function () {
    this.type = "CustomComponent";
    this.customName = "";
    this.tickCount = 0;
    this.designer = ""; // no design time component
    this.movePoints = false;
    this.callback = null;
    this.parent = null;
    this.delete = false; // not marked for clean up
    this.data = {};
    // common priorities for all components
    this.completed = false;
    this.startOnPreviousFinished = false; // wait for the previous move component to finish
};
CustomComponent.prototype = {};
CustomComponent.prototype.onSceneReady = function () { };
CustomComponent.prototype.initObject = function (obj) {
    obj.customName = this.customName;
};
CustomComponent.prototype.setObject = function (obj) { };
CustomComponent.prototype.tick = function (tickContext, obj) {
    this.tickCount++;
    if (this.callback) {
        this.callback(this);
    }
};
export class MoveComponent {
    hce;
    type;
    velocity;
    wrapX;
    wrapY;
    startPosition;
    endPosition;
    tickCount;
    designer;
    movePoints;
    delete;
    maxFrames;
    completed;
    startOnPreviousFinished;
    constructor(hce) {
        this.hce = hce;
        this.type = "MoveComponent";
        this.velocity = new MyVector(0, 0);
        this.velocity.x = Math.random() * 5;
        this.velocity.y = Math.random() * 5;
        this.velocity.r = 0;
        this.wrapX = true;
        this.wrapY = true;
        this.startPosition = null;
        this.endPosition = null;
        this.tickCount = 0;
        this.designer = "gd-movecomponent";
        this.movePoints = false;
        this.delete = false; // not marked for clean up
        this.maxFrames = -1; // run forever
        // common priorities for all components
        this.completed = false;
        this.startOnPreviousFinished = false; // wait for the previous move component to finish
    }
    ;
    onSceneReady() {
        this.tickCount = 0;
    }
    ;
    initObject(obj) {
        obj.velocity = {};
        obj.velocity.x = this.velocity.x;
        obj.velocity.y = this.velocity.y;
        obj.velocity.r = this.velocity.r;
        obj.maxFrames = this.maxFrames;
        obj.startOnPreviousFinished = this.startOnPreviousFinished;
        obj.wrapX = this.wrapX;
        obj.wrapY = this.wrapY;
    }
    ;
    setObject(obj) {
        this.velocity.x = obj.velocity.x;
        this.velocity.y = obj.velocity.y;
        if (obj.velocity.r)
            this.velocity.r = obj.velocity.r;
        if (obj.maxFrames)
            this.maxFrames = obj.maxFrames;
        if (obj.startOnPreviousFinished)
            this.startOnPreviousFinished = obj.startOnPreviousFinished;
        if (Object.prototype.hasOwnProperty.call(obj, "wrapX"))
            this.wrapX = obj.wrapX;
        if (Object.prototype.hasOwnProperty.call(obj, "wrapY"))
            this.wrapY = obj.wrapY;
    }
    ;
    tick(tickContext, obj) {
        var hceCore = this.hce.hceCore;
        if (this.completed)
            // do nothing if we have completed
            return;
        if (this.maxFrames !== -1) {
            if (this.tickCount >= this.maxFrames) {
                this.completed = true;
                return;
            }
        }
        this.tickCount++;
        if (this.tickCount === 1) {
            if (this.startPosition != null) {
                this.endPosition = obj.position;
                obj.position = this.startPosition;
            }
        }
        else {
            if (this.startPosition != null) {
                var towards = new MyVector(0, 0).createFromPoints(obj.position, this.endPosition);
                var distance = towards.length();
                if (distance < 10)
                    return;
                towards.normalize();
                obj.position.x += towards.x * this.velocity.x * hceCore.time.scale;
                obj.position.y += towards.y * this.velocity.y * hceCore.time.scale;
                return;
            }
        }
        if (this.velocity.r !== 0)
            obj.obj.rotation += this.velocity.r * hceCore.time.scale;
        // x,y position based so move
        if (!this.movePoints) {
            obj.position.x += this.velocity.x * hceCore.time.scale;
            obj.position.y += this.velocity.y * hceCore.time.scale;
            //        console.log("tick: " + this.tickCount  + " move x:" + this.velocity.x)
            //        console.log("      move y:" + this.velocity.x)
            if (this.wrapX) {
                if (obj.position.x > tickContext.gameState.scene.width) {
                    obj.position.x = 0;
                }
                if (obj.position.x < 0) {
                    obj.position.x = tickContext.gameState.scene.width;
                }
            }
            if (this.wrapY) {
                if (obj.position.y > tickContext.gameState.scene.height) {
                    obj.position.y = 0;
                }
                if (obj.position.y < 0) {
                    obj.position.y = tickContext.gameState.scene.height;
                }
            }
            return;
        }
        obj.obj.points.forEach((point) => {
            point.x += this.velocity.x * hceCore.time.scale;
            point.y += this.velocity.y * hceCore.time.scale;
        });
        var box = RT_getBoundingBoxForPoints(obj.obj.points);
        // box {minX,minY,maxX,maxY,dx,dy,cx,cy };
        if (this.wrapX) {
            if (box.minX > tickContext.gameState.scene.width) {
                obj.obj.points.forEach((point) => {
                    point.x -= tickContext.gameState.scene.width + box.dx;
                });
            }
            if (box.maxX < 0) {
                obj.obj.points.forEach((point) => {
                    point.x += tickContext.gameState.scene.width + box.dx;
                });
            }
        }
        if (this.wrapY) {
            if (box.minY > tickContext.gameState.scene.height) {
                obj.obj.points.forEach((point) => {
                    point.y -= tickContext.gameState.scene.height + box.dy;
                });
            }
            if (box.maxY < 0) {
                obj.obj.points.forEach((point) => {
                    point.y += tickContext.gameState.scene.height + box.dy;
                });
            }
        }
    }
}
