import { HceObject, HceEdgeCollider, MyVector } from "../../../lib/hce/v3/engine/BaseObjects";
import DrawUtil from "../../../lib/hce/v3/x/DrawUtil";
export class Player extends HceObject {
    spaceKeyDown;
    gameState;
    playerColor;
    shipVectorX;
    shipVectorY;
    gravityOn;
    gravityEffect;
    gravityEffectMax;
    gravityEffectInc;
    gravityX;
    gravityY;
    gravityYIncPerTick;
    gravityYMax;
    object;
    wrap;
    state;
    counter;
    dontdietimer;
    noColliderTime;
    jumping;
    forceY;
    forceYDecay;
    gravity;
    xVel;
    collided;
    keyboardMap;
    collidedPlatform;
    centerX;
    centerY;
    shipRotation;
    recoverInfo;
    shipColor;
    playerLastGoodX = 0;
    playerLastGoodY = 0;
    constructor(hce) {
        super(hce);
        this.spaceKeyDown = false;
        this.gameState = null;
        this.playerColor = "red";
        this.shipVectorX = 0;
        this.shipVectorY = -1;
        this.gravityOn = false;
        this.gravityEffect = 0;
        this.gravityEffectMax = 5;
        this.gravityEffectInc = 0.1;
        this.width = 30;
        this.height = 30;
        this.gravityX = 0;
        this.gravityY = 0;
        this.gravityYIncPerTick = 1;
        this.gravityYMax = 15;
        this.createCollider();
        this.object = null;
        this.wrap = true;
        this.components = [];
        this.state = "play";
        this.counter = 0;
        this.dontdietimer = 0;
        this.noColliderTime = 0;
        this.jumping = 0;
        this.forceY = 0;
        this.forceYDecay = 0.5;
        this.gravity = 0;
        this.xVel = 0;
        this.collided = false;
    }
    // purpose: init the player object from the design time object
    changeState(newState) {
        console.log("player change state = current: " + this.state + " new:" + newState);
        if (this.state === newState)
            return;
        this.state = newState;
        if (this.state === "die") {
            this.counter = 100;
        }
        this.raiseEvent("stateChange", null);
    }
    createCollider() {
        this.collider = new HceEdgeCollider();
        this.collider.points.push(new MyVector(0, 0));
        this.collider.points.push(new MyVector(this.width, 0));
        this.collider.points.push(new MyVector(this.width, this.height));
        this.collider.points.push(new MyVector(0, this.height));
        this.collider.parent = this;
    }
    renderCollider(c) {
        //this.collider.debugRender(c);
        return;
    }
    isRealPlayer() {
        if (this.tag === "player")
            return true;
        if (!this.object || this.object.tag !== "player") {
            return false;
        }
        return true;
    }
    init(gameState) {
        this.gameState = gameState;
        this.keyboardMap = null;
    }
    onTick(tickContext) {
        // console.log("tick:" + this.state);
        // alive and playing
        if (this.state === "play") {
            if (this.dontdietimer > 0) {
                this.dontdietimer -= this.hce.hceCore.time.frameDelta * this.hce.hceCore.time.scale;
            }
            this.keyboardMap = tickContext.keyboardMap;
            this.spaceKeyDown = false;
            if (this.keyboardMap) {
                this.checkKeyState(this.keyboardMap);
            }
            // console.log(this.gravityY);
            this.jumping--;
            if (this.jumping < 0)
                this.jumping = 0;
            // increase gravity
            this.gravityY += this.gravityYIncPerTick;
            if (this.gravityY > this.gravityYMax)
                this.gravityY = this.gravityYMax;
            this.collided = null;
            if (this.gravityY >= 0) {
                this.checkForCollisions();
            }
            if (this.collided) {
                this.position.y = this.collidedPlatform.position.y - this.height - 2;
                this.gravityY = 0;
            }
            else {
                this.position.y += this.gravityY * this.hce.hceCore.time.scale;
                if (this.position.y > 1000) {
                    this.position.y = 1000;
                    this.gravityY = 0;
                }
            }
            this.position.x += this.xVel;
            if (this.xVel > 0)
                this.xVel -= 0.1;
            else if (this.xVel < 0)
                this.xVel += 0.1;
            // TODO: revisit this origin x/y logic here and in the render
            var centerX = this.position.x + this.width / 2;
            var centerY = this.position.y + this.height / 2;
            this.centerX = centerX;
            this.centerY = centerY;
            this.collider.setOriginXY(centerX, centerY); // update collider origin
        }
        if (this.state === "die") {
            this.shipRotation += 9 * this.hce.hceCore.time.scale;
            //this.shipColor = "green";
            this.width *= 0.98 * this.hce.hceCore.time.scale;
            this.height *= 0.98 * this.hce.hceCore.time.scale;
            this.counter--;
            if (this.counter <= 0) {
                this.changeState("dead");
            }
        }
        if (this.state === "recover") {
            this.width = this.recoverInfo.width;
            this.height = this.recoverInfo.height;
            this.shipColor = this.recoverInfo.shipColor;
            let towards = new MyVector(0, 0).createFromPoints(this.position, new MyVector(this.playerLastGoodX, this.playerLastGoodY));
            towards.normalize();
            //        this.position.x = this.playerLastGoodX;
            //        this.position.y = this.playerLastGoodY;
            this.position.x += towards.x * 10;
            this.position.y += towards.y * 10;
            this.dontdietimer = 1500;
            this.changeState("play");
        }
        //HceObject.prototype.tick.call(this, tickContext); // tick any children
    }
    onSceneReady(sceneContext) {
        this.sceneContext = sceneContext;
    }
    checkKeyState(keyboardMap) {
        if (this.hce.hceCore.time.scale === 0)
            return;
        if (keyboardMap.get("a") || keyboardMap.get("A")) {
            this.moveLeft();
        }
        if (keyboardMap.get("ArrowLeft"))
            this.moveLeft();
        if (keyboardMap.get("d") || keyboardMap.get("D"))
            this.moveRight();
        if (keyboardMap.get("Enter")) {
            this.jump();
        }
        if (keyboardMap.get("ArrowRight"))
            this.moveRight();
        if (keyboardMap.get("ArrowUp") &&
            keyboardMap.get("ArrowUp").shiftKey === false)
            this.jump();
        if (keyboardMap.get("w") || keyboardMap.get("W"))
            this.jump();
        if (keyboardMap.get(" ") && keyboardMap.get(" ").shiftKey === false)
            this.jump();
    }
    onRender(c) {
        if (!this.visible)
            return;
        // HceObject.prototype.render.call(this,c);
        this.renderPlayer(c);
    }
    renderPlayer(c) {
        c.beginPath();
        c.fillStyle = this.playerColor;
        if (this.collided === true) {
            c.fillStyle = "green";
        }
        c.moveTo(this.position.x, this.position.y);
        c.lineTo(this.position.x + this.width, this.position.y);
        c.lineTo(this.position.x + this.width, this.position.y + this.height);
        c.lineTo(this.position.x, this.position.y + this.height);
        c.closePath();
        c.fill();
        c.stroke();
        c.restore();
        this.renderPlatformColliders(c);
        return;
    }
    renderPlatformColliders(c) {
        var platforms = this.sceneContext.platforms;
        var du = new DrawUtil(c);
        for (var o = 0; o < platforms.length; o++) {
            var platform = platforms[o];
            var points = platform.collider.getWorldPoints();
            du.drawPoints(points, "green", c);
        }
    }
    moveLeft() {
        this.xVel -= 0.3;
        if (this.xVel < -3.8) {
            this.xVel = -3.8;
        }
    }
    moveRight() {
        this.xVel += 0.3;
        if (this.xVel > 3.8)
            this.xVel = 3.8;
    }
    jump() {
        if (this.jumping)
            return;
        this.gravityY -= 25;
        this.jumping = 35;
        console.log("jump");
    }
    checkForCollisions() {
        this.collided = false;
        var platforms = this.sceneContext.platforms;
        for (var o = 0; o < platforms.length; o++) {
            var platform = platforms[o];
            var points = platform.collider.getWorldPoints();
            if (this.checkLineImpactMain(points)) {
                this.collided = true;
                this.gravityY = 0;
                this.collidedPlatform = platform;
                return;
            }
        }
    }
    // check if the player hit the object specified
    checkLineImpactMain(points) {
        var debug = false;
        var i = this.collider.hit(points);
        if (i != null) {
            // show the hit point
            if (debug) {
                var c = this.gameState.canvas2dContext;
                c.beginPath();
                c.fillStyle = "yellow";
                c.arc(i.x, i.y, 10, 0, 2 * Math.PI);
                c.stroke();
                c.fill();
            }
            return true;
        }
        return false;
    }
}
