//import React, { useEffect, useState } from "react";
import { useEffect, useRef } from "react";
import * as React from "react";
import ContentBlock from "../../../components/ContentBlock";
import Hce3 from "../../../lib/hce/v3/engine/hce";
import { WelcomeScene } from "./WelcomeScene";
const Welcome = (props) => {
    const canvasContainerRef = useRef(null);
    useEffect(() => {
        var options = {
            pageBackgroundColor: "black",
            canvas: canvasContainerRef.current,
        };
        var hce = new Hce3(canvasContainerRef.current);
        hce
            .init(options)
            .then(() => {
            var scene = new WelcomeScene(hce);
            scene.init();
            scene.onStopped = () => {
                scene.term();
            };
            scene.start();
        })
            .catch((e) => {
            alert("hce init failed: " + e);
        });
    });
    if (props.noContentBlock) {
        return (React.createElement("div", { style: { height: "100%" } },
            React.createElement("canvas", { ref: canvasContainerRef, className: "noselect", id: "canvas" })));
    }
    return (React.createElement("div", null,
        React.createElement(ContentBlock, { title: "HCE" },
            React.createElement("div", null,
                React.createElement("canvas", { ref: canvasContainerRef, className: "noselect", id: "canvas", style: { width: "100%", height: "100%", padding: "0", margin: "0" } })))));
};
export default Welcome;
