import * as React from "react";
import { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import ContentBlock from "../../components/ContentBlock";
import Demos from "./Demos";
import Welcome from "./welcomedemo/Welcome";
import SimpleGame from "./simplegamedemo/SimpleGame";
import HcmMiner from "./hcmminer/HcmMiner";
const FullScreenWelcomeDemo = (props) => {
    const [close, setClose] = useState(false);
    function onOpen() {
        setClose(false);
    }
    function onClose() {
        setClose(true);
    }
    if (close) {
        return React.createElement("button", { onClick: onOpen }, "Open");
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: {
                backgroundColor: "black",
                padding: "0px",
                position: "absolute",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
            } },
            React.createElement("div", { style: { height: "100vh" } },
                React.createElement("div", { style: { height: "95%" } },
                    React.createElement(Welcome, { noContentBlock: true })),
                React.createElement("button", { onClick: onClose }, "Close")))));
};
const HceDemos = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentBlock, { title: "HCE demos" },
            React.createElement(React.Fragment, null,
                React.createElement("p", null, "HCE is my experiment canvas based rendering engine for simple use cases like basic games."),
                React.createElement(Link, { to: "welcome" }, "Welcome demo"),
                " ",
                React.createElement("br", null),
                React.createElement(Link, { to: "welcomefullscreen" }, "Welcome demo (full screen)"),
                " ",
                React.createElement("br", null),
                React.createElement(Link, { to: "simplegame" }, "Simple Game Demo"),
                " ",
                React.createElement("br", null),
                React.createElement(Link, { to: "hcmminer" }, "HCM Miner"),
                " ",
                React.createElement("br", null),
                React.createElement(Link, { to: "demos" }, "Other demos"),
                " ",
                React.createElement("br", null))),
        React.createElement(Outlet, null)));
};
export function getRoutes() {
    let childRoutes = [
        { path: "welcome", element: React.createElement(Welcome, null) },
        { path: "welcomefullscreen", element: React.createElement(FullScreenWelcomeDemo, null) },
        { path: "simplegame", element: React.createElement(SimpleGame, null) },
        { path: "demos", element: React.createElement(Demos, null) },
        { path: "hcmminer", element: React.createElement(HcmMiner, null) },
    ];
    let routes = [{ path: "hce", element: React.createElement(HceDemos, null), children: childRoutes }];
    return routes;
}
