export function getSingletonHceCore() {
    return hceCoreGlobal;
}
export class HceCore3 {
    canvas;
    degrees;
    globalState;
    globalManager;
    levelState;
    levelManager;
    time;
    scaleX;
    scaleY;
    hce;
    ignoreMouseInput;
    adjustMouseX;
    adjustMouseY;
    blitPositionX;
    blitPositionY;
    zoom;
    mouseXraw;
    mouseYraw;
    mouseX;
    mouseY;
    gridsnap;
    parent;
    userPause;
    engineOptions;
    callbackIdRequestAnimFrame;
    constructor(x, y) {
        // TODO: ugly global pattern.  Has to be done as this is referenced in HceObject and other classes today
        hceCoreGlobal = this;
        this.canvas = null;
        this.degrees = 180 / Math.PI;
        this.globalState = {}; // global/game level state
        this.globalManager = null; // global object helping with game/app
        this.levelState = {}; // scene/level state
        this.levelManager = null; // global object help with scene
        this.onClickHandler = this.onClickHandler.bind(this);
        this.time = {
            scale: 1,
            frameDelta: 0, // ms between frames
        };
        // set when the canvas is zoomed
        this.scaleX = 1;
        this.scaleY = 1;
    }
    init(hce, canvas, parent) {
        this.hce = hce;
        this.ignoreMouseInput = false;
        this.adjustMouseX = 0;
        this.adjustMouseY = 0;
        this.blitPositionX = 0;
        this.blitPositionY = 0;
        this.zoom = 1;
        this.mouseXraw = -1;
        this.mouseYraw = -1;
        this.mouseX = -1;
        this.mouseY = -1;
        this.gridsnap = 1;
        if (!parent) {
            parent = document.body;
        }
        this.canvas = canvas;
        this.parent = parent;
    }
    radian2degrees(rad) {
        return rad * this.degrees;
    }
    degrees2radian(deg) {
        return deg / this.degrees;
    }
    onClickHandler(e) {
        e.preventDefault();
    }
    initEventHandlers() {
        this.parent.addEventListener("keydown", (evt) => {
            if (document.activeElement.tagName !== "BODY")
                return;
            if (evt.key === "p") {
                this.userPause = !this.userPause;
            }
            if (this.hce.engineOptions.onKeyDown) {
                this.hce.engineOptions.onKeyDown(evt);
            }
        });
        this.parent.addEventListener("keyup", (evt) => {
            if (this.hce.engineOptions.onKeyUp) {
                this.hce.engineOptions.onKeyUp(evt);
            }
        });
        this.hce.setCanvasExtents(); // resize the canvas to the window area or user set dimensions
        window.onresize = () => {
            if (this.hce.autoResizeCanvas) {
                this.hce.setCanvasExtents();
            }
        };
        this.canvas.addEventListener("mousemove", (event) => {
            this.updateDisplay(event);
            if (this.engineOptions && this.engineOptions.onMouseMove) {
                this.engineOptions.onMouseMove(this.mouseX, this.mouseY);
            }
        }, false);
        this.canvas.addEventListener("mouseenter", this.updateDisplay, false);
        this.canvas.addEventListener("mouseleave", this.updateDisplay, false);
        this.canvas.addEventListener("mousedown", () => {
            if (this.hce.engineOptions.onMouseDown) {
                this.hce.engineOptions.onMouseDown(this.mouseX, this.mouseY);
            }
        });
        this.canvas.removeEventListener("click", this.onClickHandler); // remove previous if not the first time called
        this.canvas.addEventListener("click", this.onClickHandler);
        this.canvas.addEventListener("dblclick", () => {
            if (this.hce.engineOptions.onDoubleClick) {
                this.hce.engineOptions.onDoubleClick(this.mouseX, this.mouseY);
            }
        });
        this.canvas.addEventListener("mouseup", () => {
            if (this.hce.engineOptions.onMouseUp) {
                this.hce.engineOptions.onMouseUp(this.mouseX, this.mouseY);
            }
        });
        this.canvas.addEventListener("touchmove", (event) => {
            this.updateDisplay({
                pageX: event.touches[0].clientX,
                pageY: event.touches[0].clientY,
            });
        });
    }
    scaleToZoom(p) {
        //    console.log("zoom = " + zoom);
        //    console.log("before = " + p);
        p = Math.round(p * (1 / this.zoom));
        //    console.log("after = " + p);
        return p;
    }
    updateDisplay(event) {
        if (this.ignoreMouseInput)
            return;
        var x = event.pageX;
        var y = event.pageY;
        x = x + this.adjustMouseX; // allow canvas element not to be at position 0,0
        y = y + this.adjustMouseY;
        x = x + this.blitPositionX;
        y = y + this.blitPositionY;
        if (this.zoom) {
            x = this.scaleToZoom(x);
            y = this.scaleToZoom(y);
        }
        this.mouseXraw = x;
        this.mouseYraw = y;
        this.mouseX = Math.round(x / this.gridsnap) * this.gridsnap;
        this.mouseY = Math.round(y / this.gridsnap) * this.gridsnap;
        return true;
    }
}
var hceCoreGlobal = new HceCore3();
var HceCurrentState;
(function (HceCurrentState) {
    HceCurrentState[HceCurrentState["NotInitialized"] = 0] = "NotInitialized";
    HceCurrentState[HceCurrentState["InError"] = 1] = "InError";
    HceCurrentState[HceCurrentState["Ready"] = 2] = "Ready";
})(HceCurrentState || (HceCurrentState = {}));
export default class Hce3 {
    initState;
    animationsQueue;
    canvas;
    ctx;
    screenCanvas;
    screen2dContext;
    globalTime;
    activePageButtons;
    pageBackgroundColor;
    disableBackgroundFill;
    parentElement;
    canvasWidth;
    canvasHeight;
    userPause;
    engineOptions;
    hceCore; // was hceGlobal in v2
    autoResizeCanvas = true;
    // parentElement can be document.body
    constructor(parentElement) {
        this.initState = HceCurrentState.NotInitialized;
        this.animationsQueue = [];
        this.canvas = null; // canvas to use
        this.ctx = null; // 2d canvas context
        this.screenCanvas = null; // visible canvas
        this.screen2dContext = null;
        this.globalTime = 0;
        this.activePageButtons = [];
        this.pageBackgroundColor = "blue";
        this.disableBackgroundFill = false; // don't auto fill the canvas
        this.parentElement = parentElement;
        // if these are set then setExtent will use them vs the mainElement size
        this.canvasWidth = -1;
        this.canvasHeight = -1;
        this.userPause = false;
    }
    setCanvas(c) {
        this.screenCanvas = c;
        this.screen2dContext = this.screenCanvas.getContext("2d");
    }
    init(options) {
        return new Promise((resolve, reject) => {
            if (options.canvas === null || options.canvas === undefined) {
                reject("screenCanvas not found.  Did you forget to use the id canvas?");
                this.initState = HceCurrentState.InError; // error
                return;
            }
            this.setCanvas(options.canvas);
            // default to the visible canvas
            this.ctx = this.screen2dContext;
            this.canvas = this.screenCanvas;
            var defaultOptions = {
                pageBackgroundColor: "red",
                parentWindow: window,
            };
            this.engineOptions = options || defaultOptions;
            if (this.engineOptions.pageBackgroundColor) {
                this.pageBackgroundColor = this.engineOptions.pageBackgroundColor;
            }
            if (this.engineOptions.parentWindow) {
                this.engineOptions.parentWindow = window;
            }
            this.hceCore = new HceCore3();
            this.hceCore.init(this, this.canvas, this.engineOptions.parentWindow);
            this.hceCore.initEventHandlers();
            this.initState = HceCurrentState.Ready; // clean init
            resolve(null);
            this.renderFrame = this.renderFrame.bind(this);
            this.hceCore.callbackIdRequestAnimFrame = requestAnimationFrame(this.renderFrame);
        });
    }
    setCanvasWH(w, h) {
        if (this.canvasWidth === -1) {
            this.canvas.width = w;
        }
        else {
            this.canvas.width = this.canvasWidth;
        }
        if (this.canvasHeight === -1) {
            this.canvas.height = h;
        }
        else {
            this.canvas.height = this.canvasHeight;
        }
    }
    setCanvasExtents() {
        var w = this.parentElement.clientWidth;
        var h = this.parentElement.clientHeight;
        this.setCanvasWH(this.parentElement.clientWidth, this.parentElement.clientHeight);
    }
    renderFrame() {
        this.activePageButtons = [];
        if (this.userPause) {
            requestAnimationFrame(this.renderFrame);
            return;
        }
        if (this.engineOptions.prerender) {
            this.engineOptions.prerender(c);
        }
        if (!this.disableBackgroundFill) {
            this.ctx.fillStyle = this.pageBackgroundColor;
            //this.ctx.gAlpha = 1;
            this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        }
        this.globalTime = this.globalTime + 1;
        this.playAnimations(this.ctx);
        this.checkMouseOver(); // in debug mode show hit boxes
        if (this.engineOptions.postrender) {
            this.engineOptions.postrender(this.ctx);
        }
        requestAnimationFrame(this.renderFrame);
    }
    checkMouseOver() {
        for (var i = 0; i < this.activePageButtons.length; i++) {
            if (this.activePageButtons[i].onmouseover) {
                this.activePageButtons[i].onmouseover(mouseX, mouseY);
            }
        }
    }
    queueAnimation(func, predicate) {
        let newAnimation = {
            runtime: 0,
            startTime: this.globalTime,
            handler: func,
            predicate: predicate ? predicate : null,
            ended: false,
            callWhenGamePaused: false,
            state: {},
        };
        this.animationsQueue.push(newAnimation);
        return newAnimation;
    }
    // stops an animation from being called
    // parameters: the handle returned from queueAnimation
    stopAnimation(animation) {
        this.animationsQueue = this.animationsQueue.filter((anim) => anim !== animation);
    }
    // render all active animations
    playAnimations(canvasToUse) {
        // play each animation frame
        for (var i = 0; i < this.animationsQueue.length; i++) {
            var anim = this.animationsQueue[i];
            var context = { hce: this, ctx: this.ctx, anim };
            if (anim.predicate) {
                if (anim.predicate()) {
                    anim.handler(context);
                }
            }
            else {
                anim.handler(context);
            }
            anim.runtime = anim.runtime + 1;
        }
        // remove any finished animations
        this.animationsQueue = this.animationsQueue.filter((anim) => !anim.ended);
    }
}
