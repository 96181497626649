import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ContentBlock from "../components/ContentBlock";
import { useAdminContext } from "./AdminContext";
import { useNavigate } from "react-router-dom";
import { rioGetUsers } from "../lib/rio/user";
import Button from "../lib/comp/Button";
const AdminUserList = (props) => {
    let navigate = useNavigate();
    var debug = false;
    const context = useAdminContext();
    if (context === null) {
        return;
    }
    var usersDefault;
    const [users, setUsers] = useState(usersDefault);
    const jwt = useSelector((state) => state.user.jwt);
    let userId = 0;
    const onFetchUser = (e) => {
        navigate("../user/" + e.target.id, { replace: false });
    };
    function addUser() {
        navigate("../user/add");
    }
    useEffect(() => {
        var load = async () => {
            var data = await rioGetUsers(jwt);
            setUsers(data);
        };
        load();
    }, []);
    let usersLi;
    // still loading
    if (users === undefined) {
        usersLi = React.createElement("h1", null, "Loading...");
    }
    else {
        usersLi = users.map((user) => {
            userId++;
            return (React.createElement("li", { id: user.id, key: userId, onClick: onFetchUser },
                " ",
                "User Email: ",
                user.google.email,
                " - Role: ",
                user.role,
                " - Id: ",
                user.id));
        });
    }
    return (React.createElement("div", null,
        React.createElement(ContentBlock, { title: "User List" },
            React.createElement("div", null,
                usersLi,
                React.createElement(Button, { onClick: addUser }, "Add User"))),
        debug && (React.createElement(ContentBlock, { title: "JSON" },
            React.createElement("div", null,
                "API response!:",
                React.createElement("pre", { style: { overflow: "wrapText" } }, JSON.stringify(users)))))));
};
export default AdminUserList;
