import * as React from "react";
import { Outlet, Link } from "react-router-dom";
const Container = (props) => {
    let defaultClasses = "ml-2 mr-2 my-4 py-4 px-4 bg-white rounded-xl shadow-lg space-y-2";
    if (props.defaultClassName)
        defaultClasses = props.defaultClassName;
    if (props.maxWidth)
        defaultClasses = defaultClasses + " " + props.maxWidth;
    if (props.width)
        defaultClasses = defaultClasses + " " + props.width;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: defaultClasses },
            React.createElement("div", null,
                React.createElement("div", null, props.children)))));
};
const TailwindCss = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "grid grid-cols-1 place-items-center bg-slate-200 w-full mt-2 mb-2 ml-2 mr-2" },
            React.createElement(Container, { width: "w-full", maxWidth: "max-w-screen-md" },
                React.createElement("p", null, "hello"),
                React.createElement("p", null, "world")),
            React.createElement(Container, { width: "w-full", maxWidth: "max-w-screen-lg" },
                React.createElement("p", null, "hello 2"),
                React.createElement("p", null, "world 2"))),
        React.createElement(Container, { maxWidth: "max-w-full" },
            React.createElement("div", { className: "" },
                React.createElement("p", null, "I'm a life long learner who loves technology."),
                React.createElement("ul", { className: "list-disc" },
                    React.createElement("li", null,
                        React.createElement("a", { href: "about_site/index.html" }, "This site "),
                        " - I built this site as my 2020 coding project. This page covers how I built it and talks about the various technologies used such as Go & AWS."),
                    React.createElement("li", null,
                        React.createElement("a", { href: "aws/index.html" }, "AWS Resources "),
                        " - several useful resources about AWS and solution architecture .")))),
        React.createElement(Container, null,
            React.createElement(StackedCardWithImage, { title: "Meet Rich Anderson", image: "/images/me/rich.jpeg", text: "Come and hang out with Rich and learn about his passion for teams and technology.Come and hang out with Rich and learn about his passion for teams and technology", tags: ["wwsm", "team", "tech"] }),
            React.createElement(StackedCardWithImage, { title: "Meet Rich Anderson", image: "/images/me/rich.jpeg", text: "Come and hang out with Rich and learn about his passion for teams and technology.Come and hang out with Rich and learn about his passion for teams and technology", tags: ["wwsm", "team", "tech"] }),
            React.createElement(StackedCardWithImage, { title: "Meet Rich Anderson", image: "/images/me/rich.jpeg", text: "Come and hang out with Rich and learn about his passion for teams and technology.Come and hang out with Rich and learn about his passion for teams and technology", tags: ["wwsm", "team", "tech"] }),
            React.createElement(StackedCardWithImage, { title: "Meet Rich Anderson", image: "/images/me/rich.jpeg", text: "Come and hang out with Rich and learn about his passion for teams and technology.Come and hang out with Rich and learn about his passion for teams and technology", tags: ["wwsm", "team", "tech"] })),
        React.createElement("div", { className: "my-4 py-4 px-4 max-w-4xl mx-auto bg-white rounded-xl shadow-lg space-y-2 sm:flex sm:items-center sm:space-y-0 sm:space-x-2" },
            React.createElement("div", { className: "text-center space-y-4 sm:text-left" },
                React.createElement("div", { className: "space-y-0.5" },
                    React.createElement("p", { className: "text-lg text-black font-semibold" }, "Tailwind Css"))),
            React.createElement(Link, { to: "buttons" }, "Buttons"),
            React.createElement(Link, { to: "cards" }, "Cards")),
        React.createElement("div", null,
            React.createElement(Outlet, null))));
};
const TweetCard = (props) => {
    function onClick() {
        window.location = props.link;
    }
    return (React.createElement("div", { className: "max-w-sm ml-4 w-full lg:max-w-full lg:flex", onClick: onClick },
        React.createElement("div", { className: "h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden", style: {
                backgroundImage: `url(${props.image})`,
            }, title: "Woman holding a mug" }),
        React.createElement("div", { className: "border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal" },
            React.createElement("div", { className: "mb-10" },
                React.createElement("p", { className: "text-sm text-gray-600 flex items-center" },
                    React.createElement("svg", { className: "fill-current text-gray-500 w-3 h-3 mr-2", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" },
                        React.createElement("path", { d: "M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" })),
                    "Members only"),
                React.createElement("div", { className: "text-gray-900 font-bold text-xl mb-2" }, props.title),
                React.createElement("p", { className: "text-gray-700 text-base" }, props.text)),
            React.createElement("div", { className: "flex items-center" },
                React.createElement("img", { className: "w-10 h-10 rounded-full mr-4", src: "/images/me/rich.jpeg", alt: "Avatar of Jonathan Reinink" }),
                React.createElement("div", { className: "text-sm" },
                    React.createElement("p", { className: "text-gray-900 leading-none" }, "Jonathan Reinink"),
                    React.createElement("p", { className: "text-gray-600" }, props.date))))));
};
const StackedCardWithImage = (props) => {
    return (React.createElement("div", { className: "max-w-sm rounded overflow-hidden shadow-lg" },
        React.createElement("img", { className: "w-full", src: props.image, alt: "Sunset in the mountains" }),
        React.createElement("div", { className: "px-6 py-4" },
            React.createElement("div", { className: "font-bold text-xl mb-2" }, props.title),
            React.createElement("p", { className: "text-gray-700 text-base" }, props.text)),
        React.createElement("div", { className: "px-6 pt-4 pb-2" }, props.tags &&
            props.tags.map((t) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2" },
                        "#",
                        t)));
            }))));
};
const Cards = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "" },
            React.createElement("div", { className: "bgmax-w-4xl flex flex-wrap space-x-3 space-y-3 ml-2 mb-4 mr-2" },
                React.createElement(StackedCardWithImage, { title: "The Coldest Sunset", image: "/images/exp/sunset.jpeg", text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus\n        quia, nulla! Maiores et perferendis eaque, exercitationem praesentium\n        nihil.", tags: ["photography", "travel", "winter"] }),
                React.createElement(StackedCardWithImage, { title: "Meet Rich Anderson", image: "/images/me/rich.jpeg", text: "Come and hang out with Rich and learn about his passion for teams and technology.Come and hang out with Rich and learn about his passion for teams and technology", tags: ["wwsm", "team", "tech"] }),
                React.createElement(StackedCardWithImage, { title: "Meet Rich Anderson", image: "/images/rgc/art1.jpeg", text: "Come and hang out with Rich and learn about his passion for teams and technology", tags: ["wwsm", "team", "tech"] }),
                React.createElement(StackedCardWithImage, { title: "Meet Rich Anderson", image: "/images/rgc/art2.jpeg", text: "Come and hang out with Rich and learn about his passion for teams and technology", tags: ["wwsm", "team", "tech"] }),
                React.createElement(StackedCardWithImage, { title: "Meet Rich Anderson", image: "/images/rgc/art3.jpeg", text: "Come and hang out with Rich and learn about his passion for teams and technology", tags: ["wwsm", "team", "tech"] }),
                React.createElement(StackedCardWithImage, { title: "Meet Rich Anderson", image: "/images/me/rich.jpeg", text: "Come and hang out with Rich and learn about his passion for teams and technology.Come and hang out with Rich and learn about his passion for teams and technology", tags: ["wwsm", "team", "tech"] }),
                React.createElement(StackedCardWithImage, { title: "Meet Rich Anderson", image: "/images/rgc/art1.jpeg", text: "Come and hang out with Rich and learn about his passion for teams and technology", tags: ["wwsm", "team", "tech"] }),
                React.createElement(StackedCardWithImage, { title: "Meet Rich Anderson", image: "/images/rgc/art2.jpeg", text: "Come and hang out with Rich and learn about his passion for teams and technology", tags: ["wwsm", "team", "tech"] }),
                React.createElement(StackedCardWithImage, { title: "Meet Rich Anderson", image: "/images/rgc/art3.jpeg", text: "Come and hang out with Rich and learn about his passion for teams and technology", tags: ["wwsm", "team", "tech"] }),
                React.createElement(TweetCard, { title: "Lex Fridman interview with John Carmack (Podcast 309)", date: "Aug 5 2022", link: "https://roamresearch.com/#/app/richandersonio/page/08-05-2022", image: "/images/exp/sunset.jpeg", text: "A marathon 5 hour interview covering the history of ID software, Doom, Quake, VR and much more." }),
                React.createElement(TweetCard, { title: "Agile & Scrum Don't Work (Engineering Room Ep. 9)", date: "Aug 3 2022", link: "https://roamresearch.com/#/app/richandersonio/page/08-03-2022", image: "/images/exp/sunset.jpeg", text: "Discussion between Allen Holub and Dave Farley In the Engineering Room Ep. 9" })))));
};
const Buttons = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "my-4 py-4 px-4 max-w-sm mx-auto bg-white rounded-xl shadow-lg space-y-2 sm:flex sm:items-center sm:space-y-0 sm:space-x-2" },
            React.createElement("div", { className: "text-center space-y-4 sm:text-left" },
                React.createElement("div", { className: "space-y-0.5" },
                    React.createElement("p", { className: "text-lg text-black font-semibold" }, "Buttons in Tailwind")),
                React.createElement("div", null,
                    React.createElement("button", { className: "bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded" }, "Button")),
                React.createElement("div", null,
                    React.createElement("button", { className: "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" }, "Pill Button")),
                React.createElement("div", null,
                    React.createElement("button", { className: "bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" },
                        React.createElement("svg", { className: "fill-current w-4 h-4 mr-2", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" },
                            React.createElement("path", { d: "M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" })),
                        React.createElement("span", null, "Download"))),
                React.createElement("div", null,
                    React.createElement("button", { className: "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 inline-flex items-center rounded-full" },
                        React.createElement("svg", { className: "fill-current w-4 h-4 mr-2", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" },
                            React.createElement("path", { d: "M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" })),
                        React.createElement("span", null, "Pill Button")))))));
};
export function getRoutes() {
    let childRoutes = [
        { path: "buttons", element: React.createElement(Buttons, null) },
        { path: "cards", element: React.createElement(Cards, null) },
    ];
    let routes = [
        { path: "tailwindcss", element: React.createElement(TailwindCss, null), children: childRoutes },
    ];
    return routes;
}
