var apiServer = __API__;
import { stdJsonFetchHeaders, throwIfNot200Response } from "./utils";
export async function rioAddWebEvent(name, category) {
    var url = apiServer + "/api/v1/webevent";
    var we = {
        name,
        category,
    };
    const response = await fetch(url, {
        method: "POST",
        headers: stdJsonFetchHeaders,
        body: JSON.stringify(we),
    });
    throwIfNot200Response(response, "rioAddAccount");
    if (response.status !== 200)
        throw "rioAddAccount failed status code " + response.status;
    return;
}
