import { HceObject } from "../../lib/hce/v3/engine/BaseObjects";
import { DesignTimePropType } from "../../lib/hce/v3/engine/DesignTime";
export default class Text extends HceObject {
    color = "white";
    font = "18px Courier";
    text = "Hello World";
    constructor(hce) {
        super(hce);
        this.width = 25;
        this.height = 25;
    }
    renderText(ctx2d, text, x, y) {
        ctx2d.fillStyle = this.color;
        ctx2d.font = this.font;
        ctx2d.fillText(text, x, y);
    }
    onRender(c) {
        var x = Math.floor(this.position.x);
        var y = Math.floor(this.position.y);
        this.renderText(c, this.text, x, y);
    }
    designTimeCreateProps() {
        let attr = super.designTimeCreateProps();
        attr.push({ classPropName: "color", type: DesignTimePropType.String });
        attr.push({ classPropName: "text", type: DesignTimePropType.String });
        attr.push({ classPropName: "font", type: DesignTimePropType.String });
        return attr;
    }
}
