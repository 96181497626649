import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
function loggedInUserOptions() {
    const loggedIn = useSelector((state) => state.google.loggedIn);
    const profile = useSelector((state) => state.google.profile);
    const role = useSelector((state) => state.user.role);
    return React.createElement(React.Fragment, null);
}
const StdFooter = () => {
    return (React.createElement("footer", { className: "footer" },
        React.createElement("div", null,
            "\u00A9 2020-2023 Rich Anderson \u00B7",
            " ",
            React.createElement("a", { href: "https://twitter.com/richanderson", className: "text-white", target: "_social" }, "Twitter"),
            " ",
            "\u00B7",
            " ",
            React.createElement("a", { href: "https://www.linkedin.com/in/richardanderson5", className: "text-white", target: "_social" }, "LinkedIn"),
            " ",
            "\u00B7",
            " ",
            React.createElement("a", { href: "https://github.com/richandersonio", className: "text-white", target: "_social" }, "GitHub"),
            " ",
            "\u00B7",
            React.createElement(Link, { to: "/links", className: "text-white" },
                " ",
                "More"),
            loggedInUserOptions())));
};
export default StdFooter;
