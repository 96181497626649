import { HceObject, HceEdgeCollider, MyVector, MoveComponent } from "../../lib/hce/v3/engine/BaseObjects";
import { ParticleGenerator } from "./ParticleGenerator";
import { Alien } from "./Alien";
export class Player extends HceObject {
    spaceKeyDown;
    gameState;
    shipColor;
    shipLineColor;
    thrustColor;
    shipVectorX;
    shipVectorY;
    gunXposition;
    gunYposition;
    gravityOnShip;
    gravityEffect;
    gravityEffectMax;
    gravityEffectInc;
    gravityX;
    gravityY;
    trackingRealPlayer;
    object;
    wrap;
    state;
    counter;
    enableFire;
    fireCooldown;
    fireCooldownCounter;
    dontdietimer;
    drawThrust;
    noColliderTime;
    keyboardMap;
    pg; // particle generator
    centerX;
    centerY;
    pressingSpace = false; // automation option
    recoverInfo;
    constructor(hce) {
        super(hce);
        this.spaceKeyDown = false;
        this.gameState = null;
        this.shipColor = "white";
        this.shipLineColor = "pink";
        this.thrustColor = "red";
        this.shipVectorX = 0;
        this.shipVectorY = .5;
        this.gunXposition = 0;
        this.gunYposition = 0;
        this.gravityOnShip = false;
        this.gravityEffect = 0;
        this.gravityEffectMax = 5;
        this.gravityEffectInc = 0.1;
        this.width = 15;
        this.height = 15;
        this.gravityX = 0;
        this.gravityY = 0;
        this.createCollider();
        this.trackingRealPlayer = null;
        this.object = null;
        this.wrap = true;
        this.state = "play";
        this.counter = 0;
        this.enableFire = true;
        this.fireCooldown = 10;
        this.fireCooldownCounter = 0;
        this.dontdietimer = 0;
        this.drawThrust = false;
        this.noColliderTime = 0;
    }
    changeState(newState) {
        console.log("player change state = current: " + this.state + " new:" + newState);
        if (this.state === newState)
            return;
        this.state = newState;
        if (this.state === "die") {
            this.counter = 100;
        }
        this.raiseEvent("stateChange", null);
    }
    createCollider() {
        this.collider = new HceEdgeCollider();
        this.collider.points.push(new MyVector(0, -this.height));
        this.collider.points.push(new MyVector(-this.width / 2, this.height / 2));
        this.collider.points.push(new MyVector(this.width / 2, this.height / 2));
        this.collider.points.push(new MyVector(0, -this.height));
        this.collider.parent = this;
    }
    // var centerX = this.position.x + (this.width / 2);
    // var centerY = this.position.y + (this.height / 2);
    // c.moveTo(0, -this.height);
    // c.lineTo(-this.width/2,  this.height / 2);
    // c.lineTo(this.width/2,  this.height / 2);
    renderCollider(c) {
        //this.collider.debugRender(c);
        return;
    }
    isRealPlayer() {
        if (this.tag === "player")
            return true;
        if (!this.object || this.object.tag !== "player") {
            return false;
        }
        return true;
    }
    initObject(obj) {
        obj.rotation = this.rotation;
        obj.fillColor = this.shipColor;
        obj.lineColor = this.shipLineColor;
        obj.tag = this.tag;
        obj.gravityOnShip = this.gravityOnShip;
        obj.thrustColor = this.thrustColor;
        obj.height = this.height;
        obj.width = this.width;
    }
    // purpose: init the player object from the design time object
    setObject(obj) {
        this.object = obj; // design time obj information
        this.position.x = obj.points[0].x;
        this.position.y = obj.points[0].y;
        this.rotation = obj.rotation;
        this.shipColor = obj.fillColor;
        this.shipLineColor = obj.lineColor;
        this.tag = this.object.tag;
        if (obj.gravityOnShip)
            this.gravityOnShip = obj.gravityOnShip;
        if (obj.thrustColor)
            this.thrustColor = obj.thrustColor;
        if (obj.height)
            this.height = obj.height;
        if (obj.width)
            this.width = obj.width;
        this.createCollider();
    }
    init(gameState) {
        this.gameState = gameState;
        this.keyboardMap = null;
    }
    OnCustom(tickContext) { }
    onTick(tickContext) {
        if (this.isRealPlayer() === false) {
            // player or enemy?
            if (this.trackingRealPlayer) {
                // create a vector to the player and move in that direction
                var towards;
                towards = new MyVector(0, 0).createFromPoints(this.position, this.trackingRealPlayer.position);
                var distance = towards.length();
                towards.normalize();
                // if near by then track
                if (distance < 400) {
                    if (distance > 10) {
                        // avoid fast flip/flopping of direction behavior when very close
                        this.position.x += towards.x * this.hce.hceCore.time.scale;
                        this.position.y += towards.y * this.hce.hceCore.time.scale;
                        this.rotation = towards.horizontalAngleDeg() + 90; // point towards the player
                    }
                }
            }
            return;
        }
        var rotateHeight = new MyVector(0, this.height / 2).rotateDeg(this.rotation);
        this.pg.position.x = this.centerX + rotateHeight.x;
        this.pg.position.y = this.centerY + rotateHeight.y;
        // alive and playing
        if (this.state === "play") {
            if (this.fireCooldownCounter > 0) {
                this.fireCooldownCounter--;
            }
            if (this.dontdietimer > 0) {
                this.dontdietimer -= this.hce.hceCore.time.frameDelta * this.hce.hceCore.time.scale;
            }
            this.keyboardMap = tickContext.keyboardMap;
            this.spaceKeyDown = false;
            if (this.keyboardMap) {
                this.checkKeyState(this.keyboardMap);
            }
            if (this.gravityOnShip === true) {
                this.position.y =
                    (this.position.y + this.gravityEffect) * this.hce.hceCore.time.scale;
                if (this.gravityEffect < this.gravityEffectMax) {
                    this.gravityEffect =
                        (this.gravityEffect + this.gravityEffectInc) * this.hce.hceCore.time.scale;
                }
            }
            this.translate(new MyVector(this.gravityX * this.hce.hceCore.time.scale, this.gravityY * this.hce.hceCore.time.scale));
            // TODO: revisit this origin x/y logic here and in the render
            var centerX = this.position.x + this.width / 2;
            var centerY = this.position.y + this.height / 2;
            this.centerX = centerX;
            this.centerY = centerY;
            this.collider.setOriginXY(centerX, centerY); // update collider origin
            this.checkForCollisions();
            // this.components.forEach(comp => {
            //     comp.tick(tickContext, this);
            // })
            this.recoverInfo = {
                rotation: this.rotation,
                width: this.width,
                height: this.height,
                shipColor: this.shipColor
            };
        }
        if (this.state === "die") {
            this.rotation += 9 * this.hce.hceCore.time.scale;
            //this.shipColor = "green";
            this.width *= 0.98 * this.hce.hceCore.time.scale;
            this.height *= 0.98 * this.hce.hceCore.time.scale;
            this.counter--;
            if (this.counter <= 0) {
                this.changeState("dead");
            }
        }
        if (this.state === "recover") {
            this.width = this.recoverInfo.width;
            this.height = this.recoverInfo.height;
            this.shipColor = this.recoverInfo.shipColor;
            towards = new MyVector(0, 0).createFromPoints(this.position, new MyVector(this.playerLastGoodX, this.playerLastGoodY));
            towards.normalize();
            //        this.position.x = this.playerLastGoodX;
            //        this.position.y = this.playerLastGoodY;
            this.position.x += towards.x * 10;
            this.position.y += towards.y * 10;
            this.setRotation(this.playerLastGoodRotation);
            this.dontdietimer = 1500;
            this.changeState("play");
        }
        //HceObject.prototype.tick.call(this, tickContext); // tick any children
    }
    playerLastGoodX;
    playerLastGoodY;
    playerLastGoodRotation;
    onSceneReady(sceneContext) {
        this.sceneContext = sceneContext;
        if (this.tag !== "player") {
            this.trackingRealPlayer = this.sceneContext.rootObject.getObjectByTag("player");
        }
        else {
            this.trackingRealPlayer = null;
        }
        // TODO: come back and get wrap working
        if (this.wrap === true) {
            var mc = new MoveComponent(this.hce);
            mc.velocity.x = 0;
            mc.velocity.y = 0;
            mc.wrapX = true;
            mc.wrapY = true;
            this.components.push(mc);
        }
        this.pg = new ParticleGenerator(this.hce);
        this.pg.position = this.position.clone();
        this.pg.position.x += this.width / 2;
        this.pg.position.y += this.height / 2;
        this.pg.onNewParticle = (p) => {
            p.velocity.x = this.shipVectorX * -1;
            p.velocity.y = this.shipVectorY * -1;
            p.velocity.x += this.gravityX;
            p.velocity.y += this.gravityY;
        };
        this.addChild(this.pg);
    }
    checkKeyState(keyboardMap) {
        if (this.hce.hceCore.time.scale === 0)
            return;
        if (keyboardMap.isPressed(["a", "A", "ArrowLeft"]))
            this.rotateLeft();
        if (keyboardMap.isPressed(["d", "D", "ArrowRight"]))
            this.rotateRight();
        if (keyboardMap.isPressed("Enter"))
            this.fire();
        if (keyboardMap.isPressed("ArrowUp") &&
            keyboardMap.isPressed("ArrowUp").shiftKey === false)
            this.thrust();
        if (keyboardMap.isPressed(["w", "W"]))
            this.thrust();
        if (keyboardMap.isPressed("ArrowDown") &&
            keyboardMap.isPressed("ArrowDown").shiftKey === false) {
            this.reverseThrust();
        }
        if (keyboardMap.get(" ") && keyboardMap.get(" ").shiftKey === false)
            this.thrust();
        if (this.pressingSpace === true)
            this.thrust();
        if (keyboardMap.get(" ") && keyboardMap.get(" ").shiftKey === true)
            this.reverseThrust();
    }
    onRender(c) {
        if (!this.visible)
            return;
        this.renderShip(c);
    }
    renderShip(c) {
        var centerX = this.position.x + this.width / 2;
        var centerY = this.position.y + this.height / 2;
        c.save();
        c.strokeStyle = this.shipLineColor;
        c.beginPath();
        c.fillStyle = this.shipColor;
        c.translate(centerX, centerY);
        c.rotate((this.rotation * Math.PI) / 180); // degree to radains
        c.moveTo(0, -this.height);
        c.lineTo(-this.width / 2, this.height / 2);
        c.lineTo(this.width / 2, this.height / 2);
        c.closePath();
        c.fill();
        c.stroke();
        // draw thrust
        if (this.keyboardMap && this.spaceKeyDown) {
            if (this.drawThrust) {
                c.beginPath();
                c.fillStyle = this.thrustColor;
                c.moveTo(0, this.height / 2 + 15);
                c.lineTo(-this.width / 4, this.height / 2);
                c.lineTo(this.width / 4, this.height / 2);
                c.fill();
                c.stroke();
                c.closePath();
            }
            if (this.pg)
                this.pg.generating = true;
        }
        else {
            if (this.pg)
                this.pg.generating = false;
        }
        c.restore();
        // = sin() = Opposite / Hypotenuse
        // siner reminder - https://www.youtube.com/watch?v=mZsJzdv34AI
        // rotate vector  - https://www.youtube.com/watch?v=DOMg0lXWatM
        var vx = 0;
        var vy = -1;
        var rotation = this.rotation;
        this.shipVectorX =
            Math.cos((rotation * Math.PI) / 180) * vx -
                Math.sin((rotation * Math.PI) / 180) * vy;
        this.shipVectorY =
            Math.sin((rotation * Math.PI) / 180) * vx +
                Math.cos((rotation * Math.PI) / 180) * vy;
        var xToRotate = 0;
        var yToRotate = -this.height;
        var xRot = Math.cos((rotation * Math.PI) / 180) * xToRotate -
            Math.sin((rotation * Math.PI) / 180) * yToRotate;
        var yRot = Math.sin((rotation * Math.PI) / 180) * xToRotate +
            Math.cos((rotation * Math.PI) / 180) * yToRotate;
        xRot = xRot + centerX;
        yRot = yRot + centerY;
        this.gunXposition = xRot;
        this.gunYposition = yRot;
        // draw the gun
        c.fillStyle = "#ffffff";
        c.beginPath();
        c.arc(this.gunXposition, this.gunYposition, 2, 0, 2 * Math.PI);
        c.fill();
        c.stroke();
        c.closePath();
        //this.renderCollider(c);
        return;
    }
    setRotation(val) {
        this.rotation = val;
        if (this.rotation <= 0) {
            this.rotation = 360;
        }
        if (this.rotation > 360) {
            this.rotation = 0;
        }
        // TODO: should probably rotate all children + colliders when a parent object is changed
        this.collider.setRotationDeg(this.rotation); // info collider
    }
    rotateLeft() {
        this.setRotation(this.rotation - 3 * this.hce.hceCore.time.scale);
    }
    rotateRight() {
        this.setRotation(this.rotation + 3 * this.hce.hceCore.time.scale);
    }
    thrust() {
        //      this.gravityX = this.gravityX + this.shipVectorX/10;
        //      this.gravityY = this.gravityY + this.shipVectorY/10;
        this.gravityX =
            this.gravityX + (this.shipVectorX / 7) * this.hce.hceCore.time.scale;
        this.gravityY =
            this.gravityY + (this.shipVectorY / 7) * this.hce.hceCore.time.scale;
        //console.log(this.gravityX);
        this.spaceKeyDown = true;
    }
    reverseThrust() {
        this.gravityX =
            this.gravityX - (this.shipVectorX / 6) * this.hce.hceCore.time.scale;
        this.gravityY =
            this.gravityY - (this.shipVectorY / 6) * this.hce.hceCore.time.scale;
        this.spaceKeyDown = true;
    }
    stopShip() {
        this.gravityX = this.gravityY = 0;
    }
    checkForCollisions() {
        // TODO: May be secondary ship and not the player.  Better way to check this?
        if (!this.gameState) {
            return;
        }
        var objects = this.gameState.objects;
        var drawObjects = objects.filter((obj) => obj.layer !== "hidden");
        for (var o = 0; o < drawObjects.length; o++) {
            var obj = drawObjects[o];
            points = drawObjects[o].points;
            if (obj.collide === undefined)
                obj.collide = true;
            var fill = false;
            if (obj.type === "line") {
                if (obj.collide) {
                    if (this.checkLineImpactMain(obj))
                        return;
                }
            }
            if (obj.type === "shape") {
                if (obj.collide) {
                    if (this.checkLineImpactMain(obj))
                        return;
                }
            }
            if (obj.type === "square") {
                if (this.checkLineImpactMain(obj))
                    return;
            }
            if (obj.type === "circle") {
                if (this.checkLineImpactMain(obj))
                    return;
            }
        }
        frame = frame + 1;
        // no collisions - remember the last good position if we recover the player
        this.playerLastGoodX = this.position.x;
        this.playerLastGoodY = this.position.y;
        this.playerLastGoodRotation = this.rotation;
        this.noColliderTime += this.hce.hceCore.time.frameDelta * this.hce.hceCore.time.scale;
    }
    // check if the player hit the object specified
    checkLineImpactMain(obj) {
        var debug = false;
        var points = RT_getPointsForShape(obj);
        var i = this.collider.hit(points);
        if (i != null) {
            // show the hit point
            if (debug) {
                var c = this.gameState.canvas2dContext;
                c.beginPath();
                c.fillStyle = "yellow";
                c.arc(i.x, i.y, 10, 0, 2 * Math.PI);
                c.stroke();
                c.fill();
            }
            this.gravityX = 0;
            this.gravityY = 0;
            if (this.dontdietimer <= 0) {
                // dont immediate die again if we just cras
                this.raiseEvent("playerCrashed", i);
                console.log("raised collision event");
                return true;
            }
            this.noColliderTime = 0;
        }
        return false;
    }
    fire() {
        if (!this.enableFire)
            return;
        if (this.fireCooldownCounter > 0) {
            return;
        }
        this.fireCooldownCounter = this.fireCooldown;
        var newAlien = new Alien(this.hce);
        newAlien.components = [];
        newAlien.imageId = "alienimg";
        newAlien.position.x = this.gunXposition;
        newAlien.position.y = this.gunYposition;
        newAlien.gravityX = this.shipVectorX * 5;
        newAlien.gravityY = this.shipVectorY * 5;
        newAlien.gravityX += this.gravityX;
        newAlien.gravityY += this.gravityY;
        newAlien.tag = "bullet";
        // TODO: Aug 22 - visit if this was needed
        //newAlien.obj = {};
        //newAlien.obj.width = 8;
        //newAlien.obj.height = 8;
        newAlien.width = 8;
        newAlien.height = 8;
        newAlien.collider = new HceEdgeCollider();
        newAlien.collider.parent = newAlien;
        // square box around the image
        newAlien.collider.points.push(new MyVector(0, 0));
        newAlien.collider.points.push(new MyVector(newAlien.width, 0));
        newAlien.collider.points.push(new MyVector(newAlien.width, newAlien.height));
        newAlien.collider.points.push(new MyVector(0, newAlien.height));
        newAlien.collider.points.push(new MyVector(0, 0));
        this.sceneContext.rootObject.addChild(newAlien);
    }
}
