import { HceObject, MyVector, } from "../../lib/hce/v3/engine/BaseObjects";
import { DesignTimePropType } from "../../lib/hce/v3/engine/DesignTime";
export class Starfield extends HceObject {
    // props
    starCount = 7500;
    rotateBy = -.05;
    maxDistanceZ = 1000;
    starSize = 1.1;
    stars = [];
    // state
    lastFrameRenderedStars = 0;
    constructor(hce) {
        super(hce);
    }
    designTimeCreateProps() {
        let attr = super.designTimeCreateProps();
        attr.push({ classPropName: "starCount", type: DesignTimePropType.Number });
        attr.push({ classPropName: "rotateBy", type: DesignTimePropType.Number });
        attr.push({ classPropName: "maxDistanceZ", type: DesignTimePropType.Number });
        attr.push({ classPropName: "starSize", type: DesignTimePropType.Number });
        attr.push({ classPropName: "stars", type: DesignTimePropType.Number });
        return attr;
    }
    init() {
        this.stars = this.makeStars();
        this.rotation = 0;
        var dtState = {
            starCount: 7500,
            rotateBy: .1,
            maxDistanceZ: 1000,
            starSize: .75
        };
        //this.designTimeLoadState(dtState);
        // alert(JSON.stringify(this.designTimeSaveState()))
    }
    onTick(ctx) {
        this.moveStars(1);
    }
    onRender(ctx2d) {
        this.renderStars(ctx2d);
    }
    moveStars(distance) {
        for (var i = 0; i < this.starCount; i++) {
            this.stars[i].z -= distance;
            if (this.stars[i].z <= 1)
                this.stars[i].z = this.maxDistanceZ;
        }
    }
    makeStars() {
        const stars = [];
        let maxX = Math.abs(this.hce.canvas.width);
        let maxY = Math.abs(this.hce.canvas.height);
        maxX = maxY = 15;
        for (let i = 0; i < this.starCount; i++) {
            const s = {
                x: Math.random() * maxX -
                    maxX / 2,
                y: Math.random() * maxY -
                    maxY / 2,
                z: Math.random() * this.maxDistanceZ,
                color: [Math.random() * 255, Math.random() * 255, Math.random() * 255],
            };
            stars.push(s);
        }
        return stars;
    }
    drawStar(ctx2d, x, y, star) {
        var size = this.starSize;
        const intensity = 255 * (this.maxDistanceZ / star.z);
        const rgb = "rgb(" + star.color[0] + "," + star.color[1] + "," + star.color[2] + ")";
        size *= this.maxDistanceZ / star.z / 2;
        ctx2d.fillStyle = rgb;
        ctx2d.fillRect(x, y, size, size);
    }
    renderStars(ctx2d) {
        this.lastFrameRenderedStars = 0;
        const cx = ctx2d.canvas.width / 2;
        const cy = ctx2d.canvas.height / 2;
        var x, y;
        this.rotation = this.rotation + this.rotateBy;
        for (var i = 0; i < this.starCount; i++) {
            const star = this.stars[i];
            const d = star.z / 25000; // speed the star, smaller is quicker
            var v = new MyVector(star.x, star.y);
            v.rotateDeg(this.rotation);
            x = cx + (v.x / d) * 0.8; // smaller = density increases
            y = cy + (v.y / d) * 0.8;
            if (x < 0 ||
                x >= ctx2d.canvas.width ||
                y < 0 ||
                y >= ctx2d.canvas.height) {
                continue;
            }
            this.drawStar(ctx2d, x, y, star);
            this.lastFrameRenderedStars++;
        }
    }
}
