export default class DrawUtil {
    c;
    constructor(canvas2d) {
        this.c = canvas2d;
    }
    drawPoints(points, fillColor, canvas2d) {
        if (canvas2d === undefined) {
            canvas2d = this.c;
        }
        if (fillColor === undefined) {
            fillColor = "white";
        }
        canvas2d.beginPath();
        canvas2d.fillStyle = fillColor;
        canvas2d.strokeStyle = fillColor;
        canvas2d.moveTo(points[0].x, points[0].y);
        //    console.log("points " + points.length);
        //    console.log("moveto " + points[0].x + " , " + points[0].y)
        for (var i = 1; i < points.length; i++) {
            //    console.log("lineTo " + i + "," + points[1].x + " , " + points[1].y)
            canvas2d.lineTo(points[i].x, points[i].y);
        }
        canvas2d.closePath();
        canvas2d.fill();
        canvas2d.stroke();
    }
    drawFilledBox(x, y, w, h, fillColor, ctx2d) {
        if (fillColor === undefined) {
            fillColor = "white";
        }
        if (ctx2d === undefined) {
            ctx2d = this.c;
        }
        ctx2d.beginPath();
        ctx2d.fillStyle = fillColor;
        ctx2d.moveTo(x, y);
        ctx2d.lineTo(x + w, y);
        ctx2d.lineTo(x + w, y + h);
        ctx2d.lineTo(x, y + h);
        ctx2d.closePath();
        ctx2d.fill();
        ctx2d.stroke();
    }
}
