import * as React from "react";
import { useSelector } from "react-redux";
import ContentBlock from "./ContentBlock";
import { Link } from "react-router-dom";
import AdminUserList from "../admin/AdminUserList";
const AdminPanel = () => {
    const role = useSelector((state) => state.user.role);
    return (React.createElement("div", null,
        role === "Admin" && (React.createElement(ContentBlock, { title: "Admin Panel" },
            React.createElement("div", null,
                React.createElement(Link, { to: "/admin/log" }, "Log Viewer"),
                React.createElement("br", null),
                React.createElement(Link, { to: "/admin/accounts" }, "Account List"),
                React.createElement("br", null),
                React.createElement(Link, { to: "/admin/users" }, "User List"),
                React.createElement("br", null),
                React.createElement(Link, { to: "/admin" }, "Admin Panel"),
                " ",
                React.createElement("br", null),
                React.createElement(Link, { to: "/" }, "Site Homepage"),
                " ",
                React.createElement("br", null)))),
        role === "Admin" && (React.createElement("div", null,
            React.createElement(ContentBlock, { title: "Pages in Development" },
                React.createElement("div", null,
                    React.createElement(Link, { to: "/about" }, "About"),
                    React.createElement("br", null),
                    React.createElement(Link, { to: "/contract" }, "Contract"),
                    React.createElement("br", null))))),
        role === "Admin" && React.createElement(AdminUserList, null)));
};
export default AdminPanel;
