import * as React from "react";
import { Portal } from "../comp/Portal";
import { useOpenClose } from "../hooks/useOpenClose";
export function usePortal(element, props, children) {
    const [isOpen, onOpen, onClose] = useOpenClose();
    const defaultProps = {
        onClose,
        onOpen,
        isOpen,
        ...props,
    };
    const innerElement = React.createElement(element, defaultProps, children);
    const layout = (React.createElement(React.Fragment, null,
        React.createElement(Portal, { isOpen: isOpen, element: innerElement })));
    return [React.createElement(React.Fragment, null, layout), isOpen, onOpen, onClose];
}
