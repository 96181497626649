import * as React from "react";
import { useState, useRef, useEffect } from "react";
export const DraggableWindow = (props) => {
    const divRef = useRef();
    const xRef = useRef(props.x ? props.x : 0);
    const yRef = useRef(props.y ? props.y : 0);
    const draglog = useRef([]);
    const [dragXY, setDragXY] = useState({ x: xRef.current, y: yRef.current });
    const draggingRef = useRef(false);
    const offsetX = useRef(0);
    const offsetY = useRef(0);
    const eventSource = useRef(null);
    const [dragging, setDragging] = useState(false);
    const createDragLogView = () => {
        var elements = draglog.current.map((e) => {
            let style = {
                padding: "0px",
                position: "absolute",
                top: e.y + "px",
                right: "0px",
                bottom: "0px",
                color: "white",
                left: e.x + "px",
                width: props.width ? props.width : "10x",
                height: props.height ? props.height : "10px",
                zIndex: 100, // push us behind the HTML content
            };
            return React.createElement("span", { style: style }, "*");
        });
        return elements;
    };
    useEffect(() => {
        console.log("draglog changed");
    }, [draglog.current.length]);
    useEffect(() => {
        eventSource.current = divRef.current;
        eventSource.current.addEventListener("mousedown", startDrag);
        let id = setInterval(() => {
            console.log("my interval...x=" + xRef.current + " y=" + yRef.current);
        }, 1000);
        return () => {
            clearInterval(id);
            console.log("stop interval");
        };
    }, [divRef]);
    let floatingWindowStyle = {
        padding: "0px",
        position: "absolute",
        top: yRef.current + "px",
        right: "0px",
        bottom: "0px",
        left: xRef.current + "px",
        width: props.width ? props.width : "100px",
        height: props.height ? props.height : "100px",
        zIndex: 100, // push us behind the HTML content
    };
    const startDrag = (event) => {
        offsetX.current = event.clientX - xRef.current;
        offsetY.current = event.clientY - yRef.current;
        draglog.current = [];
        draglog.current.push({
            x: event.clientX,
            y: event.clientY,
            time: performance.now(),
        });
        draggingRef.current = true;
        setDragging(true);
        document.addEventListener("mousemove", drag);
        document.addEventListener("mouseup", endDrag);
        event.preventDefault();
    };
    const drag = (event) => {
        xRef.current = event.clientX - offsetX.current;
        yRef.current = event.clientY - offsetY.current;
        draglog.current.push({
            x: xRef.current,
            y: yRef.current,
            time: performance.now(),
        });
        setDragXY({ x: xRef.current, y: yRef.current });
        event.preventDefault();
    };
    const endDrag = (event) => {
        if (draggingRef.current !== true)
            return;
        draglog.current.push({
            x: xRef.current,
            y: yRef.current,
            time: performance.now(),
        });
        xRef.current = event.clientX - offsetX.current;
        yRef.current = event.clientY - offsetY.current;
        document.removeEventListener("mousemove", drag);
        document.removeEventListener("mouseup", endDrag);
        draggingRef.current = false;
        setDragging(false);
        setDragXY({ x: xRef.current, y: yRef.current });
        event.preventDefault();
        console.log("drag points = " + draglog.current.length);
    };
    let cursor = dragging ? "cursor-grabbing" : "cursor-pointer";
    //  let cursor = ""
    return (React.createElement("div", { ref: divRef, className: cursor, style: floatingWindowStyle },
        props.children,
        React.createElement("div", { style: { backgroundColor: "green" } },
            "OK - ",
            createDragLogView())));
};
