import { rioGetWebConfig } from "../lib/rio/config";
export class Config {
    idata = {};
    get data() {
        return this.idata;
    }
    constructor() {
        this.idata.apiServer = __API__;
        this.idata.startDate = new Date();
    }
    async init() {
        await this.loadBootConfig();
    }
    async loadBootConfig() {
        var config = await rioGetWebConfig();
        this.data.siteDown = config.siteDown;
        this.data.siteDownMessage = config.siteDownMessage;
        if (config.welcome !== "" && config.welcome !== undefined) {
            alert(config.welcome);
        }
    }
}
