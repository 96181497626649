import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ContentBlock from "./ContentBlock";
import { Link } from "react-router-dom";
import { useOnKeyTrigger } from "../lib/hooks/useOnKey";
import { usePortal } from "../lib/hooks/usePortal";
const FullScreenModal = (props) => {
    return (React.createElement("div", { className: "modal", style: { backgroundColor: "white", padding: "10px" } }, props.children));
};
const SplashPage = (props) => {
    if (!props.isOpen)
        return;
    return (React.createElement(React.Fragment, null,
        React.createElement(FullScreenModal, null,
            React.createElement("div", { className: "modal", style: { padding: "10px" } },
                React.createElement("div", { className: "modal-content" },
                    React.createElement("h1", null, "WOW!"),
                    React.createElement("button", { onClick: props.onClose }, "Close"))))));
};
const MyHomePortal = (props) => {
    if (!props.isOpen)
        return;
    return (React.createElement(React.Fragment, null,
        React.createElement(FullScreenModal, null,
            React.createElement("div", { className: "modal-content" },
                React.createElement("h1", null, "This is a boring portal"),
                React.createElement("button", { onClick: props.onClose }, "Close")))));
};
const HomePage = () => {
    const [portal, , open] = usePortal(MyHomePortal, { message: "Yo panda!" }, React.createElement("p", null, "this is pretty interetsing!"));
    const [splashPage, , openSplash] = usePortal(SplashPage, { message: "Yo panda!" }, React.createElement("p", null, "this is pretty interetsing!"));
    useOnKeyTrigger("/open", () => {
        open();
    });
    useOnKeyTrigger("/splash", () => {
        openSplash();
    });
    const role = useSelector((state) => state.user.role);
    useEffect(() => {
        //rioAddWebEvent("homepage", "rio.web");
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "fadein" },
            portal,
            splashPage,
            React.createElement(ContentBlock, { title: "Guest Book", enabled: false },
                React.createElement("div", null,
                    React.createElement("p", null, "A little old school, but why not sign the guestbook?"),
                    React.createElement("a", { href: "guestbook.html" }, "Click here to sign"))),
            React.createElement(ContentBlock, { title: "Technology", enabled: false },
                React.createElement("div", null,
                    React.createElement("p", null, "I'm a life long learner who loves technology."),
                    React.createElement("ul", null,
                        React.createElement("li", null,
                            React.createElement("a", { href: "about_site/index.html" }, "This site "),
                            " - I built this site as my 2020 coding project. This page covers how I built it and talks about the various technologies used such as Go & AWS."),
                        React.createElement("li", null,
                            React.createElement("a", { href: "aws/index.html" }, "AWS Resources "),
                            " - several useful resources about AWS and solution architecture .")))),
            React.createElement(ContentBlock, { title: "Games & Fun Stuff" },
                React.createElement("div", null,
                    React.createElement("ul", { className: "list-disc" },
                        React.createElement("li", null,
                            React.createElement("a", { href: "/games/eatthedot.html" }, "Eat the dots"),
                            " - A fun little HTML 5 game I created with the kids a few years back."),
                        React.createElement("li", null,
                            React.createElement("a", { href: "/c64demo/index.html" }, "HTML C64 Demo"),
                            " - A demo I created inspired by my demo days."),
                        React.createElement("li", null,
                            React.createElement(Link, { to: "/c64" }, "C64 Music"),
                            " - A few of my favourite tunes and resources on the C64.")))),
            React.createElement(ContentBlock, { title: "Other Topics" },
                React.createElement("div", null,
                    React.createElement("p", null, "Some topics you might find interesting:"),
                    React.createElement("ul", { className: "list-disc" },
                        React.createElement("li", null,
                            React.createElement(Link, { to: "/shoplocal" }, "Support small business "),
                            " - Places to visit or order online from.",
                            " "),
                        React.createElement("li", null,
                            React.createElement("a", { href: "https://roamresearch.com/#/app/richandersonio/page/lEvnnP1jm" },
                                "My public Roam graph",
                                " "),
                            " ",
                            "- Additional content such as playlists and interesting articles and books I've enjoyed.")))),
            React.createElement(ContentBlock, { title: "React 360 Demo", enabled: true },
                React.createElement("div", null,
                    React.createElement("p", null,
                        "I coded this VR app back in 2018 using",
                        " ",
                        React.createElement("a", { href: "https://facebook.github.io/react-360/" }, "ReactVR "),
                        " when I was reseaching potential applications of VR and creating a fun demo for a conference."),
                    React.createElement("p", null,
                        "It is a pretty basic demo, but shows a fairly nice navigation system in full VR:",
                        " "),
                    React.createElement("iframe", { width: "100%", height: "315", src: "https://www.youtube.com/embed/0r-bs_rOHnE", frameBorder: "0", allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }))),
            React.createElement(ContentBlock, { title: "COVID\u201119 Resources" },
                React.createElement("div", null,
                    React.createElement("ul", { className: "list-disc" },
                        React.createElement("li", null,
                            React.createElement("p", null,
                                React.createElement("a", { href: "https://ncov2019.live/" }, "nCoV2019.live "),
                                "- Latest stats by Region. Great site by by Avi Schiffmann.")),
                        React.createElement("li", null,
                            React.createElement("p", null,
                                React.createElement("a", { href: "https://www.google.com/covid19/" }, "Google"),
                                " - Google has a wonderful set of resources. This is a great place to start.")),
                        React.createElement("li", null,
                            React.createElement("p", null,
                                React.createElement("a", { href: "https://www.facebook.com/coronavirus_info" }, "Facebook"),
                                " ",
                                "- tips/resources.")),
                        React.createElement("li", null,
                            React.createElement("p", null,
                                React.createElement("a", { href: "https://www.who.int/emergencies/diseases/novel-coronavirus-2019/situation-reports" }, "World Health Organization"),
                                " ",
                                "- Situation reports providing daily updates and stats."))))),
            React.createElement(ContentBlock, { title: "Professional Experience" },
                React.createElement("div", null,
                    React.createElement("p", null, "I'm a product and technology guy who loves designing, building, and scaling platforms & products for millions of users."),
                    React.createElement("p", null, "As a people-first leader, I excel at creating and enabling highly engaged global teams to design and build world-class award-winning products."),
                    React.createElement("p", null, "Here are some of my more recent roles/achievements:"),
                    React.createElement("ul", { className: "list-disc" },
                        React.createElement("li", null,
                            React.createElement("p", null,
                                React.createElement(Link, { to: "/resume" }, "SVP Product Development, Upmarket (2019-2020)"),
                                React.createElement("br", null),
                                " Lead the program/global team that brought the Lifion to market, resulting in Lifion winning the 2019 Awesome New Product + Awesome new Technology awards at HR Technology.")),
                        React.createElement("li", null,
                            React.createElement("p", null,
                                React.createElement(Link, { to: "/resume" }, "SVP Product Development, Small Business (2015-2018)"),
                                React.createElement("br", null),
                                "Scaled the core SBS product, platform & teams to support over 550,000k clients, achieving best in class NPS scores and significantly improving margins year over year with product/design improvements.")),
                        React.createElement("li", null,
                            React.createElement("p", null,
                                React.createElement(Link, { to: "/resume" }, "See more \u2192"),
                                React.createElement("br", null))))))),
        " "));
};
export default HomePage;
