import React from "react";
import RioApp from "./app/App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { Config } from "./config/config";
import { createRoot } from "react-dom/client";
import { useState, useEffect } from "react";
import { userLoggedIn } from "./app/appUserSlice";
import RgcApp from "./experimental/rgc/RgcApp";
import "./style.css";

let mainApp = <RioApp />;

if (window.location.hostname.toLowerCase() === "robinglasscraft.com") {
  document.title = "Robin Glass Craft";
  mainApp = <RgcApp />;
}

const container = document.getElementById("richapp");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

if (module.hot) module.hot.accept();

const SiteMessage = () => {
  const [message, setMessage] = useState("");

  async function appInit() {
    renderPleaseWait();
    let c = new Config();
    await c.init();

    if (c.data.siteDown === true) {
      setMessage(c.data.siteDownMessage);
      return;
    }
    renderApp();
  }

  useEffect(() => {
    appInit();
  }, []);

  return <h1>{message}</h1>;
};

function renderPleaseWait() {
  root.render(<SiteMessage />);
}

function renderApp() {
  // TODO: Review if we should keep this.  Added so I could work offline.
  // check for JWT on query string
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let jwt = params.jwt;
  if (jwt !== "" && jwt !== null) {
    store.dispatch(userLoggedIn(jwt));
  }
  root.render(<Provider store={store}>{mainApp}</Provider>);
}

renderPleaseWait();
