import { createSlice } from "@reduxjs/toolkit";
import { decodeJwt } from "jose";
const initialState = {
    jwt: "",
    role: "Standard",
    account: "public",
    authenticated: false,
    riouid: "",
};
export const appUserSlice = createSlice({
    name: "appUserSlice",
    initialState,
    reducers: {
        userLoggedIn: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            const response = decodeJwt(action.payload);
            state.jwt = action.payload;
            state.account = response.account;
            state.role = response.role;
            state.riouid = response.riouid;
            state.authenticated = true;
        },
    },
});
// Action creators are generated for each case reducer function
export const { userLoggedIn } = appUserSlice.actions;
export default appUserSlice.reducer;
