import * as React from "react";
import { useState } from "react";
let id = 0;
const ContentBlock = (props) => {
    const myId = useState(id++);
    if (props.enabled === false) {
        return React.createElement("div", null);
    }
    return (React.createElement("div", { key: myId.toString(), id: myId.toString(), className: "content_block m-0", style: { border: "1px solid rgb(201, 201, 201)", marginTop: "12px" } },
        React.createElement("h2", null, props.title),
        props.children));
};
export default ContentBlock;
