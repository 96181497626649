import * as React from "react";
import { useEffect, useState } from "react";
import { HceAssetManager } from "../x/HceAssetManager";
export const useHceAssetManager = () => {
    const [assetMgr, setAssetMgr] = useState(new HceAssetManager());
    const [assetCount, setAssetCount] = useState(0);
    function assetLoadedCallback(asset) {
        console.log("useHceAssetManager() - assetLoadedCallback");
        setAssetCount(assetCount + 1);
    }
    useEffect(() => {
        assetMgr.assetLoadedCallback = assetLoadedCallback;
    }, []);
    let assetsLoaded = assetMgr.trackedAssets.map((a) => {
        return React.createElement("p", null, a.typet);
    });
    let ui = (React.createElement("div", null,
        React.createElement("h1", null, "Hce Asset Manager"),
        React.createElement("p", null,
            "Asset count ",
            assetCount),
        assetsLoaded));
    return [assetMgr, ui];
};
export default useHceAssetManager;
