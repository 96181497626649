;
export class KeyboardMap {
    keyboardMap;
    onKeyDown;
    onKeyUp;
    constructor() {
        this.keyboardMap = null;
    }
    init(parent) {
        if (!parent)
            parent = window;
        this.keyboardMap = new Map();
        this.onKeyDown = (event) => {
            var keyState = {
                key: event.key,
                shiftKey: event.shiftKey,
            };
            this.keyboardMap.set(event.key, keyState);
            event.preventDefault();
        };
        this.onKeyUp = (event) => {
            var keyState = {
                key: event.key,
                shiftKey: false,
            };
            this.keyboardMap.set(event.key, null);
            event.preventDefault();
        };
        parent.addEventListener("keydown", this.onKeyDown, false);
        parent.addEventListener("keyup", this.onKeyUp, false);
    }
    // small helper to check if any key in an array is in a pressed state
    isPressed(keys) {
        if (Array.isArray(keys)) {
            for (let key of keys) {
                if (this.keyboardMap.get(key)) {
                    return this.keyboardMap.get(key);
                }
            }
            return null;
        }
        return this.get(keys);
    }
    get(key) {
        if (!this.keyboardMap)
            return null;
        return this.keyboardMap.get(key);
    }
    tick() { }
    term() {
        window.removeEventListener("keydown", this.onKeyDown, false);
        window.removeEventListener("keyup", this.onKeyUp, false);
        delete this.keyboardMap;
        this.keyboardMap = null;
    }
}
