import { HceObject } from "./BaseObjects";
var HceSceneState;
(function (HceSceneState) {
    HceSceneState[HceSceneState["uninitialized"] = 0] = "uninitialized";
    HceSceneState[HceSceneState["loaded"] = 1] = "loaded";
    HceSceneState[HceSceneState["starting"] = 2] = "starting";
    HceSceneState[HceSceneState["playing"] = 3] = "playing";
    HceSceneState[HceSceneState["stopped"] = 4] = "stopped";
})(HceSceneState || (HceSceneState = {}));
// TODO: redesign this and have async methods
export default class HceScene {
    rootObject = null;
    state = HceSceneState.uninitialized;
    animation = null;
    hce;
    onStoppedCallback = null;
    platforms;
    constructor(hce) {
        this.hce = hce;
        this.rootObject = new HceObject(hce);
        this.render = this.render.bind(this);
    }
    // initial the scene object
    init() {
    }
    term() {
        this.stop();
    }
    //  play the scene
    start() {
        this.onStart();
        this.state = HceSceneState.playing;
        this.animation = this.hce.queueAnimation(this.render);
        this.rootObject.sceneReady();
    }
    // stop the scene
    stop() {
        if (this.state !== HceSceneState.playing)
            return;
        this.hce.stopAnimation(this.animation);
        this.animation = null;
        this.onStop();
        setTimeout(() => {
            // allow a frame to finish up animations then callback
            this.onStopped();
            this.state = HceSceneState.stopped;
        }, 1);
    }
    // animation callback - convert into our own render call
    render(context) {
        var renderContext = {
            hce: this.hce,
            ctx2d: context.ctx
        };
        this.onRender(renderContext);
    }
    // override for a derived class
    onStart() { }
    onStop() { }
    onRender(context) { }
    onStopped() { }
}
