import { HceObject, MyVector, } from "../../../lib/hce/v3/engine/BaseObjects";
export class Starfield extends HceObject {
    stars = [];
    rotate;
    x;
    y;
    constructor(hce) {
        super(hce);
    }
    init() {
        // make 10000 stars  (~1500 rendered to the screen at one time)
        var stars = 10000;
        this.stars = this.makeStars(this.hce.ctx, this.hce.canvas, stars);
        this.rotate = 0;
        this.x = 0;
        this.y = 0;
    }
    onTick(ctx) {
        this.moveStars(3);
    }
    onRender(ctx) {
        this.renderStars(ctx);
    }
    moveStars(distance) {
        const count = this.stars.length;
        for (var i = 0; i < count; i++) {
            const s = this.stars[i];
            s.z -= distance;
            while (s.z <= 1) {
                s.z += 1000;
            }
        }
    }
    makeStars(ctx, c, count) {
        const stars = [];
        for (let i = 0; i < count; i++) {
            const s = {
                x: Math.random() * Math.abs(c.width) - Math.abs(c.width) / 2,
                y: Math.random() * Math.abs(c.height) - Math.abs(c.height) / 2,
                z: Math.random() * 2000,
            };
            stars.push(s);
        }
        return stars;
    }
    drawPixel(x, y, brightness) {
        var size = 3;
        var starBrightness = 255;
        const intensity = brightness * starBrightness;
        const rgb = "rgb(" + intensity + "," + intensity + "," + intensity + ")";
        this.hce.ctx.fillStyle = rgb;
        size = (size * intensity) / 128;
        this.hce.ctx.fillRect(x, y, size, size);
    }
    renderStars(ctx) {
        console.log("render stars");
        const cx = this.hce.canvas.width / 2;
        console.log("render stars");
        const cy = this.hce.canvas.height / 2;
        const count = this.stars.length;
        var renderedStars = 0;
        var x, y;
        this.rotate = this.rotate + 0.15;
        for (var i = 0; i < count; i++) {
            const star = this.stars[i];
            const d = star.z / 450.0; // speed the star, smaller is quicker
            const b = 0.5 - d * d * d * d * d * d;
            if (b <= 0)
                // if you can't see the star, skip all the rendering / processing
                continue;
            var sx = star.x;
            var sy = star.y;
            var v = new MyVector(sx, sy);
            v.x += this.x;
            v.rotateDeg(this.rotate);
            x = cx + (v.x / d) * 0.6; // smaller = density increases
            y = cy + (v.y / d) * 0.6;
            if (x < 0 ||
                x >= this.hce.canvas.width ||
                y < 0 ||
                y >= this.hce.canvas.height) {
                continue;
            }
            this.drawPixel(x, y, b);
            renderedStars = renderedStars + 1;
        }
    }
}
