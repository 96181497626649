import { useEffect, useRef, useState } from "react";
// tracks if a key is pressed
export function useOnKey() {
    const keysPressed = useRef("");
    const [value, setValue] = useState("");
    function setRefValue(value) {
        keysPressed.current = value;
        setValue(value);
    }
    function downHandler(e) {
        keysPressed.current = keysPressed.current + e.key;
        // console.log("useOnKey() - keysPressed =" + keysPressed.current);
        setValue(keysPressed.current);
    }
    const upHandler = (e) => { };
    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    }, []);
    return [value, setRefValue];
}
export function useOnKeyTrigger(trigger, handler) {
    const [keysPressed, setKeysPressed] = useOnKey();
    if (keysPressed.includes(trigger)) {
        setKeysPressed("");
        handler();
    }
    return [keysPressed, setKeysPressed];
}
