//import React, { useEffect, useState } from "react";
import { useRef, useEffect } from "react";
import * as React from "react";
import { MyLinksScene } from "./MyLinksScene";
import { useOnKey } from "../../lib/hooks/useOnKey";
import { useHceFullScreen } from "../../lib/hce/v3/react/useHceFullScreen";
const MyLinksDemo = (props) => {
    const musicPlaying = useRef(null);
    const renderCount = useRef(0);
    const activeScene = useRef(null);
    const [keysPressed, setKeysPressed] = useOnKey();
    const mainStarCount = useRef(2000);
    const [hceReady, hceapi, hceelement] = useHceFullScreen();
    useEffect(() => {
        if (hceReady === true) {
            loadTitleScene();
            if (props.onApiReady) {
                var myApi = {
                    playMusic: playMusic,
                    hceApi: hceapi,
                };
                props.onApiReady(myApi);
            }
        }
    }, [hceReady]);
    renderCount.current++;
    const playMusic = (tune) => {
        if (tune === "1")
            loadMusic("lightforce", "/exp/hce/simplegamedemo/assets/lightforce.mp3");
        if (tune === "2")
            loadMusic("xenon", "/exp/hce/simplegamedemo/assets/xenon.mp3");
        if (tune === "3")
            loadMusic("shades", "/exp/hce/simplegamedemo/assets/shades.mp3");
        if (tune === "4")
            loadMusic("speedball", "/exp/hce/simplegamedemo/assets/speedball.mp3");
    };
    async function loadMusic(id, src) {
        if (musicPlaying.current !== null) {
            musicPlaying.current.domElement.pause();
        }
        let asset = hceapi.assetMgr.getById(id);
        if (asset) {
            asset.domElement.play();
            musicPlaying.current = asset;
            return;
        }
        await hceapi.assetMgr.loadSound(id, src).then((asset) => {
            asset.domElement.play();
            musicPlaying.current = asset;
        });
    }
    if (keysPressed.length > 0) {
        playMusic(keysPressed);
        setKeysPressed("");
    }
    const onResetScene = () => {
        mainStarCount.current += 10;
        setTimeout(() => {
            loadTitleScene();
        }, 100);
    };
    const loadTitleScene = async () => {
        await stopCurrentScene();
        let titleSceneProps = {
            starCount: mainStarCount.current,
            onResetScene: onResetScene,
        };
        var scene = new MyLinksScene(hceapi.hce, titleSceneProps);
        activeScene.current = scene;
        scene.init();
        await scene.start();
    };
    const startCurrentScene = async () => {
        if (activeScene.current !== null)
            activeScene.current.start();
    };
    const stopCurrentScene = async () => {
        if (activeScene.current !== null) {
            await activeScene.current.stop();
            activeScene.current = null;
        }
    };
    return React.createElement(React.Fragment, null, hceelement);
};
export default MyLinksDemo;
