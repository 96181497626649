//import React, { useEffect, useState } from "react";
import { useRef } from "react";
import * as React from "react";
import { WelcomeScene } from "./WelcomeScene";
import { useHceFullScreen } from "../../../lib/hce/v3/react/useHceFullScreen";
const HcmMiner = (props) => {
    const [hceReady, hceapi, element] = useHceFullScreen();
    const activeScene = useRef(null);
    React.useEffect(() => {
        if (hceReady === true) {
            loadTitleScene();
        }
    }, [hceReady]);
    const loadTitleScene = async () => {
        await stopCurrentScene();
        var scene = new WelcomeScene(hceapi.hce);
        activeScene.current = scene;
        scene.init();
        scene.start();
    };
    const stopCurrentScene = async () => {
        if (activeScene.current !== null) {
            await activeScene.current.stop();
            activeScene.current = null;
        }
    };
    return React.createElement(React.Fragment, null, element);
};
export default HcmMiner;
