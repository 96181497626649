import * as React from "react";
import { Link } from "react-router-dom";
import { AdminQuickKeys } from "./AdminQuickKeys";
const StdHeader = () => {
    return (React.createElement("div", { className: "head scrolled grid place-items-center w-full" },
        React.createElement("div", { className: "flex justify-center" },
            React.createElement("span", { className: "inline-flex" },
                React.createElement(Link, { to: "/" },
                    React.createElement("img", { className: "rounded-full h-16 md:h-20 border-2 border-rioblue", src: "images/me/rich_aug_2022.jpeg", alt: "profile picture of rich anderson" })),
                React.createElement("span", { className: "text-rioblue tracking-tighter ml-4 font-sans text-4xl md:text-5xl grid place-items-center" }, "richanderson.io"))),
        React.createElement(AdminQuickKeys, null)));
};
export default StdHeader;
