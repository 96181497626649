import HceScene from "../../lib/hce/v3/engine/HceScene";
import { Starfield } from "./Starfield";
import { Player } from "./Player";
import { KeyboardMap } from "../../lib/hce/v3/engine/keyboardMap";
import { Scroller } from "./Scroller";
import Text from "./Text";
// small animation player to move the ships around the screen 
class AnimatePlayer {
    animations = [];
    count = 0;
    player;
    currentTicks = 0;
    currentStep = 0;
    constructor(player) {
        this.player = player;
        var step1 = {
            ticks: 10,
            init: function () {
                this.ticks = Math.random() * 5;
            },
            tick: (player) => {
                //player.thrust();
                player.pressingSpace = true;
                //player.drawThrust = true;
            },
            term: () => {
                this.player.pressingSpace = false;
            }
        };
        this.animations.push(step1);
        // do nothing for bit except float
        var step2 = {
            ticks: 220,
            init: function () {
                this.ticks = Math.random() * 500;
            },
            tick: (player) => {
            }
        };
        this.animations.push(step2);
        var step3 = {
            state: {
                rotateLeft: false,
                rotateRight: false,
            },
            ticks: 45,
            init: function () {
                this.ticks = Math.random() * 180;
                if (Math.random() < .5)
                    this.state.rotateLeft = true;
                else
                    this.state.rotateRight = false;
            },
            tick: function (player) {
                if (this.state.rotateRight)
                    player.rotateRight();
                if (this.state.rotateLeft)
                    player.rotateRight();
            }
        };
        this.animations.push(step3);
        var step4 = {
            ticks: 5,
            tick: (player) => {
                player.thrust();
            }
        };
        this.animations.push(step4);
    }
    onTick(c) {
        if (this.currentStep >= this.animations.length) {
            this.currentStep = 0;
            return;
        }
        if (this.currentTicks < this.animations[this.currentStep].ticks) {
            if (this.currentTicks === 0) {
                if (this.animations[this.currentStep].init)
                    this.animations[this.currentStep].init();
            }
            this.animations[this.currentStep].tick(this.player);
            this.currentTicks++;
        }
        else {
            if (this.animations[this.currentStep].term)
                this.animations[this.currentStep].term();
            this.currentStep++;
            this.currentTicks = 0;
        }
    }
}
export class MyLinksScene extends HceScene {
    keyboardMap = new KeyboardMap();
    player = null;
    ap = [];
    starfield;
    props;
    constructor(hce, props) {
        super(hce);
        this.props = props;
    }
    onStart() {
        const addInitialPlayer = false;
        this.keyboardMap.init();
        this.rootObject.sceneContext = this; // we'll be the scene object
        if (addInitialPlayer) {
            var player = new Player(this.hce);
            player.tag = "player";
            this.rootObject.addChild(player);
            this.player = player;
            this.ap.push(new AnimatePlayer(player));
        }
        this.starfield = new Starfield(this.hce);
        this.starfield.starCount = this.props.starCount;
        this.starfield.init();
        this.rootObject.addChild(this.starfield);
        this.rootObject.addChild(new Scroller(this.hce));
        var text = new Text(this.hce);
        text.designTimeLoadState({
            randomNotExist: "ok",
            tag: "text1",
            text: "Hello from me 2",
            position: { x: 50, y: 100 },
            color: "orange",
            font: "20px Courier",
            visible: true,
        });
        //this.rootObject.addChild(text);
    }
    addShip() {
        var colors = ["#fffbeb", "#fef3c7", "#fde68a", "#fcd34d", "#fbbf24"];
        var anPlayer = new Player(this.hce);
        anPlayer.tag = "player";
        anPlayer.rotation = Math.random() * 360;
        anPlayer.shipColor = colors[Math.round(Math.random() * 4)];
        this.rootObject.addChild(anPlayer);
        this.ap.push(new AnimatePlayer(anPlayer));
        return anPlayer;
    }
    totalRenderingTime = 0;
    totalFrames = 0;
    renderText(ctx2d, text, x, y) {
        ctx2d.font = "18px Courier";
        ctx2d.fillText(text, x, y);
    }
    onRender(context) {
        var tickContext = {
            keyboardMap: this.keyboardMap,
            gameState: {
                scene: {
                    width: this.hce.canvas.width,
                    height: this.hce.canvas.height
                }
            }
        };
        const t0 = performance.now();
        this.rootObject.tick(tickContext, 0);
        this.rootObject.render(context.ctx2d);
        const t1 = performance.now();
        this.totalRenderingTime += t1 - t0;
        this.totalFrames++;
        const frameRate = 1000 / (t1 - t0);
        if (this.totalRenderingTime > 1000) {
            const logRenderRateToConsole = false;
            if (logRenderRateToConsole) {
                console.log("this.totalRenderingTime = " + this.totalRenderingTime);
                console.log("this.totalFrames = " + this.totalFrames);
                console.log("ms per Frame = " + this.totalRenderingTime / this.totalFrames);
                console.log("frame per Second  = " + 1000 / (this.totalRenderingTime / this.totalFrames));
            }
            this.totalFrames = this.totalRenderingTime = 0;
        }
        let y = 40;
        if (this.keyboardMap.get("f")) {
            const stats = `renderTime ${t1 - t0} ms`;
            this.renderText(context.ctx2d, stats, 20, y);
            y += 40;
            this.renderText(context.ctx2d, `frames ${1000 / (t1 - t0)}`, 20, y);
            y += 40;
            this.renderText(context.ctx2d, `avg frames ${1000 / (this.totalRenderingTime / this.totalFrames)}`, 20, y);
            y += 40;
            this.renderText(context.ctx2d, `stars ${this.starfield.starCount}`, 20, y);
            y += 40;
            this.renderText(context.ctx2d, `rendered stars ${this.starfield.lastFrameRenderedStars}`, 20, y);
            y += 40;
            this.renderText(context.ctx2d, `canvas h=${this.hce.canvas.height} w=${this.hce.canvas.width}`, 20, y);
            y += 40;
        }
        // Let parent know a reset was requested (should likely move this out to the parent)
        if (this.keyboardMap.get("r")) {
            this.props.onResetScene();
        }
        if (this.keyboardMap.get("s")) {
            this.rootObject.removeChild(this.starfield);
            this.starfield = new Starfield(this.hce);
            this.starfield.init();
            this.rootObject.addChild(this.starfield);
            this.starfield.onSceneReady(this);
        }
        this.ap.forEach((e) => {
            e.onTick(context.ctx2d);
        });
        // randomly added a new ship or if the player requests one
        if (Math.random() > .9995 || this.keyboardMap.get("-")) {
            this.addShip().onSceneReady(this);
        }
    }
}
