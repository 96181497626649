import * as React from "react";
import { Link } from "react-router-dom";
import { AdminQuickKeys } from "../components/AdminQuickKeys";
const AdminHeader = (props) => {
    return (React.createElement("div", { className: "adminhead scrolled flex w-full border-2" },
        React.createElement("div", { className: "" },
            React.createElement(Link, { to: "/admin" },
                React.createElement("img", { className: "rounded-full h-10 border-2 border-rioblue", src: "/images/me/rich_aug_2022.jpeg", alt: "profile picture of rich anderson" }))),
        React.createElement("div", { className: "flex justify-center w-full" },
            React.createElement("span", { className: "inline-flex" },
                React.createElement("span", { className: "text-rioblue tracking-tighter ml-4 font-sans text-2xl grid place-items-center" }, props.title))),
        React.createElement(AdminQuickKeys, null)));
};
export default AdminHeader;
