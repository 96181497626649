//import React, { useEffect, useState } from "react";
import { useState } from "react";
import * as React from "react";
import HceReact from "./Hce";
import HceFullScreenDiv from "./HceFullScreenDiv";
export const useHceFullScreen = (zIndex) => {
    const [hceReady, setHceReady] = useState(false);
    const [hceapi, setHceApi] = useState(null);
    const [element, setElement] = useState(null);
    const [divsize, setDivSize] = useState({ width: 0, height: 0 });
    // called when the HCE canvas is ready and give us a ref to the API
    const onHceReady = (api) => {
        api.hce.autoResizeCanvas = false;
        setHceApi(api);
        api.hce.setCanvasWH(divsize.width, divsize.height);
        setHceReady(true);
    };
    const createMainElement = () => {
        return (React.createElement(HceFullScreenDiv, { zIndex: zIndex ? zIndex : -100, onDivResized: (w, h) => setDivSize({ width: w, height: h }) },
            React.createElement(HceReact, { onApiReady: onHceReady })));
    };
    React.useEffect(() => {
        setElement(createMainElement());
    }, []);
    React.useEffect(() => {
        if (hceapi) {
            hceapi.hce.setCanvasWH(divsize.width, divsize.height);
        }
    }, [divsize]);
    return [hceReady, hceapi, element];
};
